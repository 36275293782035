import usePrevious from 'hooks/usePrevious';
import Konva from 'konva';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Group, Transformer } from 'react-konva';
import { ElmOperation } from 'util/Constants';
import uuid from 'uuid';
import AddButton from './buttons/AddButton';
import DeleteButton from './buttons/DeleteButton';
import Shape from './Shape';

/**
 * Table Component.
 *
 * @param {string} tblNo - The table number.
 * @param {number} width - The width of the table.
 * @param {number} height - The height of the table.
 * @param {boolean} isEditMode - A boolean indicating whether the component is in edit mode.
 * @param {number} startX - The starting x-coordinate of the canvas.
 * @param {number} startY - The starting y-coordinate of the canvass.
 * @param {Function} setUpdatedTableArray - A function to update the array of tables.
 * @param {Object} dimension - The dimensions of the table.
 * @param {boolean} load - A boolean indicating whether a pending request (transformTable) is being loaded.
 * @param {Function} changeViewMode - A function to change the view mode.
 * @param {Object} data - The data associated with the table.
 *
 * @description
 * The `Table` component represents a table on the canvas. It manages the rendering and behavior of the table,
 * including adding and manipulating columns, selecting lines, and applying transformations.
 */
const Table = ({
  table,
  tblNo,
  width,
  height,
  isEditMode,
  startX,
  startY,
  setUpdatedTableArray,
  dimension,
  load: pendingReq,
  changeViewMode,
  data = {},
  selectElementHandler,
}) => {
  const transformerRef = useRef(Konva.Transformer);
  const groupRef = useRef(Konva.Group);

  const [lines, setLines] = useState([]);
  const [selectedLine, setSelectedLine] = useState(null);
  const prevLoad = usePrevious(pendingReq);

  useEffect(() => {
    if (pendingReq && transformerRef.current) {
      changeViewMode();
    } else if (prevLoad && !pendingReq) {
      changeViewMode();
      setLines([]);
      setSelectedLine(null);
    }
  }, [prevLoad, pendingReq]);

  // This code maps the data into an array of objects that have the same structure as start_point and end_point
  // This way, the data can be passed to the Line component to draw the lines
  useEffect(() => {
    if (!isEditMode) {
      return;
    }

    const linesToBeAdded = data?.columns?.map(column => ({
      ...column,
      start_point: {
        x: column.boundingPoly.normalizedVertices.start_point.x,
        y: column.boundingPoly.normalizedVertices.start_point.y,
      },
      end_point: {
        x: column.boundingPoly.normalizedVertices.end_point.x,
        y: column.boundingPoly.normalizedVertices.end_point.y,
      },
    }));

    setLines(prev => {
      // added new lines don't have an id, so we filter them out to be added to the end of the array
      const addedColumns = prev.filter(line => line.userAdded);
      return [...linesToBeAdded, ...addedColumns];
    });
  }, [data, isEditMode]);

  /**
   * Calculate the x-coordinate limits for dragging the table.
   * 
   * @type {Object}
   * @property {number} xStart - The minimum x-coordinate limit for dragging.
   * @property {number} xEnd - The maximum x-coordinate limit for dragging.
   */
  const limitX = useMemo(() => {
    const position = data?.boundingPoly?.normalizedVertices;

    if (!isEditMode) {
      return {
        x: 0,
        y: 0,
      };
    }

    const xStart = position.x * data?.size.x + startX;
    const xEnd = (position.w + position.x) * data?.size.x + startX;

    return {
      xStart,
      xEnd,
    };
  }, [data?.boundingPoly?.normalizedVertices, data?.size.x, isEditMode, startX]);

  /**
   * Add a column to the table.
   */
  const addColumn = () => {
    const position = data?.boundingPoly?.normalizedVertices;

    if (!position) {
      return;
    }

    const x = position.x + position.w / 2;
    const id = uuid.v4();

    const lineToBeAdded = {
      id,
      userAdded: true,
      start_point: {
        x,
        y: position.y,
      },
      end_point: {
        x,
        y: position.y + position.h,
      },
    };

    setLines(lines => [...lines, lineToBeAdded]);
    const normalizedVertices = {
      start_point: {
        x,
        y: position.y,
      },
      end_point: {
        x,
        y: position.y + position.h,
      },
    };
    setUpdatedTableArray(normalizedVertices, id, data.id, '', ElmOperation.CREATED);
  };

  if (!isEditMode) {
    return null;
  }

  /**
   * Set the operation.
   * 
   * @param {string} operation - The operation type (e.g., "UPDATED", "DELETED").
   * @param {Object} attributes - The attributes of the line.
   */
  const setOperation = (operation, attributes) => {
    const normalizedVertices = {
      start_point: {
        x:
          (attributes.points[0] +
            (operation === ElmOperation.UPDATED ? attributes.x : 0) -
            startX) /
          dimension.width,
        y: (attributes.points[1] - startY) / dimension.height,
      },
      end_point: {
        x:
          (attributes.points[0] +
            (operation === ElmOperation.UPDATED ? attributes.x : 0) -
            startX) /
          dimension.width,
        y: (attributes.points[3] - startY) / dimension.height,
      },
    };
    setUpdatedTableArray(normalizedVertices, attributes.id, data.id, '', operation);
  };

  return (
    <>
      <AddButton
        tblNo={tblNo}
        position={data?.boundingPoly?.normalizedVertices}
        size={data?.size}
        startX={startX}
        startY={startY}
        onClick={addColumn}
      />
      <Group id={`tableGroup${data.id}`} ref={groupRef}>
        {lines
          .filter(el => !el.first_column)
          .map((line, index) => (
            <Shape
              table={table}
              key={index}
              trRef={transformerRef}
              isEditMode={isEditMode}
              setSelected={setSelectedLine}
              startX={[line.start_point.x * data.size.x + startX, line.start_point.y * data.size.y + startY]}
              startY={[line.end_point.x * data.size.x + startX, line.end_point.y * data.size.y + startY]}
              limitX={limitX}
              id={line.id}
              setOperation={setOperation}
              selectElementHandler={selectElementHandler}
            />
          ))}
        {selectedLine && (
          <DeleteButton
            tr={transformerRef}
            selectedLine={selectedLine}
            setSelected={setSelectedLine}
            setOperation={setOperation}
          />
        )}
      </Group>

      <Transformer
        ref={transformerRef}
        boundBoxFunc={(oldBox, newBox) => {
          if (Math.abs(newBox.width) > width || Math.abs(newBox.height) > height) {
            return oldBox;
          }

          return newBox;
        }}
      />
    </>
  );
};

export default Table;
