import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import TranslateMessage from '../../components/translateManager/TranslateMessage';
import { LANGUAGE_OPTIONS } from '../../util/Constants';
import { pdfjs } from 'react-pdf';
import { CircularProgress } from "@mui/material";
import PdfPageCounter from '../../components/pdfPageCounter/PdfPageCounter';
import ExceedManager from '../../components/modal/ExceedManager';
import { PagesPerUploadModal } from '../../components/modal/PagesPerUploadModal';
import { MAX_PAGES_PER_UPLOAD } from '../../util/Constants';
import './PdfUpload.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const getBorder = props => {
  if (props.isDragAccept) {
    return '5px solid #0029ff';
  }
  if (props.isDragReject) {
    return '5px solid #ff1744';
  }
  if (props.isFocused) {
    return '5px solid #0029ff';
  }
  return '2px solid #c4c4c4';
};

const Container = styled.div`
  border: ${props => getBorder(props)};
  transition: border 0.24s ease-in-out;
`;

export default function PdfUpload(props) {
  const [totalNumPages, setTotalNumPages] = useState(0);
  const [open, setOpen] = useState(false);
  const [openPagesPerUploadModal, setOpenPagesPerUploadModal] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [languages, setLanguages] = useState([]);


  useEffect(() => {
    setTotalNumPages(
      props.fileDetails
        .map(value => value?.numPages || 1)
        .reduce((partialSum, a) => partialSum + a, 0),
    );

    console.log('totalNumPages in pdf upload', totalNumPages);
  }, [props.fileDetails]);

  useEffect(() => {
    const data = props.getAllDocumentCount?.data?.data;
    if (data?.maxLimit < totalNumPages + data?.count) {
      console.log('Limit exceed..!');
      setIsValid(false);
      setOpen(true);
      return;
    }
    if (totalNumPages > MAX_PAGES_PER_UPLOAD) {
      setOpenPagesPerUploadModal(true);
      setIsValid(false);
    }
    else {
      setIsValid(true);
      setOpen(false);
    }
  }, [totalNumPages, props.getAllDocumentCount?.data?.data])

  useEffect(() => {
    const sorter1 = (a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
    const sortedLanguage = LANGUAGE_OPTIONS.sort(sorter1);
    setLanguages(sortedLanguage);
  }, []);

  useEffect(() => {
    props.updatePageCounterHandler(totalNumPages);
  }, [totalNumPages]);

  const pdfNextHandler = () => {
    if (!props.getAllDocumentCount.loading && !props.getAllDocumentCount.hasError) {
      const data = props.getAllDocumentCount?.data?.data;
      if (data?.maxLimit < totalNumPages + data?.count) {
        console.log('Limit exceed..!');
        setIsValid(false);
        setOpen(true);
      } else {
        props.switchViewHandler('CONFIG_UPLOAD');
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({ accept: props.accept, multiple: props.multiple });


  return (
    <div className="d-flex flex-column justify-content-center h-100">
      <div className="row justify-content-center">
        <div className="col-sm-10 col-md-9 col-lg-8 col-xl-8 col-xxl-8">
          <div className="upload-container">
            <h5 className="result-heading mb-2">
              <TranslateMessage translateKey="upload_your_documents" />
            </h5>
            <section>
              <Container className="lang-select mb-3">
                <h6 className="result-heading mb-2">
                  <TranslateMessage translateKey="upload_document_title" />*
                </h6>
                <div className="row">
                  <div className="col-md-2 d-flex align-items-center ">
                    <div className="">
                      <TranslateMessage translateKey="1_st_language" />:
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div style={{ maxWidth: '200px' }}>
                      <select
                        className="form-select"
                        onChange={props.language1StHanlder}
                        value={props.language1St}
                      >
                        <option value={'unselect'}>{'-'}</option>
                        {languages.map((lan, i) => {
                          return <option value={lan.key}>{lan.label}</option>;
                        })}
                      </select>
                    </div>
                    {props.fileDetails.length > 0 &&
                      (!!!props.language1St || props.language1St === 'unselect') && (
                        <div className="error-msg">
                          {<TranslateMessage translateKey="1st_language_required" />}
                        </div>
                      )}
                  </div>
                  <div className="col-md-4 d-flex align-items-center justify-content-end">
                    <div>
                      <TranslateMessage translateKey="2_nd_language" />:
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div style={{ maxWidth: '200px' }}>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={props.language2NdHanlder}
                        value={props.language2Nd}
                      >
                        <option value={'unselect'}>{'-'}</option>
                        {languages.map((lan, i) => {
                          return <option value={lan.key}>{lan.label}</option>;
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </Container>
            </section>
            <section>
              <Container
                className="upload-card back-img"
                {...getRootProps({ isFocused, isDragAccept, isDragReject })}
              >
                <input {...getInputProps()} />
                <div>
                  <h6 className="result-heading text-center mt-4">
                    <TranslateMessage translateKey="drag_n_drop" />
                  </h6>
                  <div className="btn-login">
                    <Button className="blue-btn mt-2 mb-3" variant="contained">
                      <TranslateMessage translateKey="select" />
                    </Button>
                  </div>
                  <h6 className="result-heading text-center mb-0">
                    <TranslateMessage translateKey="documents" />
                  </h6>
                  <small>
                    <b>
                      <TranslateMessage translateKey="accept_formats" />
                    </b>
                  </small>
                </div>
              </Container>
            </section>
            <section>
              <Container className="pdf-select mt-3">
                <div className="d-flex justify-content-center">
                  <div
                    className="text-center"
                    style={{
                      width: '100%',
                      maxHeight: '120px',
                      overflowY: 'auto',
                      overflowX: 'hidden',
                    }}
                  >
                    <div className="row justify-content-center">
                      {props.fileDetails.length > 0 ? (
                        props.fileDetails.map((file, i) => {
                          return (
                            <div className="col-12" key={i}>
                              {file.name}
                              <span
                                className="upload-close-icon ms-1 px-1"
                                onClick={() => props.removePdfHandler(file, i)}
                              >
                                <b>x</b>
                              </span>
                            </div>
                          );
                        })
                      ) : (
                        <small>
                          <TranslateMessage translateKey="no_document_selected" />
                        </small>
                      )}
                    </div>
                  </div>
                </div>
              </Container>
            </section>
            <section>
              <div className="row">
                <div className="col"></div>
                <div className="col">
                  <h6 className="result-heading text-center mt-3">
                    <TranslateMessage translateKey="uploaded_files" />: {props.fileDetails.length}
                  </h6>
                </div>
                <div className="col">
                  <h6 className={`result-heading text-center mt-3 ${!isValid ? 'text-danger' : ""}`}>
                    <TranslateMessage translateKey="uploaded_pages" />: {/* {totalNumPages} */}
                    {props.totalPageCount}
                  </h6>
                </div>
                <div className="col"></div>
              </div>
            </section>

            <PdfPageCounter
              fileDetails={props.fileDetails}
              acceptedFiles={acceptedFiles}
              callBack={props.callBack}
              getAllDocumentCount={props.getAllDocumentCount}
              setTotalNumPages={setTotalNumPages}
              totalNumPages={props.totalPageCount}
              isValid={isValid}
              pageUpdateHandler={props.pageUpdateHandler}
              prevFileDetailsLength={props.prevFileDetailsLength}
            />

            <div className="btn-login text-center">
              <Button
                disabled={
                  !isValid || props.fileDetails.length === 0 ||
                  !!!props.language1St ||
                  props.language1St === 'unselect'
                }
                className="blue-btn mt-3 mb-3"
                variant="contained"
                onClick={pdfNextHandler}
              >
                {!props.btnLoading ? <TranslateMessage translateKey="next" /> :
                  <CircularProgress className="loading-btn-circle" />}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ExceedManager
        maxLimit={props.getAllDocumentCount?.data?.data?.maxLimit || 0}
        currentCount={props.getAllDocumentCount?.data?.data?.count || 0}
        open={open}
        handleClose={handleClose}
        monthlyRenewalDate={props.nextRenewalDate}
      />

      <PagesPerUploadModal
        currentCount={totalNumPages}
        maxLimit={MAX_PAGES_PER_UPLOAD}
        open={openPagesPerUploadModal}
        handleClose={() => setOpenPagesPerUploadModal(false)}
      />
    </div>
  );
}