import React, { Component, createRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Button } from "@mui/material";
import { PredictActions } from "./ducks";
import { KeyValuePair } from "../../components/validate/keyValuePair";
import { ViewMode, ElementType, AppMode, UNUSED } from "../../util/Constants";
import TableView from "../../components/validate/TableView";
import Pagination from "@mui/material/Pagination";
import SplitPane, { Pane } from "react-split-pane";
import addRow from "../../../src/assets/images/above.png";
import transpose from "../../../src/assets/images/right-and-down.png";
import PageNavigator from "../../components/validate/PageNavigator";
import TranslateMessage from "../../components/translateManager/TranslateMessage";
import Tooltip from "components/shared/ToolTip";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import HelpModal from '../../components/modal/HelpModal';
import "../../components/shared/styles/ValidateSideBar.css";

class ValidateSideBar extends Component {

  tableViewRef = createRef();

  constructor(props) {
    super(props);
    this.state = {
      values: [],
      option: null,
      viewType: null,
      isShow: false,
      keyPairAndTableData: [],
      filterValue: null,
      detections: null,
      activeTab: "1",
      selectedTableNo: 1,
      updatedColHeadings: [],
      selectedKeyValues: [],
      setSelectedKeyValues: false,
      isAddLable: false,
      orderingValue: "",
      headingTemplateAdded: false,
      deSelectedItems: false
    };
  }

  componentDidMount() {
    const { page } = this.props;
    this.props.predictActions.getTableHeadings();
    this.setState({
      keyPairAndTableData: page?.data?.detections?.keyValuePair,
    });
    const tableHeight = (window.innerHeight * 0.6) - 80;
    this.tableViewRef.current.style.height = `${tableHeight}px`;
    this.props.predictActions.handleSelectedTable({ selectedTableNo: 1 });

    this.setState({
      selectedTableNo: 1,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { page } = this.props;
    const { updatedColHeadings, selectedTableNo, setSelectedKeyValues } =
      this.state;

    if (page && JSON.stringify(page) !== JSON.stringify(prevProps.page)) {
      this.setState({
        detections: page?.data?.detections,
      });
      this.createSelectedKeyValues();
      this.createColHeadings(selectedTableNo);
    }

    if (page && !page.loading && !setSelectedKeyValues) {
      this.createSelectedKeyValues();
    }

    if (
      page &&
      !page.loading &&
      page?.data?.detections?.table?.[selectedTableNo - 1]?.columns.length >
      0 &&
      updatedColHeadings.length <= 0
    ) {
      this.createColHeadings(selectedTableNo);
    }
  }

  toggleModalHandler = () => {
    this.setState({
      isShow: !this.state.isShow,
    });
  };

  handleChange = (event) => {
    let selectedItem = event.target.value;
    this.setState({
      option: selectedItem,
      viewType: selectedItem,
    });

    this.props.predictActions.handleSelectDropdownElement(selectedItem);
    this.props.predictActions.handleAppMode({ mode: AppMode.DRAWING_ACTION });
  };

  updateKeyValueHandler = ({ data, updatedData, isKey }) => {
    const { page } = this.props;

    this.setState({ keyPairAndTableData: page.data.detections?.keyValuePair });

    let updateKeyValueDto = {
      pageId: page.data.id,
      keyValuePair: {
        id: data.id,
        value: isKey ? data.value : updatedData,
        key: isKey ? updatedData : data.key,
      },
    };

    this.props.predictActions.updateKeyValue({
      updateKeyValueDto: updateKeyValueDto,
    });
  };

  handleTableSelect = (data) => {
    this.props.predictActions.handleTableSelect(data);
  };

  pageHandler = (action) => {
    const { match, page } = this.props;
    const { pageNo = 1 } = match?.params;
    let updatedpageNo = +pageNo + action;
    if (0 < updatedpageNo && updatedpageNo <= page?.data?.numberOfPages) {
      this.props.predictActions.clearPageSpace();
      this.props.predictActions.getPage({ pageNo: updatedpageNo });
      this.props.history.push(`/validate/${updatedpageNo}`);
      this.props.predictActions.handleSelectedTable({ selectedTableNo: 1 });

      this.setState({
        selectedTableNo: 1,
      });
    }
  };

  filter = (e) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const results = this.state.keyPairAndTableData.filter((data) => {
        return data.type.toLowerCase().startsWith(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });
      this.setState({ keyPairAndTableData: results });
    } else {
      this.setState({ keyPairAndTableData: this.state.keyPairAndTableData });
      // If the text field is empty, show all users
    }

    this.setState({ filterValue: keyword });
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  updateTableHandler = (data) => {
    this.setState({
      tableData: data,
    });
  };

  tablePaginationHandler = (e, value) => {
    const { match } = this.props;
    let pgNo = match?.params.pageNo;

    this.props.predictActions.handleSelectedTable({ selectedTableNo: value });
    this.props.predictActions.getPage({ pageNo: pgNo });

    this.setState({
      selectedTableNo: value,
    });
    this.createColHeadings(value);
  };

  isEditViewMode() {
    return this.props.viewMode.mode === ViewMode.EDIT_VIEW;
  }

  tableColumnChange = (id, value, col_index) => {
    const isItemDeselected = (value === '-') ? true : false;
    this.setState({
      deSelectedItems: isItemDeselected
    }, () => { });
    let unselected_obj = this.unSelectSameSelectedValue(value);
    var headings = Object.assign([], this.state.updatedColHeadings);

    var index = headings.findIndex((obj) => obj.index === col_index);
    if (index === -1) {
      headings.push(this.getColHeadingObj(id, value, col_index));
    } else {
      var updated_obj = headings.find((obj) => obj.index === col_index);
      updated_obj["value"] = value;
      updated_obj["showLoading"] = true;  // for the table selection loading
      value === '-' && (updated_obj["deselected"] = true);
      headings[index] = updated_obj;
    }

    this.setState({
      updatedColHeadings: headings,
    });
    const { selectedTable, page } = this.props;

    let table = page?.data?.detections?.table[selectedTable?.data - 1];

    let updateColumnMapHeadingsTemplateDto = {
      pageId: page?.data?.id,
      selectedElement: {
        id: table?.transposed ? table?.transposed_columns[index]?.id : table?.columns[index]?.id,
        tableId: page?.data?.detections?.table[selectedTable?.data - 1]?.id,
        title: value,
      },
      heading: value,
      unselectedElement: unselected_obj || undefined,
    };

    this.props.predictActions.updateTableHeading({
      updateTableHeadingDto: updateColumnMapHeadingsTemplateDto,
    });
  };

  selectedKeyValuesChange = (key, data) => {
    const isItemDeselected = (key === '-') ? true : false;
    this.setState({
      deSelectedItems: isItemDeselected
    }, () => { });
    let unselected_obj = this.unSelectSameSelectedColHeading(key);
    let selectedKeyValuesUpdated = [...this.state.selectedKeyValues];

    let index = selectedKeyValuesUpdated.findIndex((obj) => obj.id === data.id);
    if (index === -1) {
      selectedKeyValuesUpdated.push(
        this.getSelectedKeyValueObj(data.id, key)
      );
    } else {
      let updated_obj = selectedKeyValuesUpdated.find(
        (obj) => obj.id === data.id
      );
      updated_obj["showLoading"] = true;  // for the key value selection loading
      key === '-' && (updated_obj["deselected"] = true);
      if (key === UNUSED) {
        selectedKeyValuesUpdated.splice(index, 1);
      } else {
        updated_obj["value"] = key;
        selectedKeyValuesUpdated[index] = updated_obj;
      }
    }
    this.setState({
      selectedKeyValues: selectedKeyValuesUpdated,
    }, () => { });

    const { page } = this.props;

    let updateSelectedKeyValueDto = {
      pageId: page?.data?.id,
      selectedElement: {
        id: data.id,
        title: key,
      },
      heading: key,
      unselectedElement: unselected_obj || undefined,
    };

    console.log("updateSelectedKeyValueDto: ",updateSelectedKeyValueDto);
    this.props.predictActions.updateSelectedKeyValue({
      updateSelectedKeyValueDto: updateSelectedKeyValueDto,
    });
  };

  // Unselect column heading ordering if already seleteced same option
  unSelectSameSelectedColHeading = (value) => {
    const { selectedTable, page } = this.props;
    let unselected_obj;
    let indexHeading = this.state.updatedColHeadings.findIndex(
      (obj) => obj.value === value
    );

    if (indexHeading !== -1) {
      let headings = Object.assign([], this.state.updatedColHeadings);
      let updated_obj = headings.find((obj) => obj.value === value);
      updated_obj["value"] = "unselected";
      headings[indexHeading] = updated_obj;
      this.setState({
        updatedColHeadings: headings,
      });

      unselected_obj = {
        id: updated_obj.id,
        tableId: page?.data?.detections?.table[selectedTable?.data - 1]?.id,
        type: "table",
      };

      return unselected_obj;
    }

    return null;
  };

  // Unselect previous selected same value in dropdowns
  unSelectSameSelectedValue = (value) => {
    const { selectedTable, page } = this.props;
    let unselected_obj;
    let indexHeading = this.state.updatedColHeadings.findIndex(
      (obj) => obj.value === value
    );
    let indexKeyValue = this.state.selectedKeyValues.findIndex(
      (obj) => obj.selected_title === value
    );

    if (indexHeading !== -1) {
      let headings = Object.assign([], this.state.updatedColHeadings);
      let updated_obj = headings.find((obj) => obj.value === value);
      updated_obj["value"] = "unselected";
      headings[indexHeading] = updated_obj;
      this.setState({
        updatedColHeadings: headings,
      });

      unselected_obj = {
        id: updated_obj.id,
        tableId: page?.data?.detections?.table[selectedTable?.data - 1]?.id,
        type: "table",
      };

      return unselected_obj;
    }

    if (indexKeyValue !== -1) {
      let keyValues = [...this.state.selectedKeyValues];
      let updated_obj = keyValues.find((obj) => obj.selected_title === value);
      updated_obj["value"] = "unselected";
      keyValues[indexKeyValue] = updated_obj;
      this.setState({
        updateSelectedKeyValueDto: keyValues,
      });

      unselected_obj = {
        id: updated_obj.id,
        type: ElementType.ELM_KEY_VAL,
      };

      return unselected_obj;
    }

    return null;
  };

  createColHeadings(selectedTableNo) {
    const { page } = this.props;
    let headings = [];

    let tableColumns = [];
    let table = page?.data?.detections?.table?.[selectedTableNo - 1];

    tableColumns = table?.transposed ? table?.transposed_columns : table?.columns;

    tableColumns?.map(
      (col, index) => {
        headings.push(this.getColHeadingObj(col.id, col.selected_title, index));
        return col;
      }
    );
    this.setState({
      updatedColHeadings: headings,
    });
  }

  createSelectedKeyValues() {
    const { page } = this.props;

    this.setState({
      setSelectedKeyValues: true,
      selectedKeyValues: page?.data?.detections?.keyValuePair,
    });
  }

  getColHeadingObj(id, value, col_index) {
    return {
      id: id,
      value: value,
      index: col_index,
    };
  }

  getSelectedKeyValueObj(id, value) {
    return {
      id: id,
      value: value,
    };
  }

  toggleAddLable() {
    let isAddLable = this.state.isAddLable;
    this.setState({
      isAddLable: !isAddLable,
    });
  }

  addOderingValue = (value, data) => {
    const { page } = this.props;


    // for the key value selection loading
    let index = this.state.selectedKeyValues.findIndex((obj) => obj.id === data.id);
    if (index !== -1) {
      let updatedValues = this.state.selectedKeyValues;
      updatedValues[index]['showLoading'] = true;
      this.setState({
        selectedKeyValues: updatedValues,
      }, () => { });
    }

    if (value !== "") {
      let headingTemplateDto = {
        pageId: page?.data?.id,
        heading: value,
        data: data
      };

      return headingTemplateDto;
    }
    // this.toggleAddLable();
  };

  keyIsSelected = (data) => {
    const { selectedKeyValues } = this.state;
    const selectedKeyValue = selectedKeyValues?.find(value => value.id === data.id);
    if (
      selectedKeyValue &&
      selectedKeyValue.selected_title !== undefined &&
      selectedKeyValue.selected_title !== '' &&
      selectedKeyValue.selected_title !== '-' &&
      selectedKeyValue.selected_title !== 'unselected'
    ) {
      this.setSelectItem(true);
      return true;
    }

    return false;
  };
  setSelectItem = (isSelected) => {
    this.props.predictActions.changeValidateStatus({
      isSelectItem: isSelected,
    });
  }

  addOderingValueTable = (columnHeaderId, value, index) => {
    const { page } = this.props;

    // for the table selection loading
    var headings = Object.assign([], this.state.updatedColHeadings);
    const ID = headings.findIndex((obj) => obj.index === index);
    if (ID !== -1) {
      var updated_obj = headings.find((obj) => obj.index === index);
      updated_obj["showLoading"] = true;
      headings[ID] = updated_obj;
    }
    this.setState({
      updatedColHeadings: headings,
    });

    if (value !== "") {
      let headingTemplateDto = {
        pageId: page?.data?.id,
        heading: value,
        columnHeaderId: columnHeaderId,
        index: index,
      };

      return headingTemplateDto;

    } else {
      return null;
    }
  };

  clearInput = () => {
    this.setState({
      orderingValue: "",
    });
  };
  addHeadingHandler = () => {
    const { page, selectedTable } = this.props;
    const selectedTableData =
      page?.data?.detections?.table[(selectedTable?.data - 1) | 0];

    let addTableHeadingDto = {
      pageId: page?.data?.id,
      tableId: selectedTableData?.id,
      status: !selectedTableData?.title_exist,
    };
    this.props.predictActions.addTableHeading({
      addTableHeadingDto: addTableHeadingDto,
    });
  };

  transposeTable = () => {
    const { page, selectedTable } = this.props;
    const selectedTableData =
      page?.data?.detections?.table[(selectedTable?.data - 1) | 0];
    this.props.predictActions.transposeTable({
      pageNo: page?.data?.id,
      tableId: selectedTableData?.id,
    });
  };

  viewModalHandler = () => {
    this.setState({
      isShowValidateModal: !this.state.isShowValidateModal
    })
  }

  handleBottomPaneResize = (size) => {
    const height = document.getElementById('height-reference-div').clientHeight;
    const tableViewHeight = height - size - 50;
    this.tableViewRef.current.style.height = `${tableViewHeight}px`;
  }

  render() {
    const { selectedTableNo, updatedColHeadings, isShowValidateModal, deSelectedItems } = this.state;
    const {
      selectedElement,
      page,
      match,
      selectedTable,
      addHeadingTemplate,
      addHeadingTemplateForTable,
      updateSelectedKeyValue,
      updateTableHeading
    } = this.props;

    const selectedTableData =
      page?.data?.detections?.table?.[(selectedTable?.data - 1) | 0];

    const { pageNo = 1 } = match?.params;

    // Set 'scroll' property to false for each item in 'keyValuePair'
    if (page && page?.data?.detections?.keyValuePair) {
      page.data.detections.keyValuePair.forEach(item => {
        if (item && typeof item === 'object') {
          item.scroll = false;
        }
      });
      // Update 'scroll' property to true for the matching item
      for (let index = 0; index < page?.data?.detections?.keyValuePair?.length; index++) {
        let currentItem = page?.data?.detections?.keyValuePair?.[index];
        if (currentItem && this.props.selectedElement.data.group_index === currentItem.group_index) {
          currentItem.scroll = true;
          page.data.detections.keyValuePair[index] = currentItem;
          // Exit the loop after the first match is found
          break;
        }
      }
    }
    const pending = (updateSelectedKeyValue.pending || updateTableHeading.pending);
    const deSelectPending = (updateTableHeading.pending || updateSelectedKeyValue.pending) && deSelectedItems;
    return (
      <div className="row m-0 h-100">
        <div className="col-12 p-0 d-flex flex-column predict-sidebar">
          <div id="height-reference-div" className="row m-0 flex-grow-1">
            <div className="col-12 p-0 position-relative">
              <SplitPane
                resizerClassName="split-resizer"
                split="horizontal"
                defaultSize="40%"
                onChange={this.handleBottomPaneResize}
              >
                <Pane>
                  <div className="scroll-area top-section" product-tour="text_section">
                    <div className="key-val-header d-flex align-items-center justify-content-between px-3 pb-1">
                    </div>

                    <Grid container style={{ marginLeft: "-2px" }}>
                      <Grid item xs={1} md={1} lg={1}>
                        <div id="iconBtn" className="cell-header" style={{ color: "blue", fontWeight: '600', fontSize: '16px' }}>
                          <TranslateMessage translateKey="text" />
                        </div>
                      </Grid>
                      <Grid item xs={3} md={3} lg={3}>
                        <div className="cell-header">
                          <b>
                            <TranslateMessage translateKey="selection" />
                          </b>
                          <Tooltip title="In the Selection column you need to choose which Values you want to extract by clicking the checkbox or using the Dropdown to assign the correct label. Values with the same Labels need to be selected only once." imgUrl={null} isBtn={false} />
                        </div>
                      </Grid>
                      <Grid item xs={3} md={3} lg={3}>
                        <div className="cell-header">
                          <b>
                            <TranslateMessage translateKey="labels" />
                          </b>
                          <Tooltip title="The Labels column shows the automatic classification of the Values. Click directly in the cell to edit if necessary." imgUrl={null} isBtn={false} />
                        </div>
                      </Grid>
                      <Grid item xs={4} md={4} lg={4}>
                        <div className="cell-header">
                          <b>
                            <TranslateMessage translateKey="values" isBtn={false} />
                          </b>
                          <Tooltip title="The Values column shows the extracted content. Click directly in the cell to edit if necessary." imgUrl={null} isBtn={false} />
                        </div>
                      </Grid>
                      <Grid item xs={1} md={1} lg={1} product-tour="help">
                        <div className="cell-header help-icon-end" >
                          <HelpOutlineOutlinedIcon sx={{ color: 'black', fontSize: "26px" }} onClick={this.viewModalHandler} />
                        </div>
                      </Grid>
                    </Grid>

                    <HelpModal
                      showModal={isShowValidateModal}
                      closeModal={this.viewModalHandler}
                    />
                    {/* <ReactTour /> */}

                    <div className="scroller">
                      <Grid>
                        {page?.data?.detections?.keyValuePair?.map(
                          (data, index) => {
                            return (
                              <KeyValuePair
                                key={`key-value-pair-${index}`}
                                keyVal={data.key}
                                val={data.value}
                                data={data}
                                selectedElement={selectedElement}
                                updateKeyValueHandler={this.updateKeyValueHandler}
                                getTableHeadings={this.props.getTableHeadings}
                                selectedKeyValuesChange={this.selectedKeyValuesChange}
                                isKeySelected={this.keyIsSelected(data)}
                                selectedKeyValues={this.state.selectedKeyValues}
                                color={`hsl(${data?.colorCode?.h}, ${data?.colorCode?.s}%, ${data?.colorCode?.l}%)`}
                                addOrderingValue={this.addOderingValue}
                                pending={pending}
                                deSelectPending={deSelectPending}
                              />
                            );
                          }
                        )}
                      </Grid>
                    </div>
                  </div>
                </Pane>
                <Pane>
                  <div className="p-2" product-tour="table_section">
                    <div className="table-header px-3 pb-2" product-tour="table_header">
                      <span className="d-inline-flex align-items-center">
                        <span className="me-2 tables-lable">
                          <b>
                            <TranslateMessage translateKey="tables" />
                          </b>
                        </span>
                        <Button
                          style={{
                            height: "30px",
                            width: "30px",
                            minWidth: "30px",
                            marginLeft: "10px",
                            marginTop: "-3px",
                            marginBottom: "-3px",
                            border: selectedTableData?.title_exist
                              ? "3px solid #0029ff"
                              : "",
                          }}
                          onClick={this.addHeadingHandler}
                        >
                          <Tooltip title="First row is a Header" imgUrl={addRow} />
                        </Button>
                        <Button
                          style={{
                            height: "30px",
                            width: "30px",
                            minWidth: "30px",
                            marginLeft: "10px",
                            marginTop: "-3px",
                            marginBottom: "-3px",
                            border: selectedTableData?.transposed
                              ? "3px solid #0029ff"
                              : "",
                            opacity: page?.data?.detections?.table && page.data.detections.table.length === 0 ? 0.2 : 1,
                          }}
                          disabled={!page?.data?.detections?.table || page.data.detections.table.length === 0}
                          onClick={this.transposeTable}
                        >
                          <Tooltip title="Transpose" imgUrl={transpose} />
                        </Button>
                      </span>
                      <Pagination
                        count={page?.data?.detections?.table?.length || 0}
                        page={selectedTableNo}
                        onChange={this.tablePaginationHandler}
                        style={{ marginLeft: "50px", float: "right" }}
                      />
                    </div>
                    <div ref={this.tableViewRef} className="auto-size">
                      <TableView
                        selectedTable={
                          page?.data?.detections?.table?.length !== 0
                            ? page?.data?.detections?.table?.[
                            selectedTableNo - 1
                            ]
                            : null
                        }
                        updateTableHandler={this.updateTableHandler}
                        updateRow={this.props.predictActions.updateRow}
                        page={this.props.page}
                        selectedTableNo={selectedTableNo}
                        getTableHeadings={this.props.getTableHeadings}
                        tableColumnChange={this.tableColumnChange}
                        updatedColHeadings={updatedColHeadings}
                        addOrderingValue={this.addOderingValue}
                        addOderingValueTable={this.addOderingValueTable}
                        pending={pending}
                        deSelectPending={deSelectPending}
                      />
                    </div>
                  </div>
                </Pane>
              </SplitPane>
            </div>
          </div>
          <div className="row m-0 bottom-sidebar-btn-area" product-tour="next_btn">
            <div className="col-12 p-0">
              {!this.isEditViewMode() && (
                <PageNavigator pageHandler={this.pageHandler} pageNo={pageNo} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    detections: state.Predict.detections,
    selectedElement: state.Predict.selectedElement,
    selectedTable: state.Predict.selectedTable,
    updateKeyValue: state.Predict.updateKeyValue,
    updateRow: state.Predict.updateRow,
    page: state.Predict.page,
    viewMode: state.Predict.viewMode,
    getTableHeadings: state.Predict.getTableHeadings,
    addHeadingTemplate: state.Predict.addHeadingTemplate,
    addHeadingTemplateForTable: state.Predict.addHeadingTemplateForTable,
    updateTableHeading: state.Predict.updateTableHeading,
    updateSelectedKeyValue: state.Predict.updateSelectedKeyValue,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    predictActions: bindActionCreators(PredictActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ValidateSideBar)
);
