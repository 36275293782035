// layouts
import DefaultLayout from "./layouts/DefaultLayout";
import ValidateLayout from "./layouts/ValidateLayout";
import NoDashboardLayout from "./layouts/NoDashboardLayout";

// views
import Views from "./modules/Views";
import { SIGN_UP_URL, IS_ENABLE_SIGN_UP } from "./util/Constants";
import PageNotFoundLayout from "./layouts/PageNotFoundLayout";


export const RoutePaths = {
  signIn: "/sign-in",
  signUp:  IS_ENABLE_SIGN_UP ? "/sign-up" : SIGN_UP_URL,
  aboutYou: "/about-you",
  account: "/account",
  validate: "/validate/:pageNo",
  results: "/results",
  progress: "/progress/:progress",
  upload: "/upload",
  wordCounter: "/word-counter",
  privacyPolicy: "/privacy-policy",
  landing: "/",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  verifyEmail: "/verify-email",
  notFoundPage: "/not-fount-page",
  pricing: "/pricing-plans",
}

const routes = [
  // ---------------SignIn---------
  {
    path: RoutePaths.signIn,
    layout: NoDashboardLayout,
    exact: true,
    component: Views.SignInView,
  },
  // --------ForgotPassword--------
  {
    path: RoutePaths.forgotPassword,
    layout: NoDashboardLayout,
    exact: true,
    component: Views.ForgotPasswordView,
  },
  // --------ResetPassword--------
  {
    path: RoutePaths.resetPassword,
    layout: NoDashboardLayout,
    exact: true,
    component: Views.ResetPasswordView,
  },
  // ---------VerifyEmail---------
  {
    path: RoutePaths.verifyEmail,
    layout: NoDashboardLayout,
    exact: true,
    component: Views.VerifyEmailView,
  },
  // ---------------SignUp---------
  {
    path: SIGN_UP_URL,
    layout: NoDashboardLayout,
    isDeactive: IS_ENABLE_SIGN_UP,
    exact: true,
    component: Views.SignUpView,
  },
  {
    path: RoutePaths.signUp,
    layout: NoDashboardLayout,
    isDeactive: !IS_ENABLE_SIGN_UP,
    exact: true,
    component: Views.SignUpView,
  },
  // ---------------AboutYou-------
  {
    path: RoutePaths.aboutYou,
    layout: NoDashboardLayout,
    exact: true,
    component: Views.AboutYouView,
  },

  // ---------------Account-------
  {
    path: RoutePaths.account,
    layout: DefaultLayout,
    exact: true,
    component: Views.AccountTabsView,
  },
  
  //----------- seller --------------
  {
    path: RoutePaths.validate,
    layout: ValidateLayout,
    exact: true,
    component: Views.ValidateView,
  },
  {
    path: RoutePaths.results,
    layout: DefaultLayout,
    exact: true,
    component: Views.ResultView,
  },
  {
    path: RoutePaths.notFoundPage,
    layout: DefaultLayout,
    exact: true,
    component: Views.PageNotFoundView,
  },
  {
    path: RoutePaths.progress,
    layout: DefaultLayout,
    exact: true,
    component: Views.ProgressView,
  },
  {
    path: RoutePaths.upload,
    layout: DefaultLayout,
    exact: true,
    component: Views.UploadView,
  },
  {
    path: RoutePaths.wordCounter,
    layout: DefaultLayout,
    exact: true,
    component: Views.WordCorrectorView,
  },
  {
    path: RoutePaths.privacyPolicy,
    layout: NoDashboardLayout,
    exact: true,
    component: Views.PrivacyPolicyView,
  },
  // ---------------Landing-------
  {
    path: RoutePaths.landing,
    layout: NoDashboardLayout,
    exact: true,
    component: Views.LandingView,
  },
  {
    path: RoutePaths.pricing,
    layout: NoDashboardLayout,
    exact: true,
    component: Views.PricingView,
  },
  //should be last one
  {
    layout: PageNotFoundLayout,
    component: Views.PageNotFoundView,
  },
];

export default routes;
