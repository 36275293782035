import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { PredictActions } from "../../modules/validate/ducks/index";
import { bindActionCreators } from "redux";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material";
import TranslateMessage from "../translateManager/TranslateMessage";
import YouTube from 'react-youtube';
import {
    EditViewModal,
    ValidateModal,
} from '../../util/Constants';
import '../../App.css';
import { FAQ } from "./FAQ";



const HelpModal = ({ showModal, closeModal, validateStatus }) => {

    const opts = {
        height: '250',
        width: '500',
        playerVars: {
            autoplay: 1,
        },
    };

    const handleClose = () => {
        closeModal(false);
    }

    const _onReady = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }

    const dispatch = useDispatch();
    const handleButtonClick = () => {
        console.log("handleButtonClick");
        dispatch(PredictActions.clickProductTour());
    };

    return (
        <Modal
            open={showModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableEnforceFocus={true}
        >
            <Box
                className="help-modal"
                style={{ maxHeight: "90%", overflowY: "auto" }}
            >
                <IconButton
                    aria-label="close"
                    onClick={() => handleClose()}
                    sx={{
                        position: "relative",
                        top: '-2%',
                        left: '95%',
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <Typography id="modal-modal-description">
                    <div className="btn-align-center">
                        <Button className="blue-btn-4" sx={{ width: '150px', marginBottom: '20px' }} onClick={handleButtonClick}>
                            Product Tour
                        </Button>
                    </div>

                    <div>
                        <YouTube videoId={validateStatus.isEditMode ? EditViewModal.VIDEO_ID : ValidateModal.VIDEO_ID} opts={opts} onReady={_onReady} />
                    </div>

                </Typography>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingTop: 20,
                }}>
                    <TranslateMessage translateKey={validateStatus.isEditMode ? EditViewModal.DESCRIPTION_KEY : ValidateModal.DESCRIPTION_KEY} />
                </div>
                <div style={{ paddingTop: 20 }}>
                    <FAQ />
                </div>
            </Box>
        </Modal>
    );
}
function mapStateToProps(state) {
    return {
        addRating: state.Predict.addRating,
        validateStatus: state.Predict.validateStatus,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        predictActions: bindActionCreators(PredictActions, dispatch),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(HelpModal)
);