import { Text } from 'react-konva';
/**
 *
 * TableNumber Component.
 *
 * @component
 * @param {Object} props - The props object containing component properties.
 * @param {string} props.tblNo - The table number to be displayed.
 * @param {number} props.startX - The x-coordinate of the starting point.
 * @param {number} props.startY - The y-coordinate of the starting point.
 * @param {Object} props.vertices - The vertices object representing the table's position.
 * @param {number} props.vertices.x - The x-coordinate of the table's vertices.
 * @param {number} props.vertices.y - The y-coordinate of the table's vertices.
 * @param {Object} props.size - The size object representing dimensions.
 * @param {number} props.size.x - The x-dimension of the size.
 * @param {number} props.size.y - The y-dimension of the size.
 * @param {number} props.indent - The amount of indentation for the table number.
 *
 * @description
 * The `TableNumber` component displays a table number at a specified position on a canvas.
 * It receives properties such as the table number (`tblNo`), starting coordinates (`startX`, `startY`),
 * table vertices (`vertices`), size (`size`), and an indentation value (`indent`).
 *
 * @returns {JSX.Element} - Returns the JSX representation of the TableNumber component.
 *
 */
const TableNumber = ({ tblNo, startX, startY, vertices, size, indent }) => {
  const textX = vertices.x * size.x + startX - indent;
  const textY = vertices.y * size.y + startY;

  return (
    <>
      <Text
        x={textX}
        y={textY}
        text={tblNo}
        fontSize={22}
        fontFamily="Arial"
        fill="black"
        fontStyle="bold"
      />
    </>
  );
};

export default TableNumber;
