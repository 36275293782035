import { Router, Switch, Route, Redirect } from "react-router-dom";
import { TranslationManager } from "./components/translateManager/TranslateManager";
import routes, { RoutePaths } from "./routes";
import history from "./util/History";
import { useDispatch } from 'react-redux';
import { AuthActions } from "modules/auth/ducks";
import React, { useEffect } from "react";
import "./App.css";

function App() {

  //eslint-disable-next-line
  useEffect(() => { restoreSession() }, [])
  const dispatch = useDispatch();

  const restoreSession = () => {
    const sessionData = localStorage.getItem("user");
    if (!sessionData) {
      dispatch(AuthActions.setSession(null))
    }
    const userData = sessionData ? JSON.parse(sessionData) : null;
    dispatch(AuthActions.setSession(userData))
    dispatch(AuthActions.getPricingData())
  }

  return (
    <Router history={history}>
      <TranslationManager hasDropdown={false}>
        <div className="app">
          <Switch>
            {routes
              .filter((route) => !route.isDeactive)
              .map((route, index) => {
                return (
                  <ProtectedRoute
                    key={index}
                    exact={route.exact}
                    path={route.path}
                    Component={route.layout(route.component)} />
                );
              })}
          </Switch>
        </div>
      </TranslationManager>
    </Router>
  );
}

const ProtectedRoute = ({ Component, ...rest }) => {
  const sessionData = localStorage.getItem("user");
  const userData = sessionData ? JSON.parse(sessionData) : null;
  const isLogged = userData ? true : false;

  const RenderComponent = (props) => {
    const publicPaths = [RoutePaths.signIn, RoutePaths.signUp, RoutePaths.verifyEmail, RoutePaths.landing]
    const specialPaths = [RoutePaths.forgotPassword, RoutePaths.resetPassword, RoutePaths.pricing]

    if (specialPaths.includes(rest.path)) {
      return <Component {...props} />;
    }
    if (isLogged) {
      const pricingPlanSelected = userData?.data?.user?.subscription
      if (pricingPlanSelected) {
        if (publicPaths.includes(rest.path)) {
          return <Redirect to={RoutePaths.upload} />;
        }
      }
      else {
        return <Redirect to={RoutePaths.pricing} />;
      }
    }
    else {
      if (!publicPaths.includes(rest.path)) {
        return <Redirect to={RoutePaths.signIn} />;
      }
    }
    return <Component {...props} />;
  }
  return (
    <Route
      {...rest}
      render={RenderComponent}
    />
  );
}

export default App;
