import React, { Component } from 'react';
import Button from '@mui/material/Button';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AuthActions } from './ducks';
import { Alert, CircularProgress } from '@mui/material';
import { EMAIL_REGEX } from '../../util/ValidationRegex';
import TranslateMessage from '../../components/translateManager/TranslateMessage';

/**
 * 
 * Renders the Forgot Password page
 * @component
 *
 * @returns {JSX.Element} The JSX element representing the ForgotPassword component.
 * 
 */
class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: '',
      showPassword: false,
      isValidEmail: true,
      isValidPassword: false,
      emailValidateMessage: '',
      passwordValidateMessage: '',
      isChanging: false,
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  /**
   * Email change handler
   * @param {string} email user input email
   */
  emailChangeHandler = email => {
    this.setState({ email, isValidEmail: true, isChanging: true });
  };

  /**
   * Validate email
   * @param {string} email user input email
   * @returns {boolean} true/false
   */
  validateEmail = email => {
    return EMAIL_REGEX.test(email);
  };

  /**
   * Forgot Password Handler
   */
  forgotPasswordHandler = () => {
    debugger;
    const { email } = this.state;
    const { checkEmail, AuthActions } = this.props;
    if (!checkEmail.pending) {
      this.setState({ loading: true });
      if (!this.validateEmail(email)) {
        this.setState({
          isValidEmail: false,
          emailValidateMessage: 'Invalid email.',
        });
      } else {
        this.setState({
          isValidEmail: true,
          emailValidateMessage: '',
          isChanging: false,
        });

        AuthActions.checkEmail({ email });
      }
    }
  };

  render() {
    const { checkEmail } = this.props;
    const { isValidEmail, emailValidateMessage, isChanging } = this.state;
    return (
      <div className="d-flex flex-column justify-content-center h-100">
        <div className="row justify-content-center">
          <div className="col-sm-10 col-md-5 col-lg-5 col-xl-4 col-xxl-3">
            <div className="login-container">
              <div className="row text-center mb-5">
                <h2 className="logo-name mb-0">
                <a href="https://www.docutize.ai">docutize.ai</a>
                  {/* <Link to="/" underline="none">
                    docutize.ai
                  </Link> */}
                </h2>
              </div>
              <div className="row first-row">
                <div className="col-md-12 col-lg-12">
                  <h3 className="text-lg-start">
                    <TranslateMessage translateKey="forgot_pw" />
                  </h3>
                </div>
              </div>
              <div className="row second-row">
                <div className="col-12 mt-4">
                  {!isChanging && checkEmail && checkEmail?.hasError && (
                    <Alert className=" mb-3" severity="error">
                      {checkEmail?.error?.payload?.message}
                    </Alert>
                  )}
                  <div className="mb-1">
                    <label>
                      <TranslateMessage translateKey="email" />*
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      onChange={e => this.emailChangeHandler(e.target.value)}
                    />
                  </div>
                  {!isValidEmail && <div className="error-msg">{emailValidateMessage}</div>}
                  <div className="btn-login">
                    <Button
                      className="blue-btn mt-4 mb-3"
                      variant="contained"
                      onClick={this.forgotPasswordHandler}
                    >
                      {!checkEmail.pending ? (
                        <TranslateMessage translateKey="Submit" />
                      ) : (
                        <CircularProgress className="loading-btn-circle" />
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    predict: state.Predict,
    detections: state.Predict.detections,
    appMode: state.Predict.appMode,
    selectedDropdownElement: state.Predict.selectedDropdownElement,
    pageImage: state.Predict.pageImage,
    viewMode: state.Predict.viewMode,
    checkEmail: state.Auth.checkEmail,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    AuthActions: bindActionCreators(AuthActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPassword));
