import React, { Component } from 'react';
import Button from '@mui/material/Button';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AuthActions } from '../auth/ducks';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Alert, CircularProgress } from '@mui/material';
import TranslateMessage from '../../components/translateManager/TranslateMessage';
import { RESET_PASSWORD_REDIRECT_TIMEOUT } from 'util/Constants';

/**
 * 
 * Renders the Reset Password page
 * @component
 *
 * @returns {JSX.Element} The JSX element representing the ResetPassword component.
 */
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidConfirmPassword: true,
      passwordMessage: '',
      newPassword: '',
      confirmPassword: '',
      showNewPassword: false,
      showConfirmPassword: false,
      isSuccess: false,
      isChanging: true,
      token: null,
    };
  }

  /**
   * 
   * Authenticate user at component mount and redirect to sign in page if not
   */
  componentDidMount() {
    const queryParams = new URLSearchParams(this.props.location.search);
    const token = queryParams.get('token');

    if (token) {
      this.setState({
        token: token,
      });
    } else {
      this.props.history.push('/sign-in');
    }
  }

  /**
   * user input field change
   * @param {string} input user input
   * @param {string} field input field name
   */
  inputChangeHandler = (input, field) => {
    this.setState({
      [field]: input,
      isValidConfirmPassword: true,
      passwordMessage: '',
      isChanging: true,
      isSuccess: false,
    });
  };

  /**
   * reset password handler
   */
  resetPasswordHandler = () => {
    const { confirmPassword, newPassword } = this.state;
    const { resetPassword } = this.props;

    if (!resetPassword.pending) {
      if (newPassword === confirmPassword) {
        this.setState({
          isChanging: false,
        });

        let resetPasswordDto = {
          reset_token: this.state.token,
          password: newPassword,
        };

        this.props.AuthActions.resetPassword({
          resetPasswordDto,
          callback: this.resetPasswordCallBack,
        });
      } else {
        this.setState({
          isValidConfirmPassword: false,
          passwordMessage: 'Confirm password mismatch.',
        });
      }
    }
  };

  /**
   * redirect to login page
   */
  redirectToLogin = () => {
    this.props.history.push('/sign-in');
  }

  /**
   * reset password call back
   */
  resetPasswordCallBack = () => {
    this.setState({
      newPassword: null,
      confirmPassword: null,
      isSuccess: true,
      isChanging: true,
    });

    setTimeout(() => {
      this.redirectToLogin();
    }, RESET_PASSWORD_REDIRECT_TIMEOUT)
  };

  /**
   * passowrd show/hide
   * @param {string} field field name
   */
  showPasswordHandler = field => {
    this.setState(prev => ({
      [field]: !prev[field],
    }));
  };

  render() {
    const {
      isValidConfirmPassword,
      passwordMessage,
      newPassword,
      confirmPassword,
      isSuccess,
      showNewPassword,
      showConfirmPassword,
    } = this.state;
    const { resetPassword } = this.props;
    return (
      <div className="d-flex flex-column justify-content-center h-100">
        <div className="row justify-content-center">
          <div className="col-sm-10 col-md-5 col-lg-5 col-xl-4 col-xxl-3">
            <div className="login-container">
              <div className="row text-center mb-5">
                <h2 className="logo-name mb-0">
                <a href="https://www.docutize.ai">docutize.ai</a>
                  {/* <Link to="/" underline="none">
                    docutize.ai
                  </Link> */}
                </h2>
              </div>
              <div className="row first-row">
                <div className="col-md-12 col-lg-12">
                  <h3 className="text-lg-start">
                    <TranslateMessage translateKey="reset_pw" />
                  </h3>
                </div>
              </div>
              <div className="row second-row">
                <div className="col-12 mt-4">
                  {isSuccess && resetPassword && resetPassword?.data && (
                    <Alert className=" mb-3" severity="success">
                      <p>Your password has been reset successfully!</p>
                      <p>Redirecting to Login page ...</p>
                    </Alert>  
                  )}
                  <div className="mb-1">
                    <label>
                      <TranslateMessage translateKey="new_password" />*
                    </label>
                    <input
                      type={showNewPassword ? 'text' : 'password'}
                      className="form-control"
                      onChange={e => this.inputChangeHandler(e.target.value, 'newPassword')}
                      value={newPassword}
                    />
                    <span
                      className="toggle-password"
                      onClick={() => this.showPasswordHandler('showNewPassword')}
                    >
                      {showNewPassword ? <Visibility /> : <VisibilityOff />}
                    </span>
                  </div>
                  <div className="mt-4 mb-3">
                    <label>
                      <TranslateMessage translateKey="confirm_new_password" />*
                    </label>
                    <input
                      type={showConfirmPassword ? 'text' : 'password'}
                      className="form-control"
                      onChange={e => this.inputChangeHandler(e.target.value, 'confirmPassword')}
                      value={confirmPassword}
                    />
                    <span
                      className="toggle-password"
                      onClick={() => this.showPasswordHandler('showConfirmPassword')}
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </span>
                  </div>
                  {!isValidConfirmPassword && (
                    <div className="error-msg mt-1">{passwordMessage}</div>
                  )}

                  <div className="btn-login">
                    <Button
                      className="blue-btn mt-4 mb-3"
                      variant="contained"
                      onClick={this.resetPasswordHandler}
                    >
                      {!resetPassword.pending && !isSuccess ? (
                        <TranslateMessage translateKey="reset_pw" />
                      ) : (
                        <CircularProgress className="loading-btn-circle" />
                      )}
                      {/* Reset Password */}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resetPassword: state.Auth.resetPassword,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    AuthActions: bindActionCreators(AuthActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));
