import React, { Component } from 'react';
import './CustomMousePointer.css';

/**
 * CustomMousePointer component provides a custom mouse pointer with horizontal and vertical lines.
 * The position of the lines follows the mouse cursor.
 */
class CustomMousePointer extends Component {

  // Initialize the component state
  state = {
    mouseX: 0,
    mouseY: 0,
  };

  /**
   * Event handler for the mousemove event, updating the cursor position.
   * @param {MouseEvent} e - The mousemove event object.
   */
  handleMouseMove = (e) => {
    // Update the component state with the cursor coordinates

    this.setState({
      mouseX: e.clientX,
      mouseY: e.clientY,
    });
  };

  /**
   * Render method to display the custom mouse pointer.
   * @returns {JSX.Element} The custom mouse pointer JSX.
   */
  render() {
    const { mouseX, mouseY } = this.state;

    // Define styles to position the horizontal and vertical lines
    const horizontalLineStyle = {
      top: mouseY + 'px',
    };

    const verticalLineStyle = {
      left: mouseX - 100 + 'px',
    };

    return (
      <div className="custom-mouse-pointer">
        <div className="horizontal-line" style={horizontalLineStyle}></div>
        <div className="vertical-line" style={verticalLineStyle}></div>
      </div>
    );
  }

  /**
   * Add event listener to track the mousemove event.
   */
  componentDidMount() {
    document.addEventListener('mousemove', this.handleMouseMove);
  }

  /**
   * Remove the mousemove event listener when the component is unmounted.
   */
  componentWillUnmount() {
    document.removeEventListener('mousemove', this.handleMouseMove);
  }
}

export default CustomMousePointer;
