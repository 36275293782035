import * as yup from "yup";

const saveDialogSchema = yup.object({
  name: yup.string().required().max(20).default("").label("Name"),
  description: yup
    .string()
    .required()
    .default("")
    .label("Additional Information"),
});

export default saveDialogSchema;
