import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import TranslateMessage from "../translateManager/TranslateMessage";

const getBorder = (props) => {
  if (props.isDragAccept) {
    return "5px solid #0029ff";
  }
  if (props.isDragReject) {
    return "5px solid #ff1744";
  }
  if (props.isFocused) {
    return "5px solid #0029ff";
  }
  return "2px solid #c4c4c4";
};

const Container = styled.div`
  border: ${(props) => getBorder(props)};
  transition: border 0.24s ease-in-out;
`;

export default function CorrectionUpload(props) {
  const { t } = useTranslation();
  const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({ accept: props.accept, multiple: props.multiple });

  useEffect(() => {
    acceptedFiles.map((file) => {
      const fd = new FormData();
      fd.append("file", file, file.name);
      props.callBack(file);
      return file;
    });
  }, [acceptedFiles]);

  const { files, title, acceptFileTypes } = props;
  return (
    <>
      <section className="word-counter">
        <Container
          className="upload-card-word back-img"
          {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
          <input {...getInputProps()} />
          <div>
            <h6 className="result-heading text-center mt-2">
              <b>
                <TranslateMessage translateKey="drag_n_drop" />
              </b>
            </h6>
            <div className="btn-login">
              <Button className="blue-btn mt-2 mb-3" variant="contained">
                <TranslateMessage translateKey="select" />
              </Button>
            </div>
            <h6 className="result-heading text-center mb-0"><b>{t(title)}</b></h6>
            <small>
              <b>
                <TranslateMessage translateKey="accepted_formats" /> {acceptFileTypes}
              </b>
            </small>
            {files
              ? files.map((file, i) => {
                  return (
                    <div key={i} className="mt-1">
                      {file.name}
                    </div>
                  );
                })
              : null}
          </div>
        </Container>
      </section>
    </>
  );
}
