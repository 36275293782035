import { Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TranslateMessage from "../../../components/translateManager/TranslateMessage";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PredictActions } from "../../../modules/validate/ducks/index";
import Loader from "../../../components/shared/Loader";
import DeleteDialog from "./DeleteDialog";
import './configScreen.css';

/**
 * 
 * Renders the upload window with model choosing
 * 
 * @param {*} handleStart
 * 
 * @example
 * // Usage example:
 * <ConfigScreen handleStart={this.handleStart} /> 
 * 
 * @returns {JSX.Element} The JSX element representing the ConfigScreen component. 
 */
const ConfigScreen = ({ handleStart, setGptModalOpen }) => {
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const { data: configData = [], loading } = useSelector(
    (state) => state?.Predict?.getAllConfigs
  );

  const { pending: deleteRequestPending } = useSelector(
    (state) => state?.Predict?.deleteConfig
  );

  useEffect(() => {
    dispatch(PredictActions.getAllConfigs());
  }, [dispatch]);

  const onDelete = (id) => {
    setDeleteId(id);
    setSelectedItem(null);
    setOpenDeleteDialog(true);
  };

  const deleteHandler = (id) => {
    dispatch(PredictActions.deleteConfig({ orderTemplateId: id }));
  };

  const onRowClick = (event, item) => {
    if (selectedItem?.orderTemplateId === item.orderTemplateId) {
      setSelectedItem(null);
    } else {
      setSelectedItem(item);
    }
  };

  return (
    <div className="d-flex flex-column justify-content-center h-100 cs-select-modal">
      <div className="row justify-content-center">
        <div className="col-sm-10 col-md-9 col-lg-8 col-xl-8 col-xxl-8">
          <div className="upload-container">
            <h6 className="result-heading mb-3 mt-1">
              <TranslateMessage translateKey="select_a_structuring_model_or_start_new" />
            </h6>

            {(loading || deleteRequestPending) && <Loader />}

            {openDeleteDialog && (
              <DeleteDialog
                open={openDeleteDialog}
                setOpen={setOpenDeleteDialog}
                onDelete={() => deleteHandler(deleteId)}
              />
            )}

            <TableContainer
              component={Paper}
              sx={{
                border: "2px solid #c4c4c4",
                maxHeight: "75vh",
                minHeight: "30vh",
                "& .MuiTableCell-root": { fontFamily: "inherit" },
              }}
            >
              <Table sx={{ minWidth: "auto" }} aria-label="config table">
                <TableHead sx={{
                  position: "sticky"
                }}>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Last Changes</TableCell>
                    <TableCell>Creator</TableCell>
                    <TableCell>Comment</TableCell>
                    <TableCell align="right">Delete</TableCell>
                  </TableRow>
                </TableHead>

                {
                  <TableBody>
                    {configData?.map((row) => (
                      <TableRow
                        key={row.orderTemplateId}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                          "& .MuiTableCell-root:last-child": {
                            cursor: "default",
                          },
                          cursor: "pointer",
                        }}
                        selected={
                          selectedItem?.orderTemplateId === row.orderTemplateId
                        }
                        onClick={(event) => onRowClick(event, row)}
                      >
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell>{new Date(row.time_stamp).toLocaleString()}</TableCell>
                        <TableCell>{row.creator}</TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell
                          align="right"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Button
                            disabled={row.admin}
                            onClick={() => onDelete(row.orderTemplateId)}
                          >
                            <DeleteOutlinedIcon
                              color={!row.admin ? "warning" : "disabled"}
                            />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                }
              </Table>
            </TableContainer>

            <div className="mt-3 d-flex  cs-config-buttons ">
              <Button
                disabled={selectedItem === null}
                className="blue-btn mt-2 mb-3"
                variant="contained"
                onClick={() => {
                  handleStart({
                    skipped: false,
                    templateId: selectedItem.orderTemplateId,
                  })
                  dispatch(PredictActions.setMetadata({ admin: selectedItem.admin }))
                }
                }
              >
                <TranslateMessage translateKey="next" />
              </Button>
              <Button
                className="blue-btn with-border mt-2 mb-3"
                variant="contained"
                onClick={() => {
                  setGptModalOpen()
                  // handleStart({ skipped: true })
                  dispatch(PredictActions.setMetadata({ admin: false }))
                }
                }
              >
                <TranslateMessage translateKey="config_page_skip_button" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigScreen;
