import React, { Component } from "react";
import "../shared/styles/progressIndicator.css";
import { Progress } from "reactstrap";
import { withRouter } from "react-router-dom";
class progressIndicator extends Component {
  render() {
    return (
      <div
        className="progressBar"
        style={{ verticalAlign: "center", padding: "30vh 0" }}
      >
        <div className="cardFrameProgress">
          <h5>{"Document is Scanning..."}</h5>
          <div style={{ padding: "10%" }}>
            <Progress
              color="success"
              value={this.props.match.params.progress}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(progressIndicator);
