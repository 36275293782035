import React, { useState, useEffect } from "react";

// Displays the page number below to the canvas
function PageNumberIndicator({ zoomOut, match, routePage }) {
  const [page, setPage] = useState(match?.params?.pageNo);

  useEffect(() => {
    setPage(match?.params?.pageNo);
  }, [match?.params?.pageNo]);

  const numOfPages = sessionStorage.getItem("numOfPages");
  return (
    <div style={{ position: "absolute", right: "10px" }}>
      <div className="page-count mt-3 d-flex">
        <input
          type="number"
          className="page-count-new"
          value={page}
          onChange={(e) => setPage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && page !== "") {
              routePage(Number(page));
            }
          }}
        />
        <span className="mt-1 ps-1">/ {numOfPages || 1}</span>
      </div>
    </div>
  );
}

export default PageNumberIndicator;
