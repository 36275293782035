import Modal from '@mui/material/Modal'
import { connect, useDispatch } from "react-redux";
import React, { useCallback, useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import LaunchIcon from '@mui/icons-material/Launch';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { PredictActions } from "../../../modules/validate/ducks";
import './PreviewModal.css'
import { Button } from '@mui/material';
import EndPoint from 'util/EndPoint';
import { FeedbackModal } from '../../../components/modal/FeedbackModal';
import {
    selectNoOfFilesProcessed,
    selectNoOfPagesProcessed
} from 'modules/validate/ducks/selectors';
import history from "../../../util/History";

type Data = Record<string, string[]>

interface PreviewData {
    loading: boolean,
    pending: boolean,
    hasError: boolean,
    data: Data,
    order: Record<string, number>,
    error: {},
}

interface PreviewModalProps {
    open: boolean
    handleClose: () => void
    previewData: PreviewData
    fileCount: number
    numOfPages: number
}

const PreviewModalX = ({ open, handleClose, previewData, fileCount, numOfPages }: PreviewModalProps) => {

    const dispatch = useDispatch();
    const [data, setData] = useState<Data>()
    const [isDownloadLoading, setIsDownloadLoading] = useState(false)
    const [openFeedBackWindow, setOpenFeedBackWindow] = useState(false);

    useEffect(() => {
        // Extracting data and order from the response
        const { data, order } = previewData;

        // Ensure data and order are present
        if (data && order) {
            // Extract keys from the order object and sort them based on their order
            const orderedKeys = Object.keys(order).sort((a, b) => order[a] - order[b]);

            // Create a sortedData object based on the order
            const sortedData: Data = {};
            orderedKeys.forEach(key => {
                sortedData[`${key} `] = data[key];
            });

            // Update the state with the sorted data
            setData(sortedData);
        }
    }, [previewData]);



    const fetchPreviewData = useCallback(() => {
        dispatch(PredictActions.getPreviewResult())
    }, [dispatch])


    useEffect(() => {
        if (open) {
            fetchPreviewData()
            dispatch(PredictActions.getMetaData())
        }
    }, [open, fetchPreviewData])

    const extendedHandleClose = () => {
        handleClose()
        dispatch(PredictActions.clearPreviewResult())
    }

    const downloadFileHandler = () => {
        const newWindow = window.open(EndPoint.DOCUMENT_DOWNLOAD, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
        setIsDownloadLoading(false);
        setOpenFeedBackWindow(true);
    };

    const redirectToDownloadPage = () => {
        history.push('/results');
    }

    const handleDownloadXls = () => {
        setIsDownloadLoading(true)
        dispatch(PredictActions.downloadPreviewResult({ callback: downloadFileHandler }))
    }

    const feedbackHandler = () => {
        setOpenFeedBackWindow(!openFeedBackWindow);
    };

    const navigateToPage = (page: string) => {
        window.location.href = `/validate/${page}`;
    }

    return (
        <>
            <Modal
                open={open}
                onClose={extendedHandleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='preview-modal'>
                    <div className='modal-header'>
                        <div className='section-title'>
                            <div className='modal-title'>File Validation Preview</div>
                            <div className='action-btns'>
                                <Button className="blue-btn-4 blue-btn solid-blu" onClick={() => handleDownloadXls()}>
                                    {isDownloadLoading && <CircularProgress color="inherit" size={15} sx={{ marginRight: 1 }} />}
                                    Download In .xls
                                </Button>
                                <Button className="blue-btn-4 blue-btn" onClick={() => redirectToDownloadPage()}>Download Settings</Button>
                            </div>
                        </div>
                        <div className='close-icon' onClick={() => extendedHandleClose()}><CloseIcon /></div>
                    </div>

                    <div className='modal-body'>
                        {previewData.loading && <div className='loading-container'><CircularProgress /></div>}
                        {(!previewData.loading && data && Object.keys(data).length > 0) && < div className='table-holder'>
                            <TableContainer className='table-o-container'>
                                <Table size='small' stickyHeader aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            {data && Object.keys(data).map((key, index) => {
                                                return <TableCell key={index + key}>{key}</TableCell>
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {data && data[Object.keys(data)[0]].map((_row, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                {data && Object.keys(data).map((key) => {
                                                    if (key === "Upload Page Number ") {
                                                        return <TableCell className='clickable-navigation-link' onClick={() => navigateToPage(data[key][index] || "-")} key={index + key}>{data[key][index] || "-"} <LaunchIcon className='icon' /></TableCell>
                                                    }
                                                    return <TableCell key={index + key}>{data[key][index] || "-"}</TableCell>
                                                })}
                                            </TableRow>
                                        ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>}
                    </div>
                </div>

            </Modal>
            <FeedbackModal
                showModal={openFeedBackWindow}
                closeModal={feedbackHandler}
                fileCount={fileCount}
                numOfPages={numOfPages}
            />
        </>
    )
}

function mapStateToProps(state) {
    return {
        previewData: state.Predict.previewResult,
        fileCount: selectNoOfFilesProcessed(state),
        numOfPages: selectNoOfPagesProcessed(state),
    };
}

export const PreviewModal = connect(mapStateToProps, null)(PreviewModalX);