// Actions
import { createAction } from "redux-actions";
import types from "./types";

var actions = {
  handleTableSelect: createAction(types.HANDLE_TABLE_SELECT),
  handleElementSelect: createAction(types.HANDLE_ELEMENT_SELECT),

  handleNewDetectionSelect: createAction(types.HANDLE_NEW_DETECTION_SELECT),

  handleAppMode: createAction(types.HANDLE_APP_MODE),
  handleViewMode: createAction(types.HANDLE_VIEW_MODE),
  handleSelectedTable: createAction(types.HANDLE_SELECTED_TABLE),

  handleSelectDropdownElement: createAction(
    types.HANDLE_SELECT_DROPDOWN_ELEMENT
  ),

  updateKeyValue: createAction(types.UPDATE_KEY_VALUE),
  updateKeyValueSuccess: createAction(types.UPDATE_KEY_VALUE_SUCCESS),
  updateKeyValueFail: createAction(types.UPDATE_KEY_VALUE_FAILED),

  updateTableHeading: createAction(types.UPDATE_TABLE_HEADING),
  updateTableHeadingSuccess: createAction(types.UPDATE_TABLE_HEADING_SUCCESS),
  updateTableHeadingFail: createAction(types.UPDATE_TABLE_HEADING_FAILED),

  updateSelectedKeyValue: createAction(types.UPDATE_SELECTED_KEY_VALUE),
  updateSelectedKeyValueSuccess: createAction(
    types.UPDATE_SELECTED_KEY_VALUE_SUCCESS
  ),
  updateSelectedKeyValueFail: createAction(
    types.UPDATE_SELECTED_KEY_VALUE_FAILED
  ),

  updateTable: createAction(types.UPDATE_TABLE),
  updateTableSuccess: createAction(types.UPDATE_TABLE_SUCCESS),
  updateTableFail: createAction(types.UPDATE_TABLE_FAILED),

  updateRow: createAction(types.UPDATE_ROW),
  updateRowSuccess: createAction(types.UPDATE_ROW_SUCCESS),
  updateRowFail: createAction(types.UPDATE_ROW_FAILED),

  addKeyValue: createAction(types.ADD_KEY_VALUE),
  addKeyValueSuccess: createAction(types.ADD_KEY_VALUE_SUCCESS),
  addKeyValueFail: createAction(types.ADD_KEY_VALUE_FAILED),

  addHeadingTemplate: createAction(types.ADD_HEADING_TEMPLATE),
  addHeadingTemplateSuccess: createAction(types.ADD_HEADING_TEMPLATE_SUCCESS),
  addHeadingTemplateFail: createAction(types.ADD_HEADING_TEMPLATE_FAILED),

  addHeadingTemplateForTable: createAction(types.ADD_HEADING_TEMPLATE_FOR_TABLE),
  addHeadingTemplateForTableSuccess: createAction(types.ADD_HEADING_TEMPLATE_FOR_TABLE_SUCCESS),
  addHeadingTemplateForTableFail: createAction(types.ADD_HEADING_TEMPLATE_FOR_TABLE_FAILED),

  addTable: createAction(types.ADD_TABLE),
  addTableSuccess: createAction(types.ADD_TABLE_SUCCESS),
  addTableFail: createAction(types.ADD_TABLE_FAILED),

  addColumn: createAction(types.ADD_COLUMN),
  addColumnSuccess: createAction(types.ADD_COLUMN_SUCCESS),
  addColumnFail: createAction(types.ADD_COLUMN_FAILED),

  uploadPDF: createAction(types.UPLOAD_PDF),
  uploadPDFSuccess: createAction(types.UPLOAD_PDF_SUCCESS),
  uploadPDFFail: createAction(types.UPLOAD_PDF_FAILED),

  uploadConfig: createAction(types.UPLOAD_CONFIG),
  uploadConfigSuccess: createAction(types.UPLOAD_CONFIG_SUCCESS),
  uploadConfigFail: createAction(types.UPLOAD_CONFIG_FAILED),

  getFileCount: createAction(types.GET_FILE_COUNT),
  getFileCountSuccess: createAction(types.GET_FILE_COUNT_SUCCESS),
  getFileCountFail: createAction(types.GET_FILE_COUNT_FAILED),

  getTableHeadings: createAction(types.GET_TABLE_HEADINGS),
  getTableHeadingsSuccess: createAction(types.GET_TABLE_HEADINGS_SUCCESS),
  getTableHeadingsFail: createAction(types.GET_TABLE_HEADINGS_FAILED),

  downloadResult: createAction(types.DOWNLOAD_RESULT),
  downloadResultSuccess: createAction(types.DOWNLOAD_RESULT_SUCCESS),
  downloadResultFail: createAction(types.DOWNLOAD_RESULT_FAILED),

  getPreviewResult: createAction(types.GET_PREVIEW_RESULT),
  getPreviewResultSuccess: createAction(types.GET_PREVIEW_RESULT_SUCCESS),
  getPreviewResultFailed: createAction(types.GET_PREVIEW_RESULT_FAILED),
  clearPreviewResult: createAction(types.CLEAR_PREVIEW_RESULT),
  
  skipGptRetry: createAction(types.SKIP_GPT_RETRY),
  skipGptRetrySuccess: createAction(types.SKIP_GPT_RETRY_SUCCESS),
  skipGptRetryFailed: createAction(types.SKIP_GPT_RETRY_FAILED),
  
  retryGptGeneration: createAction(types.RETRY_GPT_GENERATION),
  retryGptGenerationSuccess: createAction(types.RETRY_GPT_GENERATION_SUCCESS),
  retryGptGenerationFailed: createAction(types.RETRY_GPT_GENERATION_FAILED),

  downloadPreviewResult: createAction(types.DOWNLOAD_PREVIEW_RESULT),
  downloadPreviewResultSuccess: createAction(types.DOWNLOAD_PREVIEW_RESULT_SUCCESS),
  downloadPreviewResultFailed: createAction(types.DOWNLOAD_PREVIEW_RESULT_FAILED),

  startPredict: createAction(types.START_PREDICT),
  startPredictSuccess: createAction(types.START_PREDICT_SUCCESS),
  startPredictFail: createAction(types.START_PREDICT_FAILED),

  getProgress: createAction(types.GET_PROGRESS),
  getProgressSuccess: createAction(types.GET_PROGRESS_SUCCESS),
  getProgressFail: createAction(types.GET_PROGRESS_FAILED),

  resizeElement: createAction(types.RESIZE_ELEMENT),
  resizeElementSuccess: createAction(types.RESIZE_ELEMENT_SUCCESS),
  resizeElementFail: createAction(types.RESIZE_ELEMENT_FAILED),

  deleteElement: createAction(types.DELETE_ELEMENT),
  deleteElementSuccess: createAction(types.DELETE_ELEMENT_SUCCESS),
  deleteElementFail: createAction(types.DELETE_ELEMENT_FAILED),

  deleteTable: createAction(types.DELETE_TABLE),
  deleteTableSuccess: createAction(types.DELETE_TABLE_SUCCESS),
  deleteTableFail: createAction(types.DELETE_TABLE_FAILED),

  transformTable: createAction(types.TRANSFORM_TABLE),
  transformTableSuccess: createAction(types.TRANSFORM_TABLE_SUCCESS),
  transformTableFail: createAction(types.TRANSFORM_TABLE_FAILED),

  getPageImage: createAction(types.GET_PAGE_IMAGE),
  getPageImageSuccess: createAction(types.GET_PAGE_IMAGE_SUCCESS),
  getPageImageFail: createAction(types.GET_PAGE_IMAGE_FAILED),

  getPage: createAction(types.GET_PAGE),
  getPageSuccess: createAction(types.GET_PAGE_SUCCESS),
  getPageFail: createAction(types.GET_PAGE_FAILED),

  skip: createAction(types.SKIP),
  skipSuccess: createAction(types.SKIP_SUCCESS),
  skipFail: createAction(types.SKIP_FAILED),

  getProgressCompleteReporter: createAction(
    types.GET_PROGRESS_COMPLETE_REPORTER
  ),
  clearUploadSpace: createAction(types.CLEAR_UPLOAD_SPACE),

  clearPageSpace: createAction(types.CLEAR_PAGE_SPACE),

  getAllDocumentCount: createAction(types.GET_ALL_DOCUMENT_COUNT),
  getAllDocumentCountSuccess: createAction(
    types.GET_ALL_DOCUMENT_COUNT_SUCCESS
  ),
  getAllDocumentCountFail: createAction(types.GET_ALL_DOCUMENT_COUNT_FAILED),

  clearPredictReducer: createAction(types.CLEAR_PREDICT_REDUCER),

  updateNumberOfPages: createAction(types.UPDATE_NUMBER_OF_PAGES),

  addFeedback: createAction(types.ADD_FEEDBACK),
  addFeedbackSuccess: createAction(types.ADD_FEEDBACK_SUCCESS),
  addFeedbackFail: createAction(types.ADD_FEEDBACK_FAILED),

  deleteDocument: createAction(types.DELETE_DOCUMENT),
  deleteDocumentSuccess: createAction(types.DELETE_DOCUMENT_SUCCESS),
  deleteDocumentFail: createAction(types.DELETE_DOCUMENT_FAILED),

  uploadCorrections: createAction(types.UPLOAD_CORRECTIONS),
  uploadCorrectionsSuccess: createAction(types.UPLOAD_CORRECTIONS_SUCCESS),
  uploadCorrectionsFail: createAction(types.UPLOAD_CORRECTIONS_FAILED),

  addTableHeading: createAction(types.ADD_TABLE_HEADING),
  addTableHeadingSuccess: createAction(types.ADD_TABLE_HEADING_SUCCESS),
  addTableHeadingFail: createAction(types.ADD_TABLE_HEADING_FAILED),

  transposeTable: createAction(types.TRANSPOSE_TABLE),
  transposeTableSuccess: createAction(types.TRANSPOSE_TABLE_SUCCESS),
  transposeTableFail: createAction(types.TRANSPOSE_TABLE_FAILED),

  getAllConfigs: createAction(types.GET_ALL_CONFIGS),
  getAllConfigsSuccess: createAction(types.GET_ALL_CONFIGS_SUCCESS),
  getAllConfigsFail: createAction(types.GET_ALL_CONFIGS_FAILED),

  addConfig: createAction(types.ADD_CONFIG),
  addConfigSuccess: createAction(types.ADD_CONFIG_SUCCESS),
  addConfigFail: createAction(types.ADD_CONFIG_FAILED),

  updateConfig: createAction(types.UPDATE_CONFIG),
  updateConfigSuccess: createAction(types.UPDATE_CONFIG_SUCCESS),
  updateConfigFail: createAction(types.UPDATE_CONFIG_FAILED),

  deleteConfig: createAction(types.DELETE_CONFIG),
  deleteConfigSuccess: createAction(types.DELETE_CONFIG_SUCCESS),
  deleteConfigFail: createAction(types.DELETE_CONFIG_FAILED),

  getMetaData: createAction(types.GET_METADATA),
  getMetaDataSuccess: createAction(types.GET_METADATA_SUCCESS),
  getMetaDataFail: createAction(types.GET_METADATA_FAILED),

  getMetaDataSelection: createAction(types.GET_METADATA_SELECTION),
  getMetaDataSelectionSuccess: createAction(types.GET_METADATA_SELECTION_SUCCESS),
  getMetaDataSelectionFail: createAction(types.GET_METADATA_SELECTION_FAILED),

  getMetaDataValidatePage: createAction(types.GET_METADATA_VALIDATE_PAGE),
  getMetaDataValidatePageSuccess: createAction(types.GET_METADATA_VALIDATE_PAGE_SUCCESS),
  getMetaDataValidatePageFail: createAction(types.GET_METADATA_VALIDATE_PAGE_FAILED),

  setMetadata: createAction(types.SET_METADATA),

  clickProductTour: createAction(types.PRODUCT_TOUR),

  changeValidateStatus: createAction(types.CHANGE_VALIDATE_STATUS),
};

export default actions;
