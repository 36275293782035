import { Notyf } from "notyf";
import "notyf/notyf.min.css";

/**
 * Alerts generating class
 * 
 * @example
 * // Usage example:
 * Alerts.showSuccess(data?.message || "Text updated");
 */
class Alerts {
  notyf = new Notyf();
  notyfW = new Notyf({
    duration: 1000,
    position: {
      x: 'right',
      y: 'top',
    },
    types: [
      {
        type: 'warning',
        background: 'orange',
        icon: true,
        // {
        //   className: 'material-icons',
        //   tagName: 'i',
        //   text: 'warning'
        // },
        duration: 2000,
        
      },
      {
        type: 'error',
        background: 'indianred',
        duration: 2000,
        dismissible: true
      }
    ]
  });

  showError(msg) {
    this.notyf.error({
      message: msg,
      duration: 8000,
      position: {
        x: "right",
        y: "top",
      },
      dismissible: true,
    });
    // .on("dismiss", ({ target, event }) => {

    // });
  }

  showSuccess(msg) {
    this.notyf.success({
      message: msg,
      duration: 8000,
      position: {
        x: "right",
        y: "top",
      },
      dismissible: true,
    });
  }

  showWarning(msg) {
    this.notyfW.open({
      type: 'warning',
      message: msg
    });
  }
}
const x = new Alerts();
export default x;

