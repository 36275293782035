import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { PredictActions } from '../../../modules/validate/ducks/index';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { BootstrapDialogTitle } from '../../../components/modal/BootstrapDialogTitle';
import TranslateMessage from '../../../components/translateManager/TranslateMessage';
import saveDialogSchema from './saveDialogSchema';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const defaultValues = saveDialogSchema.cast({});

const SaveDialog = ({ firstPageLoadHandler }) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);

  const { handleSubmit, control } = useForm({
    defaultValues,
    mode: 'onSubmit',
    resolver: yupResolver(saveDialogSchema),
  });

  const onClose = () => {
    setOpen(false);
  };

  const onConfirm = data => {
    dispatch(PredictActions.addConfig(data));
    firstPageLoadHandler(false);
    setOpen(false);
  };

  return (
    <>
      <StyledDialog
        fullWidth={true}
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          className="text-center"
          id="customized-dialog-title"
          onClose={onclose}
        >
          <TranslateMessage translateKey="download_model_title" />
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </BootstrapDialogTitle>
        <DialogContent
          className="structure-popup"
          sx={{
            '& .MuiTextField-root': {
              width: '30ch',
            },
          }}
        >
          <Stack spacing={2} mt={2}>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
              <span className="c-label">
                <TranslateMessage translateKey="download_model_name_input" />
              </span>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    size="small"
                    error={invalid}
                    helperText={error ? error?.message : ''}
                  />
                )}
              />
            </Stack>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
              <span>
                <TranslateMessage translateKey="download_model_description_input" />
              </span>
              <Controller
                name="description"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    size="small"
                    multiline
                    minRows={2}
                    error={invalid}
                    helperText={error ? error?.message : ''}
                  />
                )}
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions className="pt-0 pb-4 st-button-panel">
          <Button
            className="blue-btn-small save-st-button"
            variant="contained"
            onClick={handleSubmit(onConfirm)}
          >
            <TranslateMessage translateKey="download_model_save_button" />
          </Button>
        </DialogActions>
      </StyledDialog>
    </>
  );
};

export default SaveDialog;
