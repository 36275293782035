import React from 'react'
import Faq from "react-faq-component";

const data = {
    title: "FAQs",
    rows: [
        {
            title: "What is the use of the Select page?",
            content: `On the left you can find your uploaded document. The document contains colored boxes. Each box represents an entry on the right in the Text/Table section. These entries can be selected for download.`,
        },
        {
            title: "Why do I need to select data?",
            content:
                "As we do not now the kind of data you are uploading you need to select the data you want to extract from the Text/Table entries using the checkboxes.",
        },
        {
            title: "How do I select data?",
            content: `You select the data (Values Column) you want to extract by clicking the checkboxes or using the Dropdown to assign the correct label. The Labels represent the Column Header of the downloadable file.<br>
            In the Selection column you need to choose which Values you want to extract.<br>
            The Labels column shows the automatic classification of the Values.<br>
            The Values column shows the extracted content. Values with the same Labels need to be selected only once.`,
        },
        {
            title: "What to do if there are similar Labels like e.g. ‘Telephone’ and ‘Phone’?",
            content: `Select the first entry having the Label ‘Telephone’. Now the Dropdown contains the Label ‘Telephone’. For the entry having the Label ‘Phone’ you can use the Dropdown to assign the Label ‘Telephone’.`,
        },
        {
            title: "Can I reuse my selections?",
            content: `In the step ‘Download’ you will be asked to save your selections. This way you can reuse the selections and save time for similar documents.`,
        },
        {
            title: "Can I Edit?",
            content: `Yes of course. You always are in full control and can edit, delete or draw new boxes by switching to Edit mode. You can also click on any cell in the Text/Table section to directly edit the text.`,
        },
        {
            title: "What is 1st row is header?",
            content: `It defines that the body of the table starts from the second row. Besides it helps with auto-selection.`,
        },
        {
            title: "What is Transpose?",
            content: `docutize.ai only selects horizontally. Therefore, this function flips the Table to allow for selecting Tables containing the header in the first column instead of the first row.`,
        },
    ],
};
const styles = {
    // bgColor: 'white',
    titleTextSize: '18px',
    titleTextColor: 'black',
    rowTitleTextSize: 'medium',
    rowContentTextSize: 'medium',
    rowTitleColor: 'black',
    rowContentColor: 'grey',
};

const config = {
    animate: true,
    arrowIcon: "V",
    openOnload: 0,
    expandIcon: "+",
    collapseIcon: "-",
};

export const FAQ = () => {
    return (
        <Faq
            data={data}
            styles={styles}
            config={config}
        />
    )
}
