import { useRef } from 'react';
import AddIcon from '../icons/AddIcon';
import { Text } from 'react-konva';

/**
 * 
 * AddButton Component.
 *
 * @component
 * @param {string} tblNo - The table number to be displayed.
 * @param {function} onClickHandler - The click handler function to be called when the button is clicked.
 * @param {Object} position - The position information of the table.
 * @param {Object} size - The size information of the table.
 * @param {number} startX - The starting x-coordinate of the table's position.
 * @param {number} startY - The starting y-coordinate of the table's position.
 *
 * @description
 * The `AddButton` component displays a button with an associated table number (`tblNo`) and an "add" icon.
 * It takes various props such as the click handler (`onClickHandler`), position (`position`), size (`size`), and starting coordinates (`startX`, `startY`).
 * 
 * @returns {JSX.Element} - Returns the JSX representation of the AddButton component.
 */
const AddButton = ({ tblNo, onClick: onClickHandler, position, size, startX, startY }) => {
  const ref = useRef();

  const iconSize = 16;
  const x = (position.w + position.x) * size.x + startX + iconSize;
  const y = (position.h / 2 + position.y) * size.y + startY;

  const textX = (position.x * size.x + startX) - 15;
  const textY = position.y * size.y + startY;

  const ColumnTextX = (position.w + position.x) * size.x + startX + 5;
  const ColumnTextY = (position.h / 2 + position.y) * size.y + startY - 25;

  const onClick = () => {
    onClickHandler?.();
  };

  return <>
    <Text
      x={textX}
      y={textY}
      text={tblNo}
      fontSize={22}
      fontFamily='Arial'
      fill='black'
      fontStyle='bold'
    />

    <Text
      x={ColumnTextX}
      y={ColumnTextY}
      text={'Column'}
      fontSize={14}
      fontFamily='Arial'
      fill='black'
      fontStyle='bold'
    />

    <AddIcon ref={ref} x={x} y={y} size={iconSize} onClick={onClick} />
  </>;

};

export default AddButton;
