import React, { Component } from "react";
import Konva from "konva";
import { Rect, Transformer } from "react-konva";
import { ColorTheme, ElementType, ViewMode } from "../../util/Constants";

// Uses for draw all the elements on the canval
// ** Most of the canvas drawing logics available
class ColoredRect extends Component {
  state = {
    color: "green",
    shapeRef: null,
    trRef: null,
  };

  componentDidUpdate(prevProps, prevState) {
    const { isSelected } = this.props;

    if (
      prevProps.r3Info.type !== ElementType.ELM_CELL && // transform is only allowed for key value pairs
      isSelected &&
      JSON.stringify(isSelected) !== JSON.stringify(prevProps.isSelected)
    ) {
      this.state.trRef.current.nodes([this.state.shapeRef.current]);
      this.state.trRef.current.getLayer().batchDraw();
    }
  }

  handleClick = () => {
    this.setState({
      color: Konva.Util.getRandomColor(),
    });
  };

  render() {
    const { r3Info, id, data, pageId, selectedTable, page } = this.props;

    this.state.shapeRef = React.createRef();
    this.state.trRef = React.createRef();

    let tableIsSelected = false;
    const drawingTableId = r3Info?.type === ElementType.ELM_CELL ? data?.table_id : undefined;
    const selectedTableId = (selectedTable && selectedTable > 0 && page?.data?.detections?.table?.length > 0) ? page?.data?.detections?.table[selectedTable - 1]?.id : undefined;

    if (drawingTableId !== undefined && selectedTableId !== undefined && drawingTableId === selectedTableId) {
      tableIsSelected = true;
    }

    // *****************TODO**********************
    let colorStroke =
      r3Info.type === ElementType.ELM_KEY_VAL
        ? `hsl(${data?.colorCode?.h}, ${data?.colorCode?.s}%, ${data?.colorCode?.l}%)`
        : // ? this.props.color
        r3Info.type === ElementType.ELM_CELL
          ? (tableIsSelected ? ColorTheme.SELECTED_TABLE_CELL_CLR : ColorTheme.TABLE_CELL_CLR)
          : r3Info.type === ElementType.ELM_TABLE
            ? ColorTheme.TABLE_CLR
            : ColorTheme.COLUMN_CLR;

    let colorFilled =
      r3Info.type === ElementType.ELM_KEY_VAL
        ? `hsl(${data?.colorCode?.h}, ${data?.colorCode?.s}%, ${data?.colorCode?.l}%)`
        : // ? this.props.color
        r3Info.type === ElementType.ELM_CELL
          ? ColorTheme.TABLE_CELL_CLR
          : r3Info.type === ElementType.ELM_TABLE
            ? ColorTheme.TABLE_EDIT_MODE_CLR
            : data?.colorCode;

    /**
      * Calculate the opacity for an element based on the view mode and selected title.
      * @returns {number} The opacity value.
      */
    const setOpacity = () => {
      if (data.colSelected === true) return 0.3;
      if (this.props.viewMode.mode === ViewMode.DEFAULT_VIEW && data.type === 'keyValuePair' && !(data.selected_title === '' || data.selected_title === '-')) return 0.2;
      if (this.props.viewMode.mode !== ViewMode.DEFAULT_VIEW) return 0.2;
      return 1
    };

    /**
     * Calculate the fill color for an element based on the view mode.
     * @returns {string | null} The fill color or null.
     */
    const setFill = () => {
      if (data.colSelected === true) return colorFilled;
      if (this.props.viewMode.mode === ViewMode.DEFAULT_VIEW && data.type === 'keyValuePair' && !(data.selected_title === '' || data.selected_title === '-')) return colorFilled;
      if (this.props.viewMode.mode !== ViewMode.DEFAULT_VIEW) return colorFilled;
      return null;
    };

    /**
     * Calculate the stroke color for an element based on the view mode.
     * @returns {string | null} The stroke color or null.
     */
    const setStroke = () => {
      if (data.colSelected === true) return null;
      if (this.props.viewMode.mode === ViewMode.DEFAULT_VIEW && data.type === 'keyValuePair' && !(data.selected_title === '' || data.selected_title === '-')) return null;
      if (this.props.viewMode.mode === ViewMode.DEFAULT_VIEW) return colorStroke;
      return null;
    };

    return (
      <div>
        <>
          <React.Fragment>
            <Rect
              opacity={setOpacity()}
              ref={this.state.shapeRef}
              x={r3Info.x + this.props.startX}
              y={r3Info.y + this.props.startY}
              width={r3Info.w}
              height={r3Info.h}
              stroke={setStroke()}
              strokeWidth={1}
              shadowBlur={5}
              onClick={() => {
                this.props.selectElementHandler(data, id);
              }}
              draggable={
                this.props.draggable && r3Info.type !== ElementType.ELM_CELL
              }
              onDragEnd={(e) => this.props.onDragEndHandler(e, data, pageId)}
              fill={setFill()}
              onTransformEnd={(e) => {
                // transformer is changing scale of the node
                // and NOT its width or height
                // but in the store we have only width and height
                // to match the data better we will reset scale on transform end
                const node = this.state.shapeRef.current;
                const scaleX = node.scaleX();
                const scaleY = node.scaleY();

                // we will reset it back
                node.scaleX(1);
                node.scaleY(1);
                this.props.onChange({
                  ...r3Info,
                  x: node.x(),
                  y: node.y(),
                  // set minimal value
                  width: Math.max(5, node.width() * scaleX),
                  height: Math.max(node.height() * scaleY),
                });
              }}
            />
            {this.props.isSelected && (
              <Transformer
                ref={this.state.trRef}
                rotateEnabled={false}
                boundBoxFunc={(oldBox, newBox) => {
                  // limit resize
                  if (newBox.width < 5 || newBox.height < 5) {
                    return oldBox;
                  }
                  return newBox;
                }}
              />
            )}
          </React.Fragment>
        </>
      </div>
    );
  }
}

export default ColoredRect;
