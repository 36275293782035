import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class PrivacyPolicy extends Component {
  render() {
    return (
      <div
        className="mt-5 pt-5"
        style={{ width: "100%", textAlign: "center" }}>
        <h2 className="logo-name mb-0">
          <Link
            to="/"
            underline="none">
            docutize.ai
          </Link>
        </h2>
      </div>
    );
  }
}
