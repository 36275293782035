import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function ExceedManager({ open, handleClose, maxLimit, currentCount, monthlyRenewalDate }) {

  const formattedDate = new Date(monthlyRenewalDate).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" id="modal-modal-description">
            Your monthly page limit is exceeded.
          </Typography>
          <Typography variant="body1" id="modal-modal-description" sx={{ mt: 2 }}>
            You can upload only {maxLimit - currentCount} pages.
          </Typography>
          <Typography variant="caption" id="modal-modal-description">
            Page quota will be  reset on {formattedDate}.
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
