// hook that provides the previous value of a prop using useRef
import { useRef, useEffect } from 'react';

export default function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
