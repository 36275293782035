import { API_BASE_URL } from "./Constants";

export const base_url = API_BASE_URL;

const EndPoint = {
  GET_PREDICTION: base_url + "get-prediction",
  KEY_VALUE_PAIR: base_url + "key-value-pair",
  TABLE: base_url + "table",
  TABLE_HEADING: base_url + "table/ordering",
  KEY_VALUE_PAIR_ORDERING: base_url + "key-value-pair/ordering",
  CELL: base_url + "cell",
  UPDATE_TABLE: base_url + "update-table",
  // ADD_KEY_VALUE: base_url + "key-value-pair",
  ADD_TABLE: base_url + "table",
  ADD_COLUMN: base_url + "add-column",
  UPLOAD_FILES: base_url + "upload-files",
  UPLOAD_CONFIG: base_url + "config-upload",
  START_PREDICT: base_url + "start-prediction",
  GET_PROGRESS: base_url + "get-progress",
  DOCUMENT_DOWNLOAD: base_url + "document/download",
  DOCUMENT_GENERATE: base_url + "document/generate",
  DOCUMENT_TITLE_TEMPLATE_DOWNLOAD:
    base_url + "document/title-template/download",
  DOCUMENT_COUNT: base_url + "document/count",
  DOCUMENT_HEADING_TEMPLATE_ALL: base_url + "document/headings-template-all",
  ORDERING_HEADING_TEMPLATE: base_url + "ordering/headings-template",
  KEY_VALUE_PAIR_RE_PROCESS: base_url + "key-value-pair/reprocess",
  DELETE_ELEMENT: base_url + "delete-element",
  TRANSFORM_TABLE: base_url + "table/reprocess",
  GET_CURRENT_PAGE: base_url + "get-current-page",
  GET_NEXT_PAGE: base_url + "get-next-page",
  GET_PREVIOUS_PAGE: base_url + "get-previous-page",
  PAGE_IMAGE: base_url + "page/image",
  PAGE: base_url + "page",
  GENERATE_DOCUMENT: base_url + "generate-document",
  DOCUMENT_COUNT_ALL: base_url + "document/count-all",
  ADD_FEEDBACK: base_url + "add-feedback",
  DOCUMENT: base_url + "document",
  DOCUMENT_CORRECTIONS_UPLOAD: base_url + "document/corrections/upload",
  DOCUMENT_CORRECTIONS_DOWNLOAD: base_url + "document/corrections/download",

  LOGIN: base_url + "user/login",
  SIGN_OUT: base_url + "user/signout",
  SIGN_UP: base_url + "user/signup",
  PRICING_PLANS: base_url + "subscriptions/get-subscription-data",
  SUBSCRIBE_PLAN: base_url + "subscriptions/create-checkout-session",
  SUBSCRIBE_FREE_PLAN: base_url + "subscriptions/start-free-subscription",
  USER_PORTAL_SESSION: base_url + "subscriptions/user-portal-session",
  USER_INFO: base_url + "user",
  CHANGE_PASSWORD: base_url + "user/password",
  RESET_PASSWORD: base_url + "user/password-reset-key",
  USER_ACCOUNT: base_url + "user/account",
  UPDATE_USER: base_url + "user/update-user",
  CHECK_EMAIL: base_url + "user/check_email",
  ADD_TABLE_HEADING: base_url + "table/heading",
  TRANSPOSE_TABLE_URL: base_url + "table/transpose",
  GET_ALL_CONFIGS: base_url + "ordering/templates",
  ADD_CONFIG: base_url + "ordering/template",
  UPDATE_CONFIG: base_url + "ordering/template",
  DELETE_CONFIG: base_url + "ordering/template",
  GET_METADATA: base_url + "metadata/download-page",
  GET_METADATA_SELECTION: base_url + "metadata/selection-status", 
  GET_METADATA_VALIDATE_PAGE: base_url + "metadata/validate-page-metadata" ,
  SKIP_GPT_RETRY: base_url + "skip-gpt",
  RETRY_GPT_DATA_FETCH: base_url + "ordering/retry_llm"
};

export default EndPoint;
