const action_header = "predict/";

// Types
var reduxTypes = {
  HANDLE_TABLE_SELECT: action_header + "HANDLE_TABLE_SELECT",
  HANDLE_ELEMENT_SELECT: action_header + "HANDLE_ELEMENT_SELECT",

  HANDLE_NEW_DETECTION_SELECT: action_header + "HANDLE_NEW_DETECTION_SELECT",

  HANDLE_APP_MODE: action_header + "HANDLE_APP_MODE",

  HANDLE_VIEW_MODE: action_header + "HANDLE_VIEW_MODE",

  HANDLE_SELECTED_TABLE: action_header + "HANDLE_SELECTED_TABLE",

  HANDLE_SELECT_DROPDOWN_ELEMENT:
    action_header + "HANDLE_SELECT_DROPDOWN_ELEMENT",

  UPDATE_KEY_VALUE: action_header + "UPDATE_KEY_VALUE",
  UPDATE_KEY_VALUE_SUCCESS: action_header + "UPDATE_KEY_VALUE_SUCCESS",
  UPDATE_KEY_VALUE_FAILED: action_header + "UPDATE_KEY_VALUE_FAILED",

  UPDATE_TABLE_HEADING: action_header + "UPDATE_TABLE_HEADING",
  UPDATE_TABLE_HEADING_SUCCESS: action_header + "UPDATE_TABLE_HEADING_SUCCESS",
  UPDATE_TABLE_HEADING_FAILED: action_header + "UPDATE_TABLE_HEADING_FAILED",

  UPDATE_SELECTED_KEY_VALUE: action_header + "UPDATE_SELECTED_KEY_VALUE",
  UPDATE_SELECTED_KEY_VALUE_SUCCESS:
    action_header + "UPDATE_SELECTED_KEY_VALUE_SUCCESS",
  UPDATE_SELECTED_KEY_VALUE_FAILED:
    action_header + "UPDATE_SELECTED_KEY_VALUE_FAILED",

  UPDATE_ROW: action_header + "UPDATE_ROW",
  UPDATE_ROW_SUCCESS: action_header + "UPDATE_ROW_SUCCESS",
  UPDATE_ROW_FAILED: action_header + "UPDATE_ROW_FAILED",

  UPDATE_TABLE: action_header + "UPDATE_TABLE",
  UPDATE_TABLE_SUCCESS: action_header + "UPDATE_TABLE_SUCCESS",
  UPDATE_TABLE_FAILED: action_header + "UPDATE_TABLE_FAILED",

  ADD_KEY_VALUE: action_header + "ADD_KEY_VALUE",
  ADD_KEY_VALUE_SUCCESS: action_header + "ADD_KEY_VALUE_SUCCESS",
  ADD_KEY_VALUE_FAILED: action_header + "ADD_KEY_VALUE_FAILED",

  // add-heading-headings-template
  ADD_HEADING_TEMPLATE: action_header + "ADD_HEADING_TEMPLATE",
  ADD_HEADING_TEMPLATE_SUCCESS: action_header + "ADD_HEADING_TEMPLATE_SUCCESS",
  ADD_HEADING_TEMPLATE_FAILED: action_header + "ADD_HEADING_TEMPLATE_FAILED",

  // add-heading-headings-template for table
  ADD_HEADING_TEMPLATE_FOR_TABLE: action_header + "ADD_HEADING_TEMPLATE_FOR_TABLE",
  ADD_HEADING_TEMPLATE_FOR_TABLE_SUCCESS: action_header + "ADD_HEADING_TEMPLATE_FOR_TABLE_SUCCESS",
  ADD_HEADING_TEMPLATE_FOR_TABLE_FAILED: action_header + "ADD_HEADING_TEMPLATE_FOR_TABLE_FAILED",

  ADD_TABLE: action_header + "ADD_TABLE",
  ADD_TABLE_SUCCESS: action_header + "ADD_TABLE_SUCCESS",
  ADD_TABLE_FAILED: action_header + "ADD_TABLE_FAILED",

  ADD_COLUMN: action_header + "ADD_COLUMN",
  ADD_COLUMN_SUCCESS: action_header + "ADD_COLUMN_SUCCESS",
  ADD_COLUMN_FAILED: action_header + "ADD_COLUMN_FAILED",

  UPLOAD_PDF: action_header + "UPLOAD_PDF",
  UPLOAD_PDF_SUCCESS: action_header + "UPLOAD_PDF_SUCCESS",
  UPLOAD_PDF_FAILED: action_header + "UPLOAD_PDF_FAILED",

  UPLOAD_CONFIG: action_header + "UPLOAD_CONFIG",
  UPLOAD_CONFIG_SUCCESS: action_header + "UPLOAD_CONFIG_SUCCESS",
  UPLOAD_CONFIG_FAILED: action_header + "UPLOAD_CONFIG_FAILED",

  GET_FILE_COUNT: action_header + "GET_FILE_COUNT",
  GET_FILE_COUNT_SUCCESS: action_header + "GET_FILE_COUNT_SUCCESS",
  GET_FILE_COUNT_FAILED: action_header + "GET_FILE_COUNT_FAILED",

  GET_TABLE_HEADINGS: action_header + "GET_TABLE_HEADINGS",
  GET_TABLE_HEADINGS_SUCCESS: action_header + "GET_TABLE_HEADINGS_SUCCESS",
  GET_TABLE_HEADINGS_FAILED: action_header + "GET_TABLE_HEADINGS_FAILED",

  DOWNLOAD_RESULT: action_header + "DOWNLOAD_RESULT",
  DOWNLOAD_RESULT_SUCCESS: action_header + "DOWNLOAD_RESULT_SUCCESS",
  DOWNLOAD_RESULT_FAILED: action_header + "DOWNLOAD_RESULT_FAILED",
  
  GET_PREVIEW_RESULT: action_header + "GET_PREVIEW_RESULT",
  GET_PREVIEW_RESULT_SUCCESS: action_header + "GET_PREVIEW_RESULT_SUCCESS",
  GET_PREVIEW_RESULT_FAILED: action_header + "GET_PREVIEW_RESULT_FAILED",
  CLEAR_PREVIEW_RESULT: action_header + "CLEAR_PREVIEW_RESULT",
  
  DOWNLOAD_PREVIEW_RESULT: action_header + "DOWNLOAD_PREVIEW_RESULT",
  DOWNLOAD_PREVIEW_RESULT_SUCCESS: action_header + "DOWNLOAD_PREVIEW_RESULT_SUCCESS",
  DOWNLOAD_PREVIEW_RESULT_FAILED: action_header + "DOWNLOAD_PREVIEW_RESULT_FAILED",

  START_PREDICT: action_header + "START_PREDICT",
  START_PREDICT_SUCCESS: action_header + "START_PREDICT_SUCCESS",
  START_PREDICT_FAILED: action_header + "START_PREDICT_FAILED",

  SKIP_GPT_RETRY: action_header + "SKIP_GPT_RETRY",
  SKIP_GPT_RETRY_SUCCESS: action_header + "SKIP_GPT_RETRY_SUCCESS",
  SKIP_GPT_RETRY_FAILED: action_header + "SKIP_GPT_RETRY_FAILED",
  
  RETRY_GPT_GENERATION: action_header + "RETRY_GPT_GENERATION",
  RETRY_GPT_GENERATION_SUCCESS: action_header + "RETRY_GPT_GENERATION_SUCCESS",
  RETRY_GPT_GENERATION_FAILED: action_header + "RETRY_GPT_GENERATION_FAILED",

  GET_PROGRESS: action_header + "GET_PROGRESS",
  GET_PROGRESS_SUCCESS: action_header + "GET_PROGRESS_SUCCESS",
  GET_PROGRESS_FAILED: action_header + "GET_PROGRESS_FAILED",

  RESIZE_ELEMENT: action_header + "RESIZE_ELEMENT",
  RESIZE_ELEMENT_SUCCESS: action_header + "RESIZE_ELEMENT_SUCCESS",
  RESIZE_ELEMENT_FAILED: action_header + "RESIZE_ELEMENT_FAILED",

  DELETE_ELEMENT: action_header + "DELETE_ELEMENT",
  DELETE_ELEMENT_SUCCESS: action_header + "DELETE_ELEMENT_SUCCESS",
  DELETE_ELEMENT_FAILED: action_header + "DELETE_ELEMENT_FAILED",

  DELETE_TABLE: action_header + "DELETE_TABLE",
  DELETE_TABLE_SUCCESS: action_header + "DELETE_TABLE_SUCCESS",
  DELETE_TABLE_FAILED: action_header + "DELETE_TABLE_FAILED",

  TRANSFORM_TABLE: action_header + "TRANSFORM_TABLE",
  TRANSFORM_TABLE_SUCCESS: action_header + "TRANSFORM_TABLE_SUCCESS",
  TRANSFORM_TABLE_FAILED: action_header + "TRANSFORM_TABLE_FAILED",

  GET_PAGE_IMAGE: action_header + "GET_PAGE_IMAGE",
  GET_PAGE_IMAGE_SUCCESS: action_header + "GET_PAGE_IMAGE_SUCCESS",
  GET_PAGE_IMAGE_FAILED: action_header + "GET_PAGE_IMAGE_FAILED",

  GET_PAGE: action_header + "GET_PAGE",
  GET_PAGE_SUCCESS: action_header + "GET_PAGE_SUCCESS",
  GET_PAGE_FAILED: action_header + "GET_PAGE_FAILED",

  SKIP: action_header + "SKIP",
  SKIP_SUCCESS: action_header + "SKIP_SUCCESS",
  SKIP_FAILED: action_header + "SKIP_FAILED",

  GET_PROGRESS_COMPLETE_REPORTER:
    action_header + "GET_PROGRESS_COMPLETE_REPORTER",

  CLEAR_UPLOAD_SPACE: action_header + "CLEAR_UPLOAD_SPACE",

  CLEAR_PAGE_SPACE: action_header + "CLEAR_PAGE_SPACE",

  GET_ALL_DOCUMENT_COUNT: action_header + "GET_ALL_DOCUMENT_COUNT",
  GET_ALL_DOCUMENT_COUNT_SUCCESS:
    action_header + "GET_ALL_DOCUMENT_COUNT_SUCCESS",
  GET_ALL_DOCUMENT_COUNT_FAILED:
    action_header + "GET_ALL_DOCUMENT_COUNT_FAILED",

  CLEAR_PREDICT_REDUCER: action_header + "CLEAR_PREDICT_REDUCER",

  UPDATE_NUMBER_OF_PAGES: action_header + "UPDATE_NUMBER_OF_PAGES",

  ADD_FEEDBACK: action_header + "ADD_FEEDBACK",
  ADD_FEEDBACK_SUCCESS: action_header + "ADD_FEEDBACK_SUCCESS",
  ADD_FEEDBACK_FAILED: action_header + "ADD_FEEDBACK_FAILED",

  DELETE_DOCUMENT: action_header + "DELETE_DOCUMENT",
  DELETE_DOCUMENT_SUCCESS: action_header + "DELETE_DOCUMENT_SUCCESS",
  DELETE_DOCUMENT_FAILED: action_header + "DELETE_DOCUMENT_FAILED",

  UPLOAD_CORRECTIONS: action_header + "UPLOAD_CORRECTIONS",
  UPLOAD_CORRECTIONS_SUCCESS: action_header + "UPLOAD_CORRECTIONS_SUCCESS",
  UPLOAD_CORRECTIONS_FAILED: action_header + "UPLOAD_CORRECTIONS_FAILED",

  ADD_TABLE_HEADING: action_header + "ADD_TABLE_HEADING",
  ADD_TABLE_HEADING_SUCCESS: action_header + "ADD_TABLE_HEADING_SUCCESS",
  ADD_TABLE_HEADING_FAILED: action_header + "ADD_TABLE_HEADING_FAILED",

  TRANSPOSE_TABLE: action_header + "TRANSPOSE_TABLE",
  TRANSPOSE_TABLE_SUCCESS: action_header + "TRANSPOSE_TABLE_SUCCESS",
  TRANSPOSE_TABLE_FAILED: action_header + "TRANSPOSE_TABLE_FAILED",

  GET_ALL_CONFIGS: action_header + "GET_ALL_CONFIGS",
  GET_ALL_CONFIGS_SUCCESS: action_header + "GET_ALL_CONFIGS_SUCCESS",
  GET_ALL_CONFIGS_FAILED: action_header + "GET_ALL_CONFIGS_FAILED",

  DELETE_CONFIG: action_header + "DELETE_CONFIG",
  DELETE_CONFIG_SUCCESS: action_header + "DELETE_CONFIG_SUCCESS",
  DELETE_CONFIG_FAILED: action_header + "DELETE_CONFIG_FAILED",

  ADD_CONFIG: action_header + "ADD_CONFIG",
  ADD_CONFIG_SUCCESS: action_header + "ADD_CONFIG_SUCCESS",
  ADD_CONFIG_FAILED: action_header + "ADD_CONFIG_FAILED",

  UPDATE_CONFIG: action_header + "UPDATE_CONFIG",
  UPDATE_CONFIG_SUCCESS: action_header + "UPDATE_CONFIG_SUCCESS",
  UPDATE_CONFIG_FAILED: action_header + "UPDATE_CONFIG_FAILED",

  GET_METADATA: action_header + "GET_METADATA",
  GET_METADATA_SUCCESS: action_header + "GET_METADATA_SUCCESS",
  GET_METADATA_FAILED: action_header + "GET_METADATA_FAILED",

  GET_METADATA_SELECTION: action_header + "GET_METADATA_SELECTION",
  GET_METADATA_SELECTION_SUCCESS: action_header + "GET_METADATA_SELECTION_SUCCESS",
  GET_METADATA_SELECTION_FAILED: action_header + "GET_METADATA_SELECTION_FAILED",

  GET_METADATA_VALIDATE_PAGE: action_header + "GET_METADATA_VALIDATE_PAGE",
  GET_METADATA_VALIDATE_PAGE_SUCCESS: action_header + "GET_METADATA_VALIDATE_PAGE_SUCCESS",
  GET_METADATA_VALIDATE_PAGE_FAILED: action_header + "GET_METADATA_VALIDATE_PAGE_FAILED",

  SET_METADATA: action_header + "SET_METADATA",

  PRODUCT_TOUR : action_header + "PRODUCT_TOUR",

  CHANGE_VALIDATE_STATUS : action_header + "CHANGE_VALIDATE_STATUS",

};

export default reduxTypes;
