import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';


export const IOSSwitch = styled((props: SwitchProps) => {
    return <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />;
})(({ theme }) => (
    {
        width: 70,
        height: 35,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 2,
            margin: 0,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(36px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: '#0041FF',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 1,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 1,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 30,
            height: 30,
        },
        '& .MuiSwitch-track': {
            borderRadius: 30,
            backgroundColor: '#E9E9EA',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));