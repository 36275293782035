import enlang from './entries/en';

const AppLocale = {
  eng: enlang,
};

const Languages = [
  {
    code: 'eng',
    name: 'English',
    dir: 'ltr',
    country_code: 'gb',
  },
  {
    code: 'deu',
    name: 'Deutsch',
    dir: 'ltr',
    country_code: 'gb',
  },
];

const LanguagesOptions = [
  { label: 'Deutsch', value: 'deu' },
  { label: 'English', value: 'eng' },
];

const DEFAULT_LANGUAGE_CODE = 'eng';

export const ENGLISH_LANGUAGE_CODE = 'eng';
export const DEUTSCH_LANGUAGE_CODE = 'deu';

export { AppLocale, Languages, DEFAULT_LANGUAGE_CODE, LanguagesOptions };
