import types from "./types";
import { handleActions } from "redux-actions";
import dummy from "../../../data/dummy.json";
import { ViewMode, AppMode } from "../../../util/Constants";

const initialState = {
  selectedTable: {
    loading: true,
    pending: false,
    hasError: false,
    data: [],
    error: {},
  },
  selectedElement: {
    loading: true,
    pending: false,
    hasError: false,
    data: [],
    error: {},
  },
  selectedNewDetection: {
    loading: true,
    pending: false,
    hasError: false,
    data: null,
    error: {},
  },
  detections: {
    loading: true,
    pending: false,
    hasError: false,
    data: dummy,
    error: {},
  },
  appMode: {
    mode: AppMode.MOVE_ACTION,
  },

  viewMode: {
    mode: ViewMode.DEFAULT_VIEW,
  },
  updateKeyValue: {
    loading: true,
    pending: false,
    hasError: false,
    data: null,
    error: {},
  },
  updateTableHeading: {
    loading: true,
    pending: false,
    hasError: false,
    data: null,
    error: {},
  },
  updateSelectedKeyValue: {
    loading: true,
    pending: false,
    hasError: false,
    data: null,
    error: {},
  },
  updateRow: {
    loading: true,
    pending: false,
    hasError: false,
    data: null,
    error: {},
  },
  addKeyValue: {
    loading: true,
    pending: false,
    hasError: false,
    data: null,
    error: {},
  },
  addHeadingTemplate: {
    loading: true,
    pending: false,
    hasError: false,
    data: null,
    error: {},
    isHeadingTemplateLoading: false,
  },
  addHeadingTemplateForTable: {
    loading: true,
    pending: false,
    hasError: false,
    data: null,
    error: {},
    isHeadingTemplateForTableLoading: false,
  },
  addTable: {
    loading: true,
    pending: false,
    hasError: false,
    data: null,
    error: {},
  },
  addColumn: {
    loading: true,
    pending: false,
    hasError: false,
    data: null,
    error: {},
  },
  selectedDropdownElement: {
    loading: true,
    pending: false,
    hasError: false,
    data: [],
    error: {},
  },
  uploadPDF: {
    loading: true,
    pending: false,
    hasError: false,
    uploadStarted: false,
    data: [],
    error: {},
  },
  uploadConfig: {
    loading: true,
    pending: false,
    hasError: false,
    data: [],
    error: {},
  },
  getFileCount: {
    loading: true,
    pending: false,
    hasError: false,
    data: [],
    error: {},
  },
  getTableHeadings: {
    loading: true,
    pending: false,
    hasError: false,
    data: [],
    error: {},
  },
  downloadResult: {
    loading: true,
    pending: false,
    hasError: false,
    data: [],
    error: {},
  },
  previewResult: {
    loading: true,
    pending: false,
    hasError: false,
    data: [],
    error: {},
  },
  downloadPreviewResult: {
    loading: true,
    pending: false,
    hasError: false,
    data: [],
    error: {},
  },
  startPredict: {
    loading: true,
    pending: false,
    hasError: false,
    data: [],
    error: {},
  },
  getProgress: {
    loading: true,
    pending: false,
    hasError: false,
    data: [],
    time: Math.round(new Date().getTime() / 1000),
  },
  resizeElement: {
    loading: true,
    pending: false,
    hasError: false,
    data: null,
    error: {},
  },
  deleteElement: {
    loading: true,
    pending: false,
    hasError: false,
    data: null,
    error: {},
  },
  deleteTable: {
    loading: true,
    pending: false,
    hasError: false,
    data: null,
    error: {},
  },
  transformTable: {
    loading: true,
    pending: false,
    hasError: false,
    data: null,
    error: {},
  },
  pageImage: {
    loading: true,
    pending: false,
    hasError: false,
    data: null,
    error: {},
  },
  page: {
    loading: true,
    pending: false,
    hasError: false,
    data: {
      tables: [],
      detections: [],
    },
    error: {},
    toggle: false,
  },
  updateTable: {
    loading: true,
    pending: false,
    hasError: false,
    data: null,
    error: {},
  },
  skip: {
    loading: true,
    pending: false,
    hasError: false,
    data: null,
    error: {},
  },
  progressCompleteReportor: {
    progress: 0,
    isComplete: false,
  },
  getAllDocumentCount: {
    loading: true,
    pending: false,
    hasError: false,
    data: null,
    error: {},
  },
  numOfPages: 1,
  addFeedback: {
    loading: true,
    pending: false,
    hasError: false,
    data: null,
    error: {},
  },
  deleteDocument: {
    loading: true,
    pending: false,
    hasError: false,
    data: null,
    error: {},
  },
  uploadCorrections: {
    loading: true,
    pending: false,
    hasError: false,
    data: [],
    error: {},
  },
  addTableHeading: {
    loading: true,
    pending: false,
    hasError: false,
    data: [],
    error: {},
  },
  transposeTable: {
    loading: true,
    pending: false,
    hasError: false,
    data: [],
    error: {},
  },
  getAllConfigs: {
    loading: true,
    pending: false,
    hasError: false,
    data: null,
    error: {},
  },
  deleteConfig: {
    loading: true,
    pending: false,
    hasError: false,
    data: null,
    error: {},
  },
  addConfig: {
    loading: true,
    pending: false,
    hasError: false,
    data: null,
    error: {},
  },
  updateConfig: {
    loading: true,
    pending: false,
    hasError: false,
    data: null,
    error: {},
  },
  metadata: {
    loading: true,
    pending: false,
    hasError: false,
    data: null,
    error: {},
  },
  validateStatus: {
    isEditMode: false,
    isSelectItem: false,
    isValidatePage: false
  },
  gptRetrySkipStatus: {
    loading: false,
    pending: false,
    data: {},
  },
  gptRetryStatus: {
    loading: false,
    pending: false,
    data: true,
  },
  productTour: false
};

// Reducers from redux-actions
export default handleActions(
  {
    [types.CLEAR_PREDICT_REDUCER]: () => {
      // debugger;
      return {
        ...initialState,
        page: {
          loading: true,
          pending: false,
          hasError: false,
          data: {
            tables: [],
            detections: [],
          },
          error: {},
        },
      };
    },
    [types.CLEAR_PAGE_SPACE]: (state: any) => {
      return {
        ...state,
        page: {
          loading: true,
          pending: false,
          hasError: false,
          data: {
            tables: [],
            detections: [],
          },
          error: {},
        },
      };
    },
    [types.CLEAR_UPLOAD_SPACE]: (state: any) => {
      return {
        ...state,
        uploadPDF: {
          loading: true,
          pending: false,
          hasError: false,
          uploadStarted: false,
          data: [],
          error: {},
        },
        uploadConfig: {
          loading: true,
          pending: false,
          hasError: false,
          data: [],
          error: {},
        },
        startPredict: {
          loading: true,
          pending: false,
          hasError: false,
          data: [],
          error: {},
        },
        getProgress: {
          loading: true,
          pending: false,
          hasError: false,
          data: [],
          time: Math.round(new Date().getTime() / 1000),
        },
      };
    },

    [types.UPDATE_NUMBER_OF_PAGES]: (state: any, { payload }: any) => {
      return {
        ...state,
        numOfPages: payload?.data?.numberOfPages || 0,
      };
    },

    [types.GET_PROGRESS_COMPLETE_REPORTER]: (state: { progressCompleteReportor: any; }, { payload }: any) => {
      return {
        ...state,
        progressCompleteReportor: {
          ...state.progressCompleteReportor,
          progress: payload.progress,
          isComplete: payload.isComplete,
        },
      };
    },

    [types.HANDLE_APP_MODE]: (state: { appMode: any; }, { payload }: any) => {
      let updatedMode = payload.mode;
      return {
        ...state,
        appMode: {
          ...state.appMode,
          mode: updatedMode,
        },
      };
    },

    [types.HANDLE_VIEW_MODE]: (state: { viewMode: any; }, { payload }: any) => {
      return {
        ...state,
        viewMode: {
          ...state.viewMode,
          mode: payload.mode,
        },
      };
    },

    [types.HANDLE_SELECTED_TABLE]: (state: { selectedTable: any; }, { payload }: any) => {
      return {
        ...state,
        selectedTable: {
          ...state.selectedTable,
          data: payload?.selectedTableNo,
        },
      };
    },

    // [types.HANDLE_TABLE_SELECT]: (state, { payload }) => {
    //   return {
    //     ...state,
    //     selectedTable: {
    //       ...state.selectedTable,
    //       loading: true,
    //       pending: true,
    //       data: payload,
    //     },
    //   };
    // },
    [types.HANDLE_ELEMENT_SELECT]: (state: { selectedElement: any; }, { payload }: any) => {
      return {
        ...state,
        selectedElement: {
          ...state.selectedElement,
          loading: true,
          pending: true,
          data: payload,
        },
      };
    },
    [types.HANDLE_SELECT_DROPDOWN_ELEMENT]: (state: { selectedDropdownElement: any; }, { payload }: any) => {
      return {
        ...state,
        selectedDropdownElement: {
          ...state.selectedDropdownElement,
          loading: true,
          pending: true,
          data: payload,
        },
      };
    },

    [types.HANDLE_NEW_DETECTION_SELECT]: (state: { selectedNewDetection: any; }, { payload }: any) => {
      return {
        ...state,
        selectedNewDetection: {
          ...state.selectedNewDetection,
          loading: true,
          pending: true,
          data: payload,
        },
      };
    },

    // ------------------ UPDATE_KEY_VALUE -------------------
    [types.UPDATE_KEY_VALUE]: (state: { updateKeyValue: any; }) => ({
      ...state,
      updateKeyValue: {
        ...state.updateKeyValue,
        loading: true,
        pending: true,
      },
    }),
    [types.UPDATE_KEY_VALUE_SUCCESS]: (state: { updateKeyValue: any; }, { payload }: any) => {
      return {
        ...state,
        updateKeyValue: {
          ...state.updateKeyValue,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },

    [types.UPDATE_KEY_VALUE_FAILED]: (state: { updateKeyValue: any; }, { payload }: any) => ({
      ...state,
      updateKeyValue: {
        ...state.updateKeyValue,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ UPDATE_TABLE_HEADING -------------------
    [types.UPDATE_TABLE_HEADING]: (state: { updateTableHeading: any; addHeadingTemplateForTable: any; }) => ({
      ...state,
      updateTableHeading: {
        ...state.updateTableHeading,
        loading: true,
        pending: true,
      },
      addHeadingTemplateForTable: {
        ...state.addHeadingTemplateForTable,
        isHeadingTemplateForTableLoading: false,
      },
    }),
    [types.UPDATE_TABLE_HEADING_SUCCESS]: (state: { updateTableHeading: any; getTableHeadings: any; addHeadingTemplateForTable: any; metadata: { data: any; }; page: { data: { detections: any; }; }; }, { payload }: any) => {
      return {
        ...state,
        updateTableHeading: {
          ...state.updateTableHeading,
          loading: false,
          pending: false,
          data: payload,
        },
        addHeadingTemplateForTable: {
          ...state.addHeadingTemplateForTable,
          isHeadingTemplateForTableLoading: false,
        },
        metadata: {
          ...state.metadata,
          data: {
            ...state.metadata.data,
            templateChanged: payload.templateChanged,
            newTemplate: payload.newTemplate,
          }
        },
        getTableHeadings: {
          ...state.getTableHeadings,
          loading: false,
          pending: false,
          data: [...payload.headings],
        },
        page: {
          ...state.page,
          data: {
            ...state.page.data,
            detections: {
              ...state.page.data.detections,
              keyValuePair: [...payload.detections.keyValuePair],
              table: [...payload.detections.table],
            },
          },
        },
      };
    },

    [types.UPDATE_TABLE_HEADING_FAILED]: (state: { updateTableHeading: any; addHeadingTemplateForTable: any; }, { payload }: any) => ({
      ...state,
      updateTableHeading: {
        ...state.updateTableHeading,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
      addHeadingTemplateForTable: {
        ...state.addHeadingTemplateForTable,
        isHeadingTemplateForTableLoading: false,
      },
    }),

    // ------------------ UPDATE_SELECTED_KEY_VALUE -------------------
    [types.UPDATE_SELECTED_KEY_VALUE]: (state: { updateSelectedKeyValue: any; addHeadingTemplate: any; page: { data: { detections: { keyValuePair: any[]; }; }; }; }, { payload }: any) => {
      return {
        ...state,
        updateSelectedKeyValue: {
          ...state.updateSelectedKeyValue,
          loading: true,
          pending: true,
        },
        addHeadingTemplate: {
          ...state.addHeadingTemplate,
          isHeadingTemplateLoading: false,
        },
        page: {
          ...state.page,
          data: {
            ...state.page.data,
            detections: {
              ...state.page.data.detections,
              keyValuePair: [
                ...state.page?.data?.detections?.keyValuePair?.map((u: { id: any; }) =>
                  u.id !== payload.updateSelectedKeyValueDto.selectedElement.id
                    ? u
                    : {
                      ...u,
                      selected_title:
                        payload.updateSelectedKeyValueDto.selectedElement
                          .title,
                    }
                ),
              ],
            },
          },
        },
      };
    },
    [types.UPDATE_SELECTED_KEY_VALUE_SUCCESS]: (state: { updateSelectedKeyValue: any; addHeadingTemplate: any; getTableHeadings: any; metadata: { data: any; }; page: { data: { detections: any; }; }; }, { payload }: any) => {
      return {
        ...state,
        updateSelectedKeyValue: {
          ...state.updateSelectedKeyValue,
          loading: false,
          pending: false,
          data: payload,
        },
        addHeadingTemplate: {
          ...state.addHeadingTemplate,
          isHeadingTemplateLoading: false,
        },
        metadata: {
          ...state.metadata,
          data: {
            ...state.metadata.data,
            templateChanged: payload.templateChanged,
            newTemplate: payload.newTemplate,
          }
        },
        getTableHeadings: {
          ...state.getTableHeadings,
          loading: false,
          pending: false,
          data: [...payload.headings],
        },
        page: {
          ...state.page,
          data: {
            ...state.page.data,
            detections: {
              ...state.page.data.detections,
              keyValuePair: [...payload.detections.keyValuePair],
              table: [...payload.detections.table],
            },
          },
        },
      };
    },

    [types.UPDATE_SELECTED_KEY_VALUE_FAILED]: (state: { updateSelectedKeyValue: any; addHeadingTemplate: any; }, { payload }: any) => ({
      ...state,
      updateSelectedKeyValue: {
        ...state.updateSelectedKeyValue,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
      addHeadingTemplate: {
        ...state.addHeadingTemplate,
        isHeadingTemplateLoading: false,
      },
    }),

    // ------------------ UPDATE_ROW -------------------
    [types.UPDATE_ROW]: (state: { updateRow: any; }) => ({
      ...state,
      updateRow: {
        ...state.updateRow,
        loading: true,
        pending: true,
      },
    }),
    [types.UPDATE_ROW_SUCCESS]: (state: { updateRow: any; }, { payload }: any) => {
      return {
        ...state,
        updateRow: {
          ...state.updateRow,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },

    [types.UPDATE_ROW_FAILED]: (state: { updateRow: any; }, { payload }: any) => ({
      ...state,
      updateRow: {
        ...state.updateRow,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ ADD_KEY_VALUE -------------------
    [types.ADD_KEY_VALUE]: (state: { addKeyValue: any; }) => ({
      ...state,
      addKeyValue: {
        ...state.addKeyValue,
        loading: true,
        pending: true,
      },
    }),
    [types.ADD_KEY_VALUE_SUCCESS]: (state: { addKeyValue: any; page: { data: { detections: { keyValuePair: any; }; }; toggle: any; }; }, { payload }: any) => {
      return {
        ...state,
        addKeyValue: {
          ...state.addKeyValue,
          loading: false,
          pending: false,
          data: payload,
        },
        page: {
          ...state.page,
          data: {
            ...state.page.data,
            detections: {
              ...state.page.data.detections,
              keyValuePair: [
                ...state.page.data.detections?.keyValuePair,
                payload.data?.element,
              ],
            },
          },
          toggle: !state.page.toggle,
        },
      };
    },

    [types.ADD_KEY_VALUE_FAILED]: (state: { addKeyValue: any; }, { payload }: any) => ({
      ...state,
      addKeyValue: {
        ...state.addKeyValue,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ ADD_HEADING_TEMPLATE -------------------
    [types.ADD_HEADING_TEMPLATE]: (state: { addHeadingTemplate: any; }) => ({
      ...state,
      addHeadingTemplate: {
        ...state.addHeadingTemplate,
        loading: true,
        pending: true,
        isHeadingTemplateLoading: true,
      },
    }),
    [types.ADD_HEADING_TEMPLATE_SUCCESS]: (state: { addHeadingTemplate: any; getTableHeadings: any; metadata: { data: any; }; page: { data: { detections: any; }; }; }, { payload }: any) => {
      return {
        ...state,
        addHeadingTemplate: {
          ...state.addHeadingTemplate,
          loading: false,
          pending: false,
          data: payload,
          isHeadingTemplateLoading: false,
        },
        getTableHeadings: {
          ...state.getTableHeadings,
          loading: false,
          pending: false,
          data: [...payload.headings],
        },
        metadata: {
          ...state.metadata,
          data: {
            ...state.metadata.data,
            templateChanged: payload.templateChanged,
            newTemplate: payload.newTemplate,
          }
        },
        page: {
          ...state.page,
          loading: false,
          pending: false,
          data: {
            ...state.page.data,
            detections: {
              ...state.page.data.detections,
              keyValuePair: [...payload?.data?.detections?.keyValuePair],
              table: [...payload?.data?.detections?.table],
            },
          },
        },
      };
    },

    [types.ADD_HEADING_TEMPLATE_FAILED]: (state: { addHeadingTemplate: any; }, { payload }: any) => ({
      ...state,
      addHeadingTemplate: {
        ...state.addHeadingTemplate,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
        isHeadingTemplateLoading: false,
      },
    }),

    // ------------------ ADD_HEADING_TEMPLATE_FOR_TABLE -------------------
    [types.ADD_HEADING_TEMPLATE_FOR_TABLE]: (state: { addHeadingTemplateForTable: any; }) => ({
      ...state,
      addHeadingTemplateForTable: {
        ...state.addHeadingTemplateForTable,
        loading: true,
        pending: true,
        isHeadingTemplateForTableLoading: true,
      },
    }),
    [types.ADD_HEADING_TEMPLATE_FOR_TABLE_SUCCESS]: (state: { addHeadingTemplateForTable: any; getTableHeadings: any; metadata: { data: any; }; page: { data: { detections: any; }; }; }, { payload }: any) => {
      return {
        ...state,
        addHeadingTemplateForTable: {
          ...state.addHeadingTemplateForTable,
          loading: false,
          pending: false,
          data: payload,
          isHeadingTemplateForTableLoading: false,
        },
        getTableHeadings: {
          ...state.getTableHeadings,
          loading: false,
          pending: false,
          data: [...payload.headings],
        },
        metadata: {
          ...state.metadata,
          data: {
            ...state.metadata.data,
            templateChanged: payload.templateChanged,
            newTemplate: payload.newTemplate,
          }
        },
        page: {
          ...state.page,
          loading: false,
          pending: false,
          data: {
            ...state.page.data,
            detections: {
              ...state.page.data.detections,
              keyValuePair: [...payload?.data?.detections?.keyValuePair],
              table: [...payload?.data?.detections?.table],
            },
          },
        },
      };
    },

    [types.ADD_HEADING_TEMPLATE_FOR_TABLE_FAILED]: (state: { addHeadingTemplateForTable: any; }, { payload }: any) => ({
      ...state,
      addHeadingTemplateForTable: {
        ...state.addHeadingTemplateForTable,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
        isHeadingTemplateForTableLoading: false,
      },
    }),

    // ------------------ ADD_TABLE -------------------
    [types.ADD_TABLE]: (state: { addTable: any; }) => ({
      ...state,
      addTable: {
        ...state.addTable,
        loading: true,
        pending: true,
      },
    }),
    [types.ADD_TABLE_SUCCESS]: (state: { addTable: any; page: { data: { detections: { table: any; }; }; }; }, { payload }: any) => {
      return {
        ...state,
        addTable: {
          ...state.addTable,
          loading: false,
          pending: false,
          data: payload,
        },
        page: {
          ...state.page,
          data: {
            ...state.page.data,
            detections: {
              ...state.page.data.detections,
              table: [...state.page.data.detections.table, payload.data],
            },
          },
        },
      };
    },

    [types.ADD_TABLE_FAILED]: (state: { addTable: any; }, { payload }: any) => ({
      ...state,
      addTable: {
        ...state.addTable,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ ADD_COLUMN -------------------
    [types.ADD_COLUMN]: (state: { addColumn: any; }) => ({
      ...state,
      addColumn: {
        ...state.addColumn,
        loading: true,
        pending: true,
      },
    }),
    [types.ADD_COLUMN_SUCCESS]: (state: { addColumn: any; }, { payload }: any) => {
      return {
        ...state,
        addColumn: {
          ...state.addColumn,
          loading: false,
          pending: false,
          data: payload,
        },
        // Column is not added to the element list
        // detections: {
        //   ...state.detections,
        //   data: {
        //     ...state.detections.data,
        //     detections: [...state.detections.data.detections],
        //   },
        // },
      };
    },

    [types.ADD_COLUMN_FAILED]: (state: { addColumn: any; }, { payload }: any) => ({
      ...state,
      addColumn: {
        ...state.addColumn,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ UPLOAD_PDF -------------------
    [types.UPLOAD_PDF]: (state: { uploadPDF: any; }) => ({
      ...state,
      uploadPDF: {
        ...state.uploadPDF,
        loading: true,
        pending: true,
        uploadStarted: true,
      },
    }),
    [types.UPLOAD_PDF_SUCCESS]: (state: { uploadPDF: any; }, { payload }: any) => {
      return {
        ...state,
        uploadPDF: {
          ...state.uploadPDF,
          loading: false,
          pending: false,
          data: payload,
          uploadStarted: true,
        },
      };
    },

    [types.UPLOAD_PDF_FAILED]: (state: { uploadPDF: any; }, { payload }: any) => ({
      ...state,
      uploadPDF: {
        ...state.uploadPDF,
        loading: false,
        pending: false,
        hasError: true,
        uploadStarted: true,
        error: { payload },
      },
    }),

    // ------------------ UPLOAD_CONFIG -------------------
    [types.UPLOAD_CONFIG]: (state: { uploadConfig: any; }) => ({
      ...state,
      uploadConfig: {
        ...state.uploadConfig,
        loading: true,
        pending: true,
      },
    }),
    [types.UPLOAD_CONFIG_SUCCESS]: (state: { uploadConfig: any; }, { payload }: any) => {
      return {
        ...state,
        uploadConfig: {
          ...state.uploadConfig,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },

    [types.UPLOAD_CONFIG_FAILED]: (state: { uploadConfig: any; }, { payload }: any) => ({
      ...state,
      uploadConfig: {
        ...state.uploadConfig,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ GET_FILE_COUNT -------------------
    [types.GET_FILE_COUNT]: (state: { getFileCount: any; }) => ({
      ...state,
      getFileCount: {
        ...state.getFileCount,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_FILE_COUNT_SUCCESS]: (state: { getFileCount: any; }, { payload }: any) => {
      return {
        ...state,
        getFileCount: {
          ...state.getFileCount,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },

    [types.GET_FILE_COUNT_FAILED]: (state: { getFileCount: any; }, { payload }: any) => ({
      ...state,
      getFileCount: {
        ...state.getFileCount,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ GET_TABLE_HEADINGS -------------------
    [types.GET_TABLE_HEADINGS]: (state: { getTableHeadings: any; }) => ({
      ...state,
      getTableHeadings: {
        ...state.getTableHeadings,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_TABLE_HEADINGS_SUCCESS]: (state: { getTableHeadings: any; }, { payload }: any) => {
      return {
        ...state,
        getTableHeadings: {
          ...state.getTableHeadings,
          loading: false,
          pending: false,
          data: [...payload.headings],
        },
      };
    },

    [types.GET_TABLE_HEADINGS_FAILED]: (state: { getTableHeadings: any; }, { payload }: any) => ({
      ...state,
      getTableHeadings: {
        ...state.getTableHeadings,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ DOWNLOAD_RESULT -------------------
    [types.DOWNLOAD_RESULT]: (state: { downloadResult: any; }) => ({
      ...state,
      downloadResult: {
        ...state.downloadResult,
        loading: true,
        pending: true,
      },
    }),
    [types.DOWNLOAD_RESULT_SUCCESS]: (state: { downloadResult: any; }, { payload }: any) => {
      return {
        ...state,
        downloadResult: {
          ...state.downloadResult,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },

    [types.DOWNLOAD_RESULT_FAILED]: (state: { downloadResult: any; }, { payload }: any) => ({
      ...state,
      downloadResult: {
        ...state.downloadResult,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    [types.GET_PREVIEW_RESULT]: (state: any) => {
      return {
        ...state,
        previewResult: {
          loading: true,
          pending: true,
        },
      };
    },

    [types.GET_PREVIEW_RESULT_SUCCESS]: (state: any, { payload }: any) => {
      return {
        ...state,
        previewResult: {
          loading: false,
          pending: false,
          data: payload.document_data,
          order: payload.order,
        },
      };
    },

    [types.GET_PREVIEW_RESULT_FAILED]: (state: any, { payload }: any) => {
      return {
        ...state,
        previewResult: {
          loading: false,
          pending: false,
          hasError: true,
          error: { payload },
        },
      };
    },

    [types.CLEAR_PREVIEW_RESULT]: (state: any) => {
      return {
        ...state,
        previewResult: {
          data: {},
          loading: true,
          pending: false,
        },
      };
    },

    [types.DOWNLOAD_PREVIEW_RESULT]: (state: any) => {
      return {
        ...state,
        downloadPreviewResult: {
          loading: true,
          pending: true,
        },
      };
    },

    [types.DOWNLOAD_PREVIEW_RESULT_SUCCESS]: (state: any, { payload }: any) => {
      return {
        ...state,
        downloadPreviewResult: {
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },

    [types.DOWNLOAD_PREVIEW_RESULT_FAILED]: (state: any, { payload }: any) => {
      return {
        ...state,
        downloadPreviewResult: {
          loading: false,
          pending: false,
          hasError: true,
          error: { payload },
        },
      };
    },

    // ------------------ START_PREDICT -------------------
    [types.START_PREDICT]: (state: { startPredict: any; }) => ({
      ...state,
      startPredict: {
        ...state.startPredict,
        loading: true,
        pending: true,
      },
    }),
    [types.START_PREDICT_SUCCESS]: (state: { startPredict: any; }, { payload }: any) => {
      return {
        ...state,
        startPredict: {
          ...state.startPredict,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },

    [types.START_PREDICT_FAILED]: (state: { startPredict: any; }, { payload }: any) => ({
      ...state,
      startPredict: {
        ...state.startPredict,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ GET_PROGRESS -------------------
    [types.GET_PROGRESS]: (state: { getProgress: any; }, { payload }: any) => ({
      ...state,
      getProgress: {
        ...state.getProgress,
        loading: true,
        pending: true,
        time: payload.time,
      },
    }),
    [types.GET_PROGRESS_SUCCESS]: (state: { getProgress: any; }, { payload }: any) => {
      const { data, time } = payload;
      return {
        ...state,
        getProgress: {
          ...state.getProgress,
          loading: false,
          pending: false,
          data: data,
          time: time,
        },
      };
    },

    [types.GET_PROGRESS_FAILED]: (state: { getProgress: any; }, { payload }: any) => ({
      ...state,
      getProgress: {
        ...state.getProgress,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
        time: Math.round(new Date().getTime() / 1000),
      },
    }),
    // ------------------ RESIZE_ELEMENT -------------------
    [types.RESIZE_ELEMENT]: (state: { resizeElement: any; }) => ({
      ...state,
      resizeElement: {
        ...state.resizeElement,
        loading: true,
        pending: true,
      },
    }),
    [types.RESIZE_ELEMENT_SUCCESS]: (state: { resizeElement: any; page: { data: { detections: { keyValuePair: any[]; }; }; }; }, { payload }: any) => {
      return {
        ...state,
        resizeElement: {
          ...state.resizeElement,
          loading: false,
          pending: false,
          data: payload,
        },
        page: {
          ...state.page,
          data: {
            ...state.page.data,
            detections: {
              ...state.page.data.detections,
              keyValuePair: [
                ...state.page?.data?.detections?.keyValuePair?.map((u: { id: any; }) =>
                  u.id !== payload.element.id ? u : payload.element
                ),
              ],
            },
          },
        },
      };
    },
    [types.RESIZE_ELEMENT_FAILED]: (state: { resizeElement: any; }, { payload }: any) => ({
      ...state,
      resizeElement: {
        ...state.resizeElement,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ DELETE_ELEMENT -------------------
    [types.DELETE_ELEMENT]: (state: { deleteElement: any; }) => ({
      ...state,
      deleteElement: {
        ...state.deleteElement,
        loading: true,
        pending: true,
      },
    }),
    [types.DELETE_ELEMENT_SUCCESS]: (state: { page: { data: { detections: { keyValuePair: any; table: any; }; }; }; deleteElement: any; }, { payload }: any) => {
      let keyValuePair = state.page?.data?.detections?.keyValuePair;
      let table = state.page?.data?.detections?.table;
      // create new object for updateDetections
      let updatedKeyValuePair = Object.create(keyValuePair);
      let updateTable = Object.create(table);

      let index = updatedKeyValuePair.findIndex(
        (obj: { id: any; }) => obj.id === payload.payload.id
      );
      if (index > -1) {
        updatedKeyValuePair.splice(index, 1); // 2nd parameter means remove one item only
      }

      let tableIndex = updateTable.findIndex(
        (obj: { id: any; }) => obj.id === payload.payload.id
      );
      if (tableIndex > -1) {
        updateTable.splice(tableIndex, 1); // 2nd parameter means remove one item only
      }
      return {
        ...state,
        deleteElement: {
          ...state.deleteElement,
          loading: false,
          pending: false,
          data: payload,
        },
        page: {
          ...state.page,
          data: {
            ...state.page.data,
            detections: {
              keyValuePair: updatedKeyValuePair,
              table: updateTable,
            },
          },
        },
      };
    },
    [types.DELETE_ELEMENT_FAILED]: (state: { deleteElement: any; }, { payload }: any) => ({
      ...state,
      deleteElement: {
        ...state.deleteElement,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ DELETE_TABLE -------------------
    [types.DELETE_TABLE]: (state: { deleteTable: any; }) => ({
      ...state,
      deleteTable: {
        ...state.deleteTable,
        loading: true,
        pending: true,
      },
    }),
    [types.DELETE_TABLE_SUCCESS]: (state: { page: { data: { detections: { table: any; }; }; }; deleteTable: any; }, { payload }: any) => {
      let table = [...state.page?.data?.detections?.table];
      // create new object for updateDetections
      let tableIndex = table.findIndex((obj) => obj.id === payload.id);
      if (tableIndex > -1) {
        table.splice(tableIndex, 1); // 2nd parameter means remove one item only
      }

      return {
        ...state,
        deleteTable: {
          ...state.deleteTable,
          loading: false,
          pending: false,
          data: payload,
        },
        page: {
          ...state.page,
          data: {
            ...state.page.data,
            detections: {
              ...state.page.data.detections,
              table: [...table],
            },
          },
        },
      };
    },
    [types.DELETE_TABLE_FAILED]: (state: { deleteTable: any; }, { payload }: any) => ({
      ...state,
      deleteTable: {
        ...state.deleteTable,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ TRANSFORM_TABLE -------------------
    [types.TRANSFORM_TABLE]: (state: { transformTable: any; }) => ({
      ...state,
      transformTable: {
        ...state.transformTable,
        loading: true,
        pending: true,
      },
    }),
    [types.TRANSFORM_TABLE_SUCCESS]: (state: { transformTable: any; page: { data: { detections: any; }; }; }, { payload }: any) => {
      return {
        ...state,
        transformTable: {
          ...state.transformTable,
          loading: false,
          pending: false,
          data: payload,
        },
        page: {
          ...state.page,
          data: {
            ...state.page.data,
            detections: {
              ...state.page.data.detections,
              table: [
                ...payload
              ],
            },
          },
        },
      };
    },
    [types.TRANSFORM_TABLE_FAILED]: (state: { transformTable: any; }, { payload }: any) => ({
      ...state,
      transformTable: {
        ...state.transformTable,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ GET_PAGE_IMAGE -------------------
    [types.GET_PAGE_IMAGE]: (state: { pageImage: any; }) => ({
      ...state,
      pageImage: {
        ...state.pageImage,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_PAGE_IMAGE_SUCCESS]: (state: { pageImage: any; }, { payload }: any) => {
      return {
        ...state,
        pageImage: {
          ...state.pageImage,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },
    [types.GET_PAGE_IMAGE_FAILED]: (state: { pageImage: any; }, { payload }: any) => ({
      ...state,
      pageImage: {
        ...state.pageImage,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ GET_PAGE -------------------
    [types.GET_PAGE]: (state: { page: any; }) => {
      // sessionStorage.removeItem("numOfPages");
      return {
        ...state,
        page: {
          ...state.page,
          loading: true,
          pending: true,
          data: {
            ...state.page.data,
            detections: {}
          }
        },
      };
    },
    [types.GET_PAGE_SUCCESS]: (state: { page: any; metadata: any; }, { payload }: any) => {
      return {
        ...state,
        page: {
          ...state.page,
          loading: false,
          pending: false,
          data: payload,
        },
        numOfPages: payload?.numberOfPages || 1,
        metadata: {
          ...state.metadata,
          overlappingPages: payload.overlappingPages,
        },
      };
    },
    [types.GET_PAGE_FAILED]: (state: { page: any; }, { payload }: any) => ({
      ...state,
      page: {
        ...state.page,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ UPDATE_TABLE -------------------
    [types.UPDATE_TABLE]: (state: { updateTable: any; }) => ({
      ...state,
      updateTable: {
        ...state.updateTable,
        loading: true,
        pending: true,
      },
    }),
    [types.UPDATE_TABLE_SUCCESS]: (state: { updateTable: any; }, { payload }: any) => {
      return {
        ...state,
        updateTable: {
          ...state.updateTable,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },
    [types.UPDATE_TABLE_FAILED]: (state: { updateTable: any; }, { payload }: any) => ({
      ...state,
      updateTable: {
        ...state.updateTable,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ SKIP -------------------
    [types.SKIP]: (state: { skip: any; }) => ({
      ...state,
      skip: {
        ...state.skip,
        loading: true,
        pending: true,
      },
    }),
    [types.SKIP_SUCCESS]: (state: { skip: any; }, { payload }: any) => {
      let tables = [];
      return {
        ...state,
        skip: {
          ...state.skip,
          loading: false,
          pending: false,
          data: { ...payload, tables },
        },
      };
    },
    [types.SKIP_FAILED]: (state: { skip: any; }, { payload }: any) => ({
      ...state,
      skip: {
        ...state.skip,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ GET_ALL_DOCUMENT_COUNT -------------------
    [types.GET_ALL_DOCUMENT_COUNT]: (state: { getAllDocumentCount: any; }) => ({
      ...state,
      getAllDocumentCount: {
        ...state.getAllDocumentCount,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_ALL_DOCUMENT_COUNT_SUCCESS]: (state: { getAllDocumentCount: any; }, { payload }: any) => {
      return {
        ...state,
        getAllDocumentCount: {
          ...state.getAllDocumentCount,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },
    [types.GET_ALL_DOCUMENT_COUNT_FAILED]: (state: { getAllDocumentCount: any; }, { payload }: any) => ({
      ...state,
      getAllDocumentCount: {
        ...state.getAllDocumentCount,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ ADD_FEEDBACK -------------------
    [types.ADD_FEEDBACK]: (state: { addFeedback: any; }) => ({
      ...state,
      addFeedback: {
        ...state.addFeedback,
        loading: true,
        pending: true,
      },
    }),
    [types.ADD_FEEDBACK_SUCCESS]: (state: { addFeedback: any; }, { payload }: any) => {
      return {
        ...state,
        addFeedback: {
          ...state.addFeedback,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },

    [types.ADD_FEEDBACK_FAILED]: (state: { addFeedback: any; }, { payload }: any) => ({
      ...state,
      addFeedback: {
        ...state.addFeedback,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ DELETE_DOCUMENT -------------------
    [types.DELETE_DOCUMENT]: (state: { deleteDocument: any; }) => ({
      ...state,
      deleteDocument: {
        ...state.deleteDocument,
        loading: true,
        pending: true,
      },
    }),
    [types.DELETE_DOCUMENT_SUCCESS]: (state: { deleteDocument: any; }, { payload }: any) => {
      return {
        ...state,
        deleteDocument: {
          ...state.deleteDocument,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },
    [types.DELETE_DOCUMENT_FAILED]: (state: { deleteDocument: any; }, { payload }: any) => ({
      ...state,
      deleteDocument: {
        ...state.deleteDocument,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ UPLOAD_PDF -------------------
    [types.UPLOAD_CORRECTIONS]: (state: { uploadCorrections: any; }) => ({
      ...state,
      uploadCorrections: {
        ...state.uploadCorrections,
        loading: true,
        pending: true,
      },
    }),
    [types.UPLOAD_CORRECTIONS_SUCCESS]: (state: { uploadCorrections: any; }, { payload }: any) => {
      return {
        ...state,
        uploadCorrections: {
          ...state.uploadCorrections,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },

    [types.UPLOAD_CORRECTIONS_FAILED]: (state: { uploadCorrections: any; }, { payload }: any) => ({
      ...state,
      uploadCorrections: {
        ...state.uploadCorrections,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ ADD_TABLE_HEADING -------------------
    [types.ADD_TABLE_HEADING]: (state: { addTableHeading: any; page: any; }) => ({
      ...state,
      addTableHeading: {
        ...state.addTableHeading,
        loading: true,
        pending: true,
        uploadStarted: true,
      },
      page: {
        ...state.page,
        data: {
          ...state.page.data,
          detections: {
            ...state.page.data.detections,
            table: [],
          },
        },
      },
    }),
    [types.ADD_TABLE_HEADING_SUCCESS]: (state: { addTableHeading: any; page: { data: { detections: any; }; }; }, { payload }: any) => {
      return {
        ...state,
        addTableHeading: {
          ...state.addTableHeading,
          loading: false,
          pending: false,
          data: payload,
        },
        page: {
          ...state.page,
          data: {
            ...state.page.data,
            detections: {
              ...state.page.data.detections,
              table: [...payload],
            },
          },
        },
      };
    },

    [types.ADD_TABLE_HEADING_FAILED]: (state: { addTableHeading: any; }, { payload }: any) => ({
      ...state,
      addTableHeading: {
        ...state.addTableHeading,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ TRANSPOSE_TABLE -------------------
    [types.TRANSPOSE_TABLE]: (state: { transposeTable: any; }) => ({
      ...state,
      transposeTable: {
        ...state.transposeTable,
        loading: true,
        pending: true,
      },
    }),
    [types.TRANSPOSE_TABLE_SUCCESS]: (state: { transposeTable: any; page: { data: any; }; }, { payload }: any) => {
      return {
        ...state,
        transposeTable: {
          ...state.transposeTable,
          loading: false,
          pending: false,
          data: payload,
        },
        page: {
          ...state.page,
          loading: false,
          pending: false,
          data: {
            ...state.page.data,
            detections: payload.detections,
          },
        },
        // numOfPages: payload?.numberOfPages || 1,
      };
    },
    [types.TRANSPOSE_TABLE_FAILED]: (state: { transposeTable: any; }, { payload }: any) => ({
      ...state,
      transposeTable: {
        ...state.transposeTable,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ GET_ALL_CONFIGS -------------------
    [types.GET_ALL_CONFIGS]: (state: { getAllConfigs: any; }) => ({
      ...state,
      getAllConfigs: {
        ...state.getAllConfigs,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_ALL_CONFIGS_SUCCESS]: (state: { getAllConfigs: any; }, { payload }: any) => {
      return {
        ...state,
        getAllConfigs: {
          ...state.getAllConfigs,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },
    [types.GET_ALL_CONFIGS_FAILED]: (state: { getAllConfigs: any; }, { payload }: any) => ({
      ...state,
      getAllConfigs: {
        ...state.getAllConfigs,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ ADD_CONFIG -------------------
    [types.ADD_CONFIG]: (state: { addConfig: any; }) => ({
      ...state,
      addConfig: {
        ...state.addConfig,
        loading: true,
        pending: true,
      },
    }),
    [types.ADD_CONFIG_SUCCESS]: (state: { addConfig: any; }, { payload }: any) => {
      return {
        ...state,
        addConfig: {
          ...state.addConfig,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },

    [types.ADD_CONFIG_FAILED]: (state: { addConfig: any; }, { payload }: any) => ({
      ...state,
      addConfig: {
        ...state.addConfig,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ UPDATE_CONFIG -------------------
    [types.UPDATE_CONFIG]: (state: { updateConfig: any; }) => ({
      ...state,
      updateConfig: {
        ...state.updateConfig,
        loading: true,
        pending: true,
      },
    }),
    [types.UPDATE_CONFIG_SUCCESS]: (state: { updateConfig: any; metadata: { data: any; }; }, { payload }: any) => {
      return {
        ...state,
        updateConfig: {
          ...state.updateConfig,
          loading: false,
          pending: false,
          data: payload,
        },
        metadata: {
          ...state.metadata,
          data: {
            ...state.metadata.data,
            templateChanged: false
          }
        }
      };
    },

    [types.UPDATE_CONFIG_FAILED]: (state: { updateConfig: any; }, { payload }: any) => ({
      ...state,
      updateConfig: {
        ...state.updateConfig,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ DELETE_CONFIG -------------------
    [types.DELETE_CONFIG]: (state: { deleteConfig: any; }) => ({
      ...state,
      deleteConfig: {
        ...state.deleteConfig,
        loading: true,
        pending: true,
      },
    }),
    [types.DELETE_CONFIG_SUCCESS]: (state: { getAllConfigs: { data: any; }; deleteConfig: any; }, { payload }: any) => {
      const table = [...state.getAllConfigs.data];
      const newTableData = table.filter(item => item.orderTemplateId !== payload.orderTemplateId)
      return {
        ...state,
        deleteConfig: {
          ...state.deleteConfig,
          loading: false,
          pending: false,
          data: payload.data,
        },
        getAllConfigs: {
          ...state.getAllConfigs,
          data: newTableData
        }
      };
    },
    [types.DELETE_CONFIG_FAILED]: (state: { deleteConfig: any; }, { payload }: any) => ({
      ...state,
      deleteConfig: {
        ...state.deleteConfig,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ GET_METADATA -------------------
    [types.GET_METADATA]: (state: { metadata: any; }) => ({
      ...state,
      metadata: {
        ...state.metadata,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_METADATA_SUCCESS]: (state: { metadata: { data: any; }; }, { payload }: any) => {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          loading: false,
          pending: false,
          data: { ...state.metadata.data, ...payload },
        },
      };
    },
    [types.GET_METADATA_FAILED]: (state: { metadata: any; }, { payload }: any) => ({
      ...state,
      metadata: {
        ...state.metadata,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    [types.SET_METADATA]: (state: { metadata: { data: any; }; }, { payload }: any) => ({
      ...state,
      metadata: {
        ...state.metadata,
        data: {
          ...state.metadata.data,
          ...payload
        }
      },
    }),

    // ------------------ GET_METADATA_SELECTION -------------------
    [types.GET_METADATA_SELECTION]: (state: { metadata: any; }) => ({
      ...state,
      metadata: {
        ...state.metadata,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_METADATA_SELECTION_SUCCESS]: (state: { metadata: { data: any; }; }, { payload }: any) => {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          loading: false,
          pending: false,
          data: {
            ...state.metadata.data,
            ...payload,
          },
        },
      };
    },
    [types.GET_METADATA_SELECTION_FAILED]: (state: { metadata: any; }, { payload }: any) => ({
      ...state,
      metadata: {
        ...state.metadata,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ GET_METADATA_VALIDATE_PAGE -------------------
    [types.GET_METADATA_VALIDATE_PAGE]: (state: { metadata: any; }) => ({
      ...state,
      metadata: {
        ...state.metadata,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_METADATA_VALIDATE_PAGE_SUCCESS]: (state: { metadata: { data: any; }; }, { payload }: any) => {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          loading: false,
          pending: false,
          data: { ...state.metadata.data, ...payload },
        },
      };
    },
    [types.GET_METADATA_VALIDATE_PAGE_FAILED]: (state: { metadata: any; }, { payload }: any) => ({
      ...state,
      metadata: {
        ...state.metadata,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    [types.PRODUCT_TOUR]: (state: { productTour: any; }) => {
      return {
        ...state,
        productTour: !state.productTour,
      };
    },

    // ------------------ CHANGE_VALIDATE_STATUS -------------------
    [types.CHANGE_VALIDATE_STATUS]: (state: { validateStatus: any; }, { payload }: any) => ({
      ...state,
      validateStatus: {
        ...state.validateStatus,
        ...payload,
      },
    }),

    [types.RETRY_GPT_GENERATION]: (state: { gptRetryStatus: any; }, { payload }: any) => ({
      ...state,
      gptRetryStatus: {
        ...state.gptRetryStatus,
        loading: true,
        data: payload
      }
    }),

    [types.RETRY_GPT_GENERATION_SUCCESS]: (state: { gptRetryStatus: any; }, { payload }: any) => ({
      ...state,
      gptRetryStatus: {
        ...state.gptRetryStatus,
        loading: false,
        data: payload
      }
    }),

    [types.RETRY_GPT_GENERATION_FAILED]: (state: { gptRetryStatus: any; }, { payload }: any) => ({
      ...state,
      gptRetryStatus: {
        ...state.gptRetryStatus,
        loading: false,
        data: payload
      }
    }),
  },
  initialState
);
