import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { PredictActions } from "../../../modules/validate/ducks/index";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { BootstrapDialogTitle } from "../../../components/modal/BootstrapDialogTitle";
import TranslateMessage from "../../../components/translateManager/TranslateMessage";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const UpdatePromptDialog = ({admin}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);

  const onClose = () => {
    setOpen(false);
  };

  const onUpdate = () => {
    dispatch(PredictActions.updateConfig());
    onClose();
  };

  const onSave = () => {
    dispatch(PredictActions.setMetadata({ newTemplate: true }));
    onClose();
  };

  return (
    <StyledDialog
      onClose={onClose}
      fullWidth
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onclose}>
        <TranslateMessage translateKey="download_model_prompt_title" />
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </BootstrapDialogTitle>
      <DialogContent></DialogContent>
      <DialogActions className="justify-content-center pt-0 pb-4">
        <Button
          className="blue-btn-small"
          variant="contained"
          disabled={admin}
          onClick={onUpdate}
          autoFocus
        >
          <TranslateMessage translateKey="download_model_prompt_update_button" />
        </Button>
        <Button
          className="blue-btn-small with-border"
          variant="contained"
          autoFocus
          onClick={onSave}
        >
          <TranslateMessage translateKey="download_model_prompt_save_as_button" />
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default UpdatePromptDialog;
