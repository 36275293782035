import React, { Component } from 'react';
import Paper from '@mui/material/Paper';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import testImage from '../../assets/images/image_pdf-001.jpg';
import { Stage, Layer, Rect } from 'react-konva';
import URLImage from '../../components/canvas/URLImage';
import { PredictActions } from './ducks';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import uuid from 'uuid';
import {
  DrawingMode,
  KONVA_PIXEL_RATIO,
} from '../../util/Constants';
import ImageControlPanel from '../../components/validate/ImageControlPanel';
import PageNumberIndicator from '../../components/validate/PageNumberIndicator';
import {
  ViewMode,
  ElementType,
  AppMode,
  ColorTheme,
  ElmOperation,
  CanvasOrientation,
} from '../../util/Constants';
import Konva from 'konva';
import Loader from '../../components/shared/Loader';
import ElementSelector from '../../components/validate/ElementSelector';
import ColoredRect from '../../components/canvas/ColoredRect';
import '../../components/shared/styles/predict.css';
import { withTranslation } from 'react-i18next';
import { Alert } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Table from './table/Table';
import TableNumber from './table/numbers/TableNumber';
import AddColumnOverlay from './AddColumnOverlay';
import { io } from 'socket.io-client';
import CustomMousePointer from 'components/CustomMousePointer/CustomMousePointer';
import { GPTRetryModalDialog } from "components/validate/GPTRetryModal/GPTRetryModal";

/**
 * Image width and height is defined acording to the window size
 */
const { innerHeight: height } = window;
Konva.pixelRatio = KONVA_PIXEL_RATIO;
// Define socket in validate page
let socketValidate = '';

/**
 * Handles all the drawing related views and logics
 *
 * @component
 * @returns {JSX.Element} The JSX element representing the Validate component.
 */
class Validate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tempDrewColumn: [],
      height: false,
      width: false,
      xvalue: null,
      yvalue: null,
      res: null,
      rowvalues: [],
      updatedTableArr: [],
      dataArray: [],
      tablesArray: [],
      newAnnotation: [],
      annotations: [],
      isShow: false,
      scale: 1.0,
      mode: AppMode.MOVE_ACTION,
      selectedId: null,
      clickedOnEmpty: false,
      startX: 0,
      startY: 0,
      containerHeight: 0,
      containerWidth: 0,
      CANVAS_WIDTH: 0,
      CANVAS_HEIGHT: window.innerHeight - 60,
      option: DrawingMode.KEY_VALE,
      windowHeight: window.innerHeight,
      windoWidth: window.innerWidth,
      IMAGE_HEIGHT: this.getPdfDimentions(CanvasOrientation.VERTICAL).height,
      IMAGE_WIDTH: this.getPdfDimentions(CanvasOrientation.VERTICAL).width,
      tempDrewElement: null,
      openAlert: true,
      anchorEl: null,
      linesArray: [],
      isShowValidateModal: false,
      showEditViewModal: false,
      gptRetryModalOpen: !this.props.gptGenerationStatus,
    };
  }

  /**
   * when the page is loading get page data from backend
   *
   * @function
   */
  componentDidMount() {

    socketValidate = io.connect(process.env.REACT_APP_API_BASE_URL, { withCredentials: true });

    socketValidate.emit('update_page_data', { page: 'validate' });

    this.update_page_data_interval = setInterval(async function () {
      await socketValidate.emit('update_page_data', { page: 'validate' });
    }, 1000);

    setTimeout(() => {
      clearInterval(this.update_page_data_interval);
      this.update_page_data_interval = setInterval(async function () {
        await socketValidate.emit('update_page_data', { page: 'validate' });
      }, 1000 * 60);
    }, 1000 * 6)

    //stop socket after 8mins
    setTimeout(() => {
      clearInterval(this.update_page_data_interval);
    }, 1000 * 60 * 60 * 12);

    const { match } = this.props;
    const containerHeight = this.divElement.clientHeight;
    const containerWidth = this.divElement.clientWidth;

    this.setCanvasSize();
    this.createDataMap();
    this.props.predictActions.getPage({ pageNo: match?.params?.pageNo || 1 });

    this.setState({
      containerHeight,
      containerWidth,
      CANVAS_WIDTH: containerWidth,
      isShowValidateModal: true,
    });
  }

  /**
   * When page data is recieved create data map for drawing on the canvas
   *
   * @function
   * @param {Object} prevProps - The previous props object.
   * @param {Object} prevState - The previous state object.
   *
   * @description
   * The `componentDidUpdate` method is invoked when the component updates and receives new props or state changes.
   * It checks if the `page` prop or the `getTableHeadings` prop has changed, and if so, it updates the component's
   * state by resetting annotations, creating a data map, and setting the canvas size accordingly.
   */
  componentDidUpdate(prevProps, prevState) {
    const { page, getTableHeadings } = this.props;
    if (page && JSON.stringify(page) !== JSON.stringify(prevProps.page)) {
      this.setState({
        annotations: [],
      });
      this.createDataMap();
      this.setCanvasSize();
    }
    if (page && JSON.stringify(getTableHeadings) !== JSON.stringify(prevProps.getTableHeadings)) {
      this.createDataMap();
      this.setCanvasSize();
    }

    if (this.props.page?.data?.detections?.table) {
      const table = this.props.page?.data?.detections?.table;
      for (let i = 0; i < table.length; i++) {
        const columnsList = table[i].columns;
        const rowsList = table[i].rows;
        for (let j = 0; j < columnsList.length; j++) {
          const column = columnsList[j];
          if (column.selected) {
            for (let k = 0; k < rowsList.length; k++) {
              if (this.props.page?.data?.detections?.table[i] && this.props.page.data.detections.table[i].rows[k]) {
                this.props.page.data.detections.table[i].rows[k].cells[j].colSelected = true;
              }
            }
          }
        }
      }
    }

    if (this.props.gptGenerationStatus !== prevProps.gptGenerationStatus) {
      this.setState({
        gptRetryModalOpen: !this.props.gptGenerationStatus,
      });
    }
  }

  /**
   * componentWillUnmount Lifecycle Method.
   *
   * @method
   * @description
   * The `componentWillUnmount` method is invoked when the component is about to be unmounted and removed from the DOM.
   * It emits an update signal to a socket named 'update_page_data', clears an interval timer,
   * and disconnects the socket connection.
   */
  componentWillUnmount() {
    socketValidate.emit('update_page_data', { page: 'validate' });
    clearInterval(this.update_page_data_interval);
    socketValidate.disconnect();
  }

  /**
   * When click on the canvas this will get called
   * prepare the states to draw new polygon on the canvas
   *
   * @function
   * @param {Event} event
   */
  handleMouseDown = event => {
    const { appMode } = this.props;
    const { newAnnotation } = this.state;

    if (appMode.mode === AppMode.DRAWING_ACTION) {
      if (newAnnotation.length === 0) {
        const { x, y } = event.target.getStage().getPointerPosition();
        let annotationsObj = { x, y, width: 0, height: 0, key: '0' };
        this.setState({
          newAnnotation: [annotationsObj],
        });
      }
    }

    this.setState({
      clickedOnEmpty: event.target.parent?.parent === event.target.getStage(),
    });

    if (this.state.clickedOnEmpty) {
      if (appMode.mode === AppMode.DRAG_ACTION) {
        this.props.predictActions.handleAppMode({ mode: AppMode.MOVE_ACTION });
      }
      this.setState({
        selectedId: null,
        mode: AppMode.MOVE_ACTION,
      });
    }
  };

  /**
   * This function is working for drawing on the canvas
   * When mouse click is getting released creates new bounding polygon and adds it to the canvas
   *
   * @function
   * @param {Event} event
   */
  handleMouseUp = event => {
    const { appMode, selectedDropdownElement } = this.props;
    const { newAnnotation, annotations } = this.state;
    let annotationsobj = annotations;

    if (appMode.mode === AppMode.DRAWING_ACTION && newAnnotation.length === 1) {
      const sx = newAnnotation[0].x;
      const sy = newAnnotation[0].y;
      const { x, y } = event.target.getStage().getPointerPosition();

      let elementId = uuid.v4();
      const annotationToAdd = {
        id: elementId,
        table_id: selectedDropdownElement.data === DrawingMode.TABLE ? elementId : undefined,
        x: sx,
        y: sy,
        width: x - sx,
        height: y - sy,
        key: annotationsobj.length + 1,
        type: selectedDropdownElement.data,
      };
      annotationsobj.push(annotationToAdd);
      this.setState({
        newAnnotation: [],
        annotations: annotationsobj,
      });
      if (annotationToAdd.width !== 0 || annotationToAdd.height !== 0) {
        let obj = {
          ...annotationToAdd,
        };
        let normalized = this.calculateNormalizedVertices(
          obj,
          this.state.IMAGE_WIDTH,
          this.state.IMAGE_HEIGHT,
        );

        const tempDrewElement = {
          ...obj,
          ...normalized,
          width: normalized.w,
          height: normalized.h,
        };
        this.setState({
          tempDrewElement,
        });

        this.addNewElement(tempDrewElement);
      }
    }
  };

  /**
   * submitHandler Function.
   *
   * @function
   * @param {Event} event - The event object.
   * @description
   * The `submitHandler` function is responsible for handling the submission of an annotation.
   */
  submitHandler = event => {
    const { appMode, selectedDropdownElement } = this.props;

    const { newAnnotation, annotations } = this.state;
    let annotationsobj = annotations;

    if (appMode.mode === AppMode.DRAWING_ACTION && newAnnotation.length === 1) {
      const sx = newAnnotation[0].x;
      const sy = newAnnotation[0].y;
      const { x, y } = event.target.getStage().getPointerPosition();

      let elementId = uuid.v4();
      const annotationToAdd = {
        id: elementId,
        table_id: selectedDropdownElement.data === DrawingMode.TABLE ? elementId : undefined,
        x: sx,
        y: sy,
        width: x - sx,
        height: y - sy,
        key: annotationsobj.length + 1,
        type: selectedDropdownElement.data,
      };
      annotationsobj.push(annotationToAdd);
      this.setState({
        newAnnotation: [],
        annotations: annotationsobj,
      });
      if (annotationToAdd.width !== 0 || annotationToAdd.height !== 0) {
        let obj = {
          ...annotationToAdd,
        };
        let normalized = this.calculateNormalizedVertices(
          obj,
          this.state.IMAGE_WIDTH,
          this.state.IMAGE_HEIGHT,
        );
        this.sendDrewElement({
          ...obj,
          ...normalized,
          width: normalized.w,
          height: normalized.h,
        });
      }
    }
  };

  /**
   * Send drawn elements to the backend
   *
   * @function
   * @param {Object} data - the data object
   */
  sendDrewElement = data => {
    if (data.type === DrawingMode.COLUMN) {
      console.log(data);
      let dataDto = {
        id: data.id,
        page_id: this.props.page?.data?.id,
        type: 'column',
        vertices: {
          x: data.x,
          y: data.y,
          w: data.width,
          h: data.height,
        },
      };

      this.setState({
        tempDrewColumn: dataDto,
      });
    } else if (data.type === DrawingMode.TABLE) {
      console.log('Drawing table:', data);
      let dataDto = {
        pageId: this.props.page?.data?.id,
        table: {
          id: data.id,
          normalizedVertices: {
            x: data.x,
            y: data.y,
            w: data.width,
            h: data.height,
          },
        },
      };
      this.props.predictActions.addTable({ dataDto });
    } else {
      let dataDto = {
        pageId: this.props.page?.data?.id,
        keyValuePair: {
          id: data.id,
          normalizedVertices: {
            x: data.x,
            y: data.y,
            w: data.width,
            h: data.height,
          },
        },
      };
      this.props.predictActions.addKeyValue({ dataDto });
    }
  };

  /**
   * handleMouseMove Function.
   * Handle the drawing logic on the canvas
   *
   * @function
   * @param {Event} event - The mouse move event object.
   * @description
   * The `handleMouseMove` function is responsible for handling the mouse move event in the app. It checks if the app is in drawing mode
   * and there is a new annotation available. If these conditions are met, it logs the coordinates of the new annotation, calculates its
   * position, size, and key based on pointer positions. The calculated annotation details are then stored in the component's state.
   */
  handleMouseMove = event => {
    const { appMode } = this.props;
    const { newAnnotation } = this.state;
    if (appMode.mode === AppMode.DRAWING_ACTION && newAnnotation.length === 1) {
      console.log(newAnnotation[0]);
      const sx = newAnnotation[0].x;
      const sy = newAnnotation[0].y;
      const { x, y } = event.target.getStage().getPointerPosition();

      let newAnnotationObj = {
        x: sx,
        y: sy,
        width: x - sx,
        height: y - sy,
        key: '0',
      };

      this.setState({
        newAnnotation: [newAnnotationObj],
      });
    }
  };

  /**
   * createDataMap function.
   *
   * @function
   * @description - Creates data map for drawing elements on the canvas
   */
  createDataMap = () => {
    const { page } = this.props;
    let rectElements = page?.data?.detections?.keyValuePair
      ? [...page?.data?.detections?.keyValuePair]
      : [];
    let table = page?.data?.detections?.table ? [...page?.data?.detections?.table] : [];

    let keyValuePairArr = [];

    table?.forEach(tableData => {
      let tableElement = {
        type: ElementType.ELM_TABLE,
        id: tableData.id,
        boundingPoly: tableData.boundingPoly,
      };
      rectElements.push(tableElement);
      tableData.rows?.forEach(row => {
        row.cells.forEach(cell => {
          rectElements.push(cell);
        });
      });

      tableData.columns.forEach(column => rectElements.push(column));
    });

    let temp = [];

    rectElements.forEach((element, i) => {
      if (element.type === 'keyValuePair') {
        keyValuePairArr.push(element);
      } else {
        temp.push(element);
      }
    });
    const key = 'group_index';
    const uniqueKeyValuePair = [
      ...new Map(keyValuePairArr.map(item => [item[key], item])).values(),
    ];

    this.setState({
      dataArray: [...uniqueKeyValuePair, ...temp],
      tablesArray: table,
    });
  };

  Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  /**
   * This will execute when clicking on a element and set the element in reducer to access it from ValidateSideBar component
   *
   * @function
   * @param {Object} data - Data object
   * @param {string} id - Id of the selected element
   */
  selectElementHandler = (data, id) => {
    const { tablesArray } = this.state;
    const { viewMode } = this.props;
    this.props.predictActions.handleElementSelect(data);
    if (data.type === ElementType.ELM_CELL) {
      this.props.predictActions.handleTableSelect(tablesArray[0]);
    }

    if (viewMode.mode === ViewMode.EDIT_VIEW) {
      if (this.state.mode === AppMode.DRAG_ACTION) {
        this.setState({
          selectedId: id,
        });
      }

      this.props.predictActions.handleAppMode({ mode: AppMode.DRAG_ACTION });
      this.setState({
        mode: AppMode.DRAG_ACTION,
      });
    }
  };

  /**
   * This will executes when clicking on a drawn element
   *
   * @function
   * @param {Object} data - Data object
   */
  selectDrawingHandler = data => {
    this.props.predictActions.handleNewDetectionSelect(data);
    this.props.predictActions.handleElementSelect(data);
  };

  /**
   * ElementSelector
   * Set the edior mode accoring to the image control panel actions
   * @param {*} param0
   */

  /**
   * modeHandler Function.
   *
   * @function
   * @param {Object} mode - The mode object containing the mode value.
   * @description
   * The `modeHandler` function is responsible for handling mode changes in the app. It takes a `mode` object as a parameter, which should
   * contain the `mode` value. The function updates the component's state with the new mode value and also invokes the `handleAppMode` function
   * from `predictActions` prop, passing the new mode value to it.
   */
  modeHandler = ({ mode }) => {
    this.setState({
      mode: mode,
    });

    this.props.predictActions.handleAppMode({ mode: mode });
  };

  /**
   * DeleteHandler
   * deletes the selected element on the canvas
   *
   * @function
   */
  deleteHandler = () => {
    var body = {
      id: this.state.selectedId,
      pageId: this.props.page?.data?.id,
    };

    let index = this.state.dataArray.findIndex(obj => obj.id === this.state.selectedId);

    if (index > -1) {
      let dltElm = this.state.dataArray[index];

      if (dltElm.type === ElementType.ELM_COLUMN) {
        this.updateColumns(
          dltElm.boundingPoly.normalizedVertices,
          this.props.page?.data?.id,
          dltElm.id,
          dltElm.table_id,
          dltElm.title,
          ElmOperation.DELETED,
        );

        this.state.dataArray.splice(index, 1); // 2nd parameter means remove one item only
      } else if (dltElm.type === ElementType.ELM_TABLE) {
        this.props.predictActions.deleteTable({
          tableId: body.id,
          pageId: body.pageId,
        });
      } else {
        this.props.predictActions.deleteElement(body);
      }
    }

    this.setState({
      selectedId: null,
    });
  };

  /**
   * Calculates normalized vertices by absolute coordinates
   *
   * @function
   * @param {Object} value - The object containing the x, y, width, and height values.
   * @param {number} imgWidth - The width of the image.
   * @param {number} imgHeight - The height of the image.
   * @returns an object containing x coordinate, y coordinate, width and height of normalized vertices
   */
  calculateNormalizedVertices(value, imgWidth, imgHeight) {
    console.log(value);
    return {
      x: (value.x - this.state.startX) / imgWidth,
      y: (value.y - this.state.startY) / imgHeight,
      w: value.width / imgWidth,
      h: value.height / imgHeight,
    };
  }

  /**
   * Executes when changing the element size
   *
   * @function
   * @param {Object} normalizedVertices - The normalized vertices for the bounding polygon.
   * @param {string} pageId - The ID of the page containing the element.
   * @param {Object} cell - The cell or element being updated.
   * @param {string} type - The type of the element (ElementType.ELM_TABLE, ElementType.ELM_COLUMN, etc.).
   *
   * @description
   * The `boundingPolyChanged` function handles the update of an element's bounding polygon. Depending on the element type,
   * it performs specific update operations. If the element type is ElementType.ELM_TABLE, it updates the table with the new
   * normalized vertices.
   */
  boundingPolyChanged(normalizedVertices, pageId, cell, type) {
    if (type === ElementType.ELM_TABLE) {
      this.updateTable(normalizedVertices, cell, pageId);
      return;
    }
    if (type === ElementType.ELM_COLUMN) {
      this.updateColumns(
        normalizedVertices,
        pageId,
        cell.id,
        cell.table_id,
        cell.title,
        ElmOperation.UPDATED,
      );
      return;
    }

    let updated = {
      pageId: pageId,
      keyValuePair: {
        id: cell.id,
        normalizedVertices: normalizedVertices,
        type: cell.type,
      },
    };
    this.props.predictActions.resizeElement(updated);
  }

  /**
   *
   * @function
   * @param {Event} e - The event object.
   * @param {Object} data - The data object.
   * @param {string} pageId - Id of the page.
   *
   * @description
   * Executes when draging element is getting finished.
   * The `onDragEndHandler` function handles the update of an element's position. Depending on the element type,
   * it performs specific update operations.
   */
  onDragEndHandler = (e, data, pageId) => {
    console.log(data);
    const normalizedVertices = {
      // x: (e.target.x() - this.state.startX) / IMG_WIDTH,
      x: (e.target.x() - this.state.startX) / this.state.IMAGE_WIDTH,
      y: (e.target.y() - this.state.startY) / this.state.IMAGE_HEIGHT,
      w: data.boundingPoly.normalizedVertices.w,
      h: data.boundingPoly.normalizedVertices.h,
    };

    if (data.type === ElementType.ELM_COLUMN) {
      this.updateColumns(
        normalizedVertices,
        pageId,
        data.id,
        data.table_id,
        data.title,
        ElmOperation.UPDATED,
      );
      return;
    }

    if (data.type === ElementType.ELM_TABLE) {
      this.updateTable(normalizedVertices, data, pageId);
      return;
    }

    let dragedObj = {
      pageId: pageId,
      keyValuePair: {
        id: data.id,
        normalizedVertices: normalizedVertices,
        type: data.type,
      },
    };
    this.props.predictActions.resizeElement(dragedObj);
  };

  /**
   * addNewColumn Function.
   *
   * @function
   * @param {string} selectedTable - The ID of the selected table.
   * @param {string} heading - The heading of the new column.
   *
   * @description
   * The `addNewColumn` function is responsible for adding a new column to a selected table. It prepares the necessary
   * column data by setting the table ID, page ID, and normalized vertices based on the temporary drawn element. The
   * function then calls the `updateColumns` method with the provided data, indicating that a new column is being created.
   *
   * @returns {void}
   */
  addNewColumn = (selectedTable, heading) => {
    let tempColumn = this.state.tempDrewElement;
    tempColumn['table_Id'] = selectedTable;
    tempColumn['page_id'] = this.props.page?.data?.id;
    tempColumn['normalizedVertices'] = {
      x: tempColumn.x,
      y: tempColumn.y,
      w: tempColumn.width,
      h: tempColumn.height,
    };

    console.log(tempColumn);

    this.updateColumns(
      tempColumn.normalizedVertices,
      tempColumn.page_id,
      tempColumn.id,
      tempColumn.table_Id,
      tempColumn.heading,
      ElmOperation.CREATED,
    );
  };

  /**
   * addNewElement Function.
   *
   * @function
   *
   * @description
   * The `addNewElement` function is responsible for finalizing the creation of a new element that has been drawn or added
   * to the canvas.
   *
   * @returns {void}
   */
  addNewElement = () => {
    const { tempDrewElement } = this.state;
    const { selectedDropdownElement } = this.props;
    tempDrewElement['type'] = selectedDropdownElement?.data;

    this.props.predictActions.handleAppMode({ mode: AppMode.MOVE_ACTION });
    this.props.predictActions.handleSelectDropdownElement([]);

    this.sendDrewElement(tempDrewElement);
  };

  /**
   * updateTable Function.
   *
   * @function
   * @param {Object} normalizedVertices - The normalized vertices of the updated table.
   * @param {Object} table - The table object being updated.
   *
   * @description
   * The `updateTable` function is responsible for updating the information of a table, including its normalized vertices,
   * within the component's state. It first checks if the table already exists in the `updatedTableArr` state array. If it
   * does, it updates the normalized vertices of the existing table entry. If not, it creates a new table object with the
   * provided `table` details and normalized vertices, and adds it to the `updatedTableArr`. This function is typically used
   * when a table's position or size is modified on the canvas.
   *
   * @returns {void}
   */
  updateTable(normalizedVertices, table) {
    let index = this.state.updatedTableArr.findIndex(obj => obj.tableId === table.id);
    if (index !== -1) {
      let tableOb = this.state.updatedTableArr.find(obj => obj.tableId === table.id);
      tableOb['normalizedVertices'] = normalizedVertices;
      let updatedColArr = this.state.updatedTableArr;
      updatedColArr[index] = tableOb;
      this.setState({
        updatedTableArr: updatedColArr,
      });
    } else {
      let tableObj = {
        tableId: table.id,
        columns: [],
        normalizedVertices: normalizedVertices,
        // pageId: pageId,
      };

      let updatedColArr = [...this.state.updatedTableArr, tableObj];
      this.setState({
        updatedTableArr: updatedColArr,
      });
    }
  }

  /**
   * setUpdatedTableArray Function.
   *
   * @function
   * @param {Object} normalizedVertices - The normalized vertices of the updated column.
   * @param {string} columnId - The ID of the column being updated.
   * @param {string} table_id - The ID of the table to which the column belongs.
   * @param {string} title - The title or heading of the column.
   * @param {string} operation - The operation performed on the column.
   *
   * @description
   * The `setUpdatedTableArray` function is a utility function that serves as an intermediary to update column information
   * within the component's state.
   *
   * @returns {void}
   */
  setUpdatedTableArray = (normalizedVertices, columnId, table_id, title, operation) => {
    this.updateColumns(
      normalizedVertices,
      this.props.page?.data?.id,
      columnId,
      table_id,
      title,
      operation,
    );
  };

  /**
   * updateColumns Function.
   *
   * @function
   * @param {Object} normalizedVertices - The normalized vertices of the updated column.
   * @param {string} pageId - The ID of the page to which the column belongs.
   * @param {string} columnId - The ID of the column being updated.
   * @param {string} table_id - The ID of the table to which the column belongs.
   * @param {string} title - The title or heading of the column.
   * @param {string} operation - The operation performed on the column (created, updated, deleted, etc.).
   *
   * @description
   * The `updateColumns` function is responsible for updating column information within the component's state.
   *
   * @returns {void}
   */
  updateColumns(normalizedVertices, pageId, columnId, table_id, title, operation) {
    let columnObj = {
      id: columnId,
      pageId: pageId,
      normalizedVertices: normalizedVertices,
      type: ElementType.ELM_COLUMN,
      title: title,
      operation: operation,
      tableId: table_id,
    };

    let index = this.state.updatedTableArr.findIndex(obj => obj.tableId === table_id);
    const tableObj = this.state.updatedTableArr.find(table => table.tableId === table_id);

    if (index !== -1) {
      let colIndex = tableObj.columns.findIndex(col => col.id === columnId);

      if (colIndex !== -1) {
        let updatedColArr = this.state.updatedTableArr;

        if (operation === ElmOperation.UPDATED) {
          if (
            updatedColArr.find(table => table.tableId === table_id).columns[colIndex].operation ===
            ElmOperation.CREATED
          ) {
            updatedColArr.find(obj => obj.tableId === table_id).columns[colIndex] = {
              ...columnObj,
              operation: ElmOperation.CREATED,
            };
          } else {
            updatedColArr.find(obj => obj.tableId === table_id).columns[colIndex] = columnObj;
          }
        } else {
          updatedColArr.find(obj => obj.tableId === table_id).columns[colIndex] = columnObj;
        }

        this.setState({
          updatedTableArr: updatedColArr,
        });
      } else {
        let updatedColArr = this.state.updatedTableArr;
        updatedColArr.find(obj => obj.tableId === table_id).columns.push(columnObj);
        this.setState({
          updatedTableArr: updatedColArr,
        });
      }
    } else {
      let columnArr = [];
      columnArr.push(columnObj);
      let table = this.state.tablesArray.find(table => table.id === table_id);

      let tableObj = {
        // pageId: pageId,
        tableId: table_id,
        columns: columnArr,
        normalizedVertices: table.boundingPoly.normalizedVertices,
      };

      let updatedColArr = [...this.state.updatedTableArr, tableObj];
      this.setState({
        updatedTableArr: updatedColArr,
      });
    }
  }

  /**
   * setCanvasSize Function.
   *
   * @function
   * @description
   * The `setCanvasSize` function calculates and updates the canvas size and positioning based on the container's dimensions,
   * PDF dimensions, and canvas orientation. It ensures that the canvas is centered within the container and calculates
   * the appropriate starting coordinates (`startX` and `startY`) for drawing elements on the canvas. Additionally, it updates
   * the `IMAGE_HEIGHT` and `IMAGE_WIDTH` states to reflect the dimensions of the PDF content.
   *
   * @returns {void}
   */
  setCanvasSize = () => {
    const containerHeight = this.divElement.clientHeight;
    const containerWidth = this.divElement.clientWidth;
    let canvasOrientation = this.getCanvasOrientation();

    const pdfLengths = this.getPdfDimentions(canvasOrientation);
    let startX = (containerWidth - pdfLengths.width) / 2;
    let startY = (containerHeight - pdfLengths.height) / 2;

    this.setState({
      startX: startX > 0 ? startX : 0,
      startY: startY > 0 && canvasOrientation === CanvasOrientation.HORIZONTAL ? startY : 0,
      IMAGE_HEIGHT: pdfLengths.height,
      IMAGE_WIDTH: pdfLengths.width,
    });
  };

  /**
   * getCanvasOrientation Function.
   *
   * @function
   * @description
   * The `getCanvasOrientation` function determines the canvas orientation based on the dimensions of the PDF page.
   * It compares the width and height of the page and returns the appropriate canvas orientation value.
   *
   * @returns {CanvasOrientation} The canvas orientation value (CanvasOrientation.HORIZONTAL or CanvasOrientation.VERTICAL).
   */
  getCanvasOrientation = () => {
    const { page } = this.props;

    let x = page?.data?.size?.x;
    let y = page?.data?.size?.y;

    return x > y ? CanvasOrientation.HORIZONTAL : CanvasOrientation.VERTICAL;
  };

  /**
   * Element select handler
   *
   * @function
   * @param {Event} event - The Event object
   */
  handleChange = event => {
    let selectedItem = event.target.value;
    this.setState({
      option: selectedItem,
      viewType: selectedItem,
    });

    this.props.predictActions.handleSelectDropdownElement(selectedItem);
    this.props.predictActions.handleAppMode({ mode: AppMode.DRAWING_ACTION });
  };

  /**
   * changeViewMode Function.
   *
   * @function
   * @description
   * The `changeViewMode` function is responsible for changing the current view mode between edit view and default view.
   */
  changeViewMode = () => {
    var vMode = this.isEditViewMode() ? ViewMode.DEFAULT_VIEW : ViewMode.EDIT_VIEW;
    this.isEditViewMode()
      ? this.props.predictActions.changeValidateStatus({
        isEditMode: false,
      })
      : this.props.predictActions.changeValidateStatus({
        isEditMode: true,
      });
    if (vMode === ViewMode.DEFAULT_VIEW) {
      this.props.predictActions.handleSelectDropdownElement(DrawingMode.KEY_VALE);
    }

    this.props.predictActions.handleViewMode({ mode: vMode });
    this.props.predictActions.handleAppMode({ mode: AppMode.MOVE_ACTION });

    this.setState({
      selectedId: null,
    });
  };

  /**
   * editViewModalHandler Function.
   *
   * @function
   * @description
   * The `editViewModalHandler` function toggles the value of the `showEditViewModal` property in the component's state.
   * This property is used to control the visibility of an edit view modal.
   */
  editViewModalHandler = () => {
    this.setState({
      showEditViewModal: !this.state.showEditViewModal,
    });
  };

  /**
   * isEditViewMode Function.
   *
   * @function
   * @returns {boolean} Returns `true` if the current view mode is "edit" mode, otherwise returns `false`.
   * @description
   * The `isEditViewMode` function is a utility function that checks whether the current view mode is set to "edit" mode.
   */
  isEditViewMode() {
    return this.props.viewMode.mode === ViewMode.EDIT_VIEW;
  }

  /**
   * Checks if the application mode is set to drawing action.
   *
   * @returns {boolean} True if the application mode is drawing action, false otherwise.
   */
  isDrawingMode() {
    return this.props.appMode.mode === AppMode.DRAWING_ACTION;
  }

  /**
   * submitColumnChange Function.
   *
   * @function
   * @description
   * The `submitColumnChange` function is used to submit changes made to table columns.
   */
  submitColumnChange = () => {
    var updateTableDto = {
      pageId: this.props.page?.data?.id,
      tables: this.state.updatedTableArr,
    };

    this.props.predictActions.transformTable(updateTableDto);
    this.setState({
      updatedTableArr: [],
      selectedId: null,
    });
  };

  pageHandler = action => {
    const { match } = this.props;
    const { pageNo = 1 } = match?.params;
    let updatedpageNo = +pageNo + action;
    this.props.predictActions.clearPageSpace();
    this.props.predictActions.getPage({ pageNo: updatedpageNo });
    this.props.history.push(`/validate/${updatedpageNo}`);
    this.props.predictActions.handleSelectedTable({ selectedTableNo: 1 });
  };

  getPdfDimentions = canvasOrientation => {
    let long = window.innerHeight - 60;
    let short = (495.9 * (window.innerHeight - 60)) / 701.7;
    let lengths = {
      height: long,
      width: short,
    };

    if (canvasOrientation === CanvasOrientation.HORIZONTAL) {
      lengths = {
        height: short,
        width: long,
      };
    }

    return lengths;
  };

  routePage = pageNo => {
    const { page } = this.props;
    if (0 < pageNo && pageNo <= page?.data?.numberOfPages) {
      this.props.predictActions.clearPageSpace();
      this.props.predictActions.getPage({ pageNo: pageNo });
      this.props.history.push(`/validate/${pageNo}`);
      this.props.predictActions.handleSelectedTable({ selectedTableNo: 1 });

      this.setState({
        selectedTableNo: 1,
      });
    }
  };

  setAnchorEl = event => {
    if (event) {
      this.setState({
        anchorEl: event.currentTarget,
      });
    }

    if (!event) {
      this.setState({
        anchorEl: null,
      });
    }
  };

  feedbackModalHandler = () => {
    console.log('Feedback Modal Handler!');
    this.setState({
      isShowValidateModal: !this.state.isShowValidateModal,
    });
  };

  render() {
    const { annotations, newAnnotation, CANVAS_WIDTH, option, CANVAS_HEIGHT, dataArray } =
      this.state;
    const {
      appMode,
      page,
      match,
      addKeyValue,
      updateTable,
      addTable,
      addColumn,
      resizeElement,
      deleteElement,
      transformTable,
      addTableHeading,
      deleteTable,
      transposeTable,
      updateKeyValue,
      updateRow,
    } = this.props;

    const annotationsToDraw = [...annotations, ...newAnnotation];

    let imgWidth = this.state.IMAGE_WIDTH;
    let imgHeight = this.state.IMAGE_HEIGHT;

    return (
      <div
        ref={divElement => {
          this.divElement = divElement;
        }}
      >
        {(page.pending ||
          addTableHeading.pending ||
          transposeTable.pending) && <Loader />}
        {(addKeyValue.pending ||
          updateTable.pending ||
          addTable.pending ||
          addColumn.pending ||
          resizeElement.pending ||
          deleteElement.pending ||
          deleteTable.pending ||
          updateKeyValue.pending ||
          updateRow.pending ||
          transformTable.pending) && <Loader />}
        <TransformWrapper
          initialScale={1}
          disabled={
            !(appMode.mode === AppMode.MOVE_ACTION && this.state.mode === AppMode.MOVE_ACTION)
          }
        >
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <React.Fragment>
              <div
                className="canvas-section"
                onMouseDown={this.startDrawing}
                onMouseUp={this.stopDrawing}
              >
                {/* CustomMousePointer component */}
                {this.isEditViewMode() && this.isDrawingMode() && (
                  <div className="custom-mouse-pointer">
                    <CustomMousePointer />
                  </div>
                )}

                <div
                  className="row m-0"
                  style={{
                    backgroundColor: '#E6E6E6',
                    overflowX: 'hidden',
                    height: { height },
                    width: { CANVAS_WIDTH },
                    fontWeight: '600',
                    borderRight: '1px solid #c9c9c9',
                    borderBottom: '1px solid #c9c9c9',
                    borderLeft: '1px solid #c9c9c9',
                  }}
                  product-tour="canvas"
                >
                  {this.props.page?.data?.overlappingPages?.length > 0 && this.state.openAlert && (
                    <div className="col-md-12 p-0 position-relative d-flex flex-row justify-content-center">
                      <Alert
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          zIndex: 999,
                        }}
                        severity="warning"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              this.setState({
                                openAlert: false,
                              });
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {this.props.t('overlapped_pages_error_msg', {
                          overlappedPages: this.props.page?.data?.overlappingPages?.toString(),
                        })}
                      </Alert>
                    </div>
                  )}
                  <TransformComponent>
                    <Stage
                      width={CANVAS_WIDTH}
                      height={CANVAS_HEIGHT}
                      onMouseDown={this.handleMouseDown}
                      onMouseUp={this.handleMouseUp}
                      onMouseMove={this.handleMouseMove}
                    >
                      <Layer>
                        <URLImage
                          height={imgHeight}
                          width={imgWidth}
                          src={page.loading ? testImage : page?.data?.imageURI}
                          x={this.state.startX}
                          y={this.state.startY}
                        />
                        {dataArray?.map((cell, j) => {
                          if (this.isEditViewMode()) {
                            if (cell.type === ElementType.ELM_CELL) return null;
                          } else {
                            if (
                              cell.type === ElementType.ELM_COLUMN ||
                              cell.type === ElementType.ELM_TABLE
                            )
                              return null;
                          }

                          let vertices = cell.boundingPoly.normalizedVertices;
                          const r3Info = {
                            type: cell.type,
                            x: vertices.x * imgWidth,
                            y: vertices.y * imgHeight,
                            w: vertices.w * imgWidth,
                            h: vertices.h * imgHeight,
                          };
                          // 70 - 30  174 274
                          return (
                            <ColoredRect
                              // color={`hsl(${
                              //   174 + Math.floor(elmLength * j)
                              // }, 82%, 61%)`}
                              key={`rect-${j}`}
                              color={`hsl(${cell?.colorCode?.h}, ${cell?.colorCode?.s}%, ${cell?.colorCode?.l}%)`}
                              onDragEndHandler={this.onDragEndHandler}
                              startX={this.state.startX}
                              startY={this.state.startY}
                              data={cell}
                              r3Info={r3Info}
                              selectElementHandler={this.selectElementHandler}
                              id={cell.id}
                              draggable={appMode.mode === AppMode.DRAG_ACTION}
                              pageId={this.props.page?.data?.id}
                              isSelected={cell.id === this.state.selectedId}
                              viewMode={this.props.viewMode}
                              onChange={newAttrs => {
                                let dataArray = this.state.dataArray;
                                dataArray[j].boundingPoly.normalizedVertices =
                                  this.calculateNormalizedVertices(newAttrs, imgWidth, imgHeight);
                                this.setState({
                                  dataArray: dataArray,
                                });
                                this.boundingPolyChanged(
                                  this.calculateNormalizedVertices(newAttrs, imgWidth, imgHeight),
                                  this.props.page?.data?.id,
                                  cell,
                                  newAttrs.type,
                                );
                              }}
                              selectedTable={this.props.selectedTable}
                              page={this.props.page}
                            />
                          );
                        })}
                        {this.state.tablesArray &&
                          this.state.tablesArray.length >= 0 &&
                          this.state.tablesArray.map((table, i) => {
                            if (!this.isEditViewMode()) {
                              return (
                                <TableNumber
                                  key={`table-number-${i}`}
                                  tblNo={i + 1}
                                  startX={this.state.startX}
                                  startY={this.state.startY}
                                  vertices={table?.boundingPoly?.normalizedVertices}
                                  size={{
                                    x: this.state.IMAGE_WIDTH,
                                    y: this.state.IMAGE_HEIGHT,
                                  }}
                                  indent={15}
                                />
                              );
                            }

                            return (
                              <Table
                                table={table}
                                tblNo={i + 1}
                                key={table.id}
                                changeViewMode={this.changeViewMode}
                                dimension={{
                                  width: this.state.IMAGE_WIDTH,
                                  height: this.state.IMAGE_HEIGHT,
                                }}
                                load={transformTable.pending}
                                isEditMode={this.isEditViewMode()}
                                setUpdatedTableArray={this.setUpdatedTableArray}
                                width={
                                  table?.boundingPoly?.normalizedVertices?.w * page?.data?.size?.x
                                }
                                height={
                                  table?.boundingPoly?.normalizedVertices?.h * page?.data?.size?.y
                                }
                                startX={this.state.startX}
                                startY={this.state.startY}
                                data={{
                                  ...table,
                                  size: {
                                    x: this.state.IMAGE_WIDTH,
                                    y: this.state.IMAGE_HEIGHT,
                                  },
                                }}
                                selectElementHandler={this.selectElementHandler}
                              />
                            );
                          })}
                        {annotationsToDraw.map(value => {
                          let color =
                            value.type === DrawingMode.TABLE
                              ? ColorTheme.TABLE_CLR
                              : value.type === DrawingMode.KEY_VALE
                                ? 'black'
                                : value.type === DrawingMode.COLUMN
                                  ? ColorTheme.COLUMN_CLR
                                  : 'black';
                          return (
                            <Rect
                              key={value.id}
                              x={value.x}
                              y={value.y}
                              width={value.width}
                              height={value.height}
                              fill="transparent"
                              stroke={color}
                              onClick={() => this.selectDrawingHandler(value)}
                              draggable={appMode.mode === AppMode.DRAG_ACTION}
                            />
                          );
                        })}
                      </Layer>
                    </Stage>
                  </TransformComponent>
                </div>
              </div>
              <div>
                <div className="row m-0" product-tour="edit_btn">
                  <div
                    className="col-md-12 p-0 position-relative d-flex flex-row justify-content-center"
                    style={{ height: '54px' }}
                  >
                    <ElementSelector
                      active={this.props.appMode.mode}
                      deleteHandler={this.deleteHandler}
                      handleChange={this.handleChange}
                      changeViewMode={this.changeViewMode}
                      option={option}
                      canDelete={this.state.selectedId !== null}
                      isColumnView={this.props.viewMode.mode === ViewMode.EDIT_VIEW}
                      isDisableConfirm={this.state.updatedTableArr.length <= 0}
                      updatedTableArr={this.state.updatedTableArr}
                      submitColumnChange={this.submitColumnChange}
                      setAnchorEl={this.setAnchorEl}
                      editViewModalHandler={this.editViewModalHandler}
                    />
                    <ImageControlPanel
                      active={this.props.appMode.mode}
                      modeHandler={this.modeHandler}
                      resetTransform={resetTransform}
                      isColumnView={this.props.viewMode.mode === ViewMode.EDIT_VIEW}
                      zoomIn={zoomIn}
                      zoomOut={zoomOut}
                    />
                    <PageNumberIndicator
                      zoomOut={zoomOut}
                      match={match}
                      routePage={this.routePage}
                    />
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </TransformWrapper>
        <AddColumnOverlay
          modeHandler={this.modeHandler}
          addNewColumn={this.addNewColumn}
          tempDrewColumn={this.state.tempDrewColumn}
          isShow={this.state.anchorEl !== null}
          tables={this.state.tablesArray}
          submitHandler={this.submitHandler}
          addNewElement={this.addNewElement}
          anchorEl={this.state.anchorEl}
          setAnchorEl={this.setAnchorEl}
        />
        <GPTRetryModalDialog open={this.state.gptRetryModalOpen} handleSkip={() => this.setState({ gptRetryModalOpen: false })} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    predict: state.Predict,
    detections: state.Predict.detections,
    appMode: state.Predict.appMode,
    selectedDropdownElement: state.Predict.selectedDropdownElement,
    page: state.Predict.page,
    pageImage: state.Predict.pageImage,
    viewMode: state.Predict.viewMode,
    addKeyValue: state.Predict.addKeyValue,
    updateKeyValue: state.Predict.updateKeyValue,
    updateTable: state.Predict.updateTable,
    updateRow: state.Predict.updateRow,
    addTable: state.Predict.addTable,
    addColumn: state.Predict.addColumn,
    resizeElement: state.Predict.resizeElement,
    deleteElement: state.Predict.deleteElement,
    transformTable: state.Predict.transformTable,
    selectedTable: state.Predict.selectedTable.data,
    getTableHeadings: state.Predict.getTableHeadings,
    updateTableHeading: state.Predict.updateTableHeading,
    addTableHeading: state.Predict.addTableHeading,
    deleteTable: state.Predict.deleteTable,
    transposeTable: state.Predict.transposeTable,
    getMetaDataValidatePage: state.Predict.getMetaDataValidatePage,
    validateStatus: state.Predict.validateStatus,
    gptGenerationStatus: !state?.Predict?.metadata?.data?.chat_gpt_retry_status || false,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    predictActions: bindActionCreators(PredictActions, dispatch),
  };
}

export default withRouter(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Validate)),
);
