// Reducers: combine all reducers of the app
import { combineReducers } from "redux";
import { reducer as reduxFormReducer } from "redux-form";
import Predict from "./modules/validate/ducks";
import Auth from "./modules/auth/ducks";

export default combineReducers({
  // router: connectRouter(history),
  form: reduxFormReducer,
  Predict: Predict,
  Auth: Auth,
});

// export default createRootReducer;
