import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { PredictActions } from "../../modules/validate/ducks";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { BootstrapDialogTitle } from "./BootstrapDialogTitle";
import TranslateMessage from "../translateManager/TranslateMessage";
import YouTube from 'react-youtube';

const opts = {
  height: '250',
  width: '450',
  playerVars: {
    autoplay: 1,
  },
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export function CommonModal(props) {

  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  return (
    <div>
      <BootstrapDialog
        onClose={props.closeModal}
        aria-labelledby="customized-dialog-title"
        open={props.showModal}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={props.closeModal}
        >
          <h5 className="result-heading mb-0 text-center">
            {/*<TranslateMessage translateKey="validate_modal_header" />*/}
            <TranslateMessage translateKey={props.heading} />
          </h5>
        </BootstrapDialogTitle>
        <DialogContent>
        <div><div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 70,
  }}>
      {/*<YouTube videoId="XWSGPjPT0UI" opts={opts} onReady={_onReady} />*/}
      <YouTube videoId={props.videoId} opts={opts} onReady={_onReady} />

      </div>
      <div  style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 70,
  }}>
        {/*<TranslateMessage translateKey="tutorial_description" />*/}
        <TranslateMessage translateKey={props.description} />
      </div>
      </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    addRating: state.Predict.addRating,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    predictActions: bindActionCreators(PredictActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CommonModal)
);
