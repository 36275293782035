import { Popover, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { PredictActions } from '../../modules/validate/ducks';
import { AppMode, DrawingMode } from '../../util/Constants';

const elementOptions = [
  {
    value: DrawingMode.KEY_VALE,
    label: 'option_key_value_pair',
  },
  {
    value: DrawingMode.TABLE,
    label: 'option_table',
  },
];

/**
 * AddColumnOverlay Component.
 *
 * @component
 * @param {Object} props - The props object containing various properties and functions.
 * @param {boolean} props.isShow - A boolean indicating whether the overlay is shown.
 * @param {Function} props.setAnchorEl - A function to set the anchor element for the popover.
 * @param {Element} props.anchorEl - The anchor element for the popover.
 * @param {Function} props.modeHandler - A function to handle mode changes.
 * @param {Object} props.predictActions - An object containing services.
 * @param {Array} props.elementOptions - An array of options for the overlay elements.
 *
 * @description
 * The `AddColumnOverlay` component displays a popover overlay with options for adding columns. It provides a user interface
 * for selecting an option and triggering actions related to adding `Text` or `Table` in a drawing mode.
 */
const AddColumnOverlay = props => {
  const { isShow, setAnchorEl, anchorEl, modeHandler } = props;

  const { t } = useTranslation();

  const popId = isShow ? 'simple-popover' : undefined;

  /**
   * Handle the selection of an option.
   * 
   * @param {string} value - The selected value.
   */
  const selectChangeHandler = value => {
    let selectedItem = value;

    props.predictActions.handleSelectDropdownElement(selectedItem);
    modeHandler({ mode: AppMode.DRAWING_ACTION });

    handleClose();
  };

  /**
   * Close the popover.
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Popover
        id={popId}
        open={isShow}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {elementOptions?.map((option, index) => (
          <Typography
            key={index}
            className="lang-text-side"
            onClick={() => {
              selectChangeHandler(option.value);
            }}
            sx={{ px: 3, py: 0.5 }}
          >
            {t(option.label)}
          </Typography>
        ))}
      </Popover>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    selectedTable: state.Predict.selectedTable,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    predictActions: bindActionCreators(PredictActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddColumnOverlay));
