import React from "react";
import CircularProgress from '@mui/material/CircularProgress';

function Loader() {
  return (
    <div id="overlay" style={{ zIndex: 9999 }}>
      <div className="center-loader">
      <CircularProgress />
      </div>
    </div>
  );
}

export default Loader;
