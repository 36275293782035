import React from "react";
import PanToolOutlinedIcon from "@mui/icons-material/PanToolOutlined";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { Button, ButtonGroup } from "@mui/material";
import { AppMode } from "../../util/Constants";

// ** Can move the canvas image
// ** zoom reset button
// ** zoom out button
// ** zoom in button
function ImageControlPanel({
  active,
  modeHandler,
  resetTransform,
  zoomIn,
  zoomOut,
  isColumnView,
}) {
  return (
    <div className="">
      <ButtonGroup
        style={{
          paddingTop: "8px",
        }}
      >
        <Button
          style={{
            backgroundColor:
              active === AppMode.MOVE_ACTION ? "#E0E0E0" : "whitesmoke",
          }}
          className={isColumnView ? "middle-btn" : "first-btn"}
          onClick={() => modeHandler({ mode: AppMode.MOVE_ACTION })}
        >
          {/* Enable drag */}
          <PanToolOutlinedIcon />
        </Button>
        <Button
          style={{
            backgroundColor: "whitesmoke",
          }}
          className="middle-btn"
          onClick={() => resetTransform()}
        >
          {/* Reset */}
          <AspectRatioIcon />
        </Button>
        <Button
          style={{
            backgroundColor: "whitesmoke",
          }}
          className="middle-btn"
          onClick={() => zoomIn()}
        >
          {/* Zoom In */}
          <ZoomInIcon />
        </Button>
        <Button
          style={{
            backgroundColor: "whitesmoke",
          }}
          className="last-btn"
          onClick={() => zoomOut()}
        >
          {/* Zoom Out */}
          <ZoomOutIcon />
        </Button>
      </ButtonGroup>
    </div>
  );
}

export default ImageControlPanel;
