import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AuthActions } from "../auth/ducks";
import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { pricingPlanFeaturesOther } from '../pricing/PricingPage';
import './MyPlan.css';
import history from "../../util/History";
import types from "../auth/ducks/types";
import EndPoint from "../../util/EndPoint";

/**
 * Retrieves the titles of features based on the provided subscription plan type.
 *
 * @function
 * @param {string} planType - The subscription plan type ("free", "pro", "enterprise").
 * @returns {string[]} An array of feature titles associated with the given plan type.
 */
const getTitlesByPlan = (planType) => {
    const lowerCasePlanType = planType.toLowerCase();
    const filteredTitles = pricingPlanFeaturesOther
        .filter(feature => feature.plans.map(p => p.toLowerCase()).includes(lowerCasePlanType))
        .map(feature => feature.title);

    return filteredTitles;
}

const MyPlan = () => {
    const dispatch = useDispatch();
    const getUserInfo = useSelector((state) => state.Auth.getUserInfo);
    const pendingActions = useSelector((state) => state.Auth.pendingActions);
    const [subscriptionInfo, setSubcribtionInfo] = useState({});
    const [subscription, setSubscription] = useState({});

    const isLoading = (type) => {
        return pendingActions.actions.includes(type) && pendingActions.loading
    }

    const handleChange = () => {
        const pricingPlanSelected = getUserInfo?.data?.user?.subscription.group !== "free"
        if (pricingPlanSelected) {
            dispatch(AuthActions.getUserPortalPaymentInfo());
        } else {
            history.push("/pricing-plans");
        }
    }

    useEffect(() => {
        dispatch(AuthActions.getUserInfo());
    }, [dispatch]);

    useEffect(() => {
        // Get subscription data from the user object
        const userData = getUserInfo?.data?.user;
        if (userData && userData.subscription !== subscription)
            setSubscription(userData.subscription || {});

        // Create subscription benefit list
        if (Object.keys(subscription).length !== 0) {
            setSubcribtionInfo(getTitlesByPlan(subscription.group))
        }
    }, [getUserInfo, subscription]);

    return (
        <>
            <div className="pl-80 pt-24">
                <div className='pricing-card my-plan'>
                    <div>
                        <div className="badge">
                            <span>Current Plan</span>
                        </div>
                        <div className='title-section'>
                            <div className='title capitalize'>{subscription.group}</div>
                            <div className="price-display">
                                {subscription?.interval?.billing_interval
                                    ? <span className='title'>{'$'}{subscription?.interval?.price}</span>
                                    : '$0'
                                }
                                {subscription?.interval?.billing_interval && <span className='pricing-period pl-5'>{'/ '}{subscription?.interval?.billing_interval}</span>}
                            </div>
                        </div>
                        <div className='body-section'>
                            {subscription?.interval && subscription?.group !== 'free' &&
                                <div className='feature'>
                                    <div>
                                        <span className='transparent-circle'><img className="check-icon" src="assets/check.svg" alt="" /></span>
                                    </div>
                                    <span className='feature-text'>{subscription?.interval?.maximum_pages}{" pages"}</span>
                                    {subscription?.interval && <span className='feature-text pl-5'>{"/ "}{subscription?.interval?.billing_interval}</span>}
                                </div>
                            }

                            {subscriptionInfo.length && subscriptionInfo.map((title, index) => (
                                <div className='feature' key={index} >
                                    <div>
                                        <span className='transparent-circle'><img className="check-icon" src="assets/check.svg" alt="" /></span>
                                    </div>
                                    <span className='feature-text'>{title}</span>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='lower-section flex-col pt-0'>
                        <div className="d-flex justify-center">
                            <Button onClick={handleChange} className="blue-btn my-plan-btn">
                                Change Plan
                                {isLoading(types.GET_USER_PORTAL_PAYMENT_INFO) && <CircularProgress size={16} style={{ color: "inherit", marginLeft: 10 }} />}
                            </Button>
                        </div>
                        <div className="d-flex pt-15 justify-center">
                            <span className="fs-8">Support is only available for Pro Plans.*</span>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};


export default MyPlan;
