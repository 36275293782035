import { LinearProgress } from '@mui/material';
import React from 'react';
import TranslateMessage from '../translateManager/TranslateMessage';

function UploadLoadingManager({ getProgress, totalPageCount, uploadPDF }) {
  const calculateServerInstances = totalPages => {
    let instances, pagesPerInstance;
    if (totalPages <= 50) {
      instances = Math.ceil((totalPages + 9) / 10);
      pagesPerInstance = Math.min(10, Math.floor(totalPages / instances));
    } else if (totalPages <= 100) {
      instances = 5;
      pagesPerInstance = Math.min(20, Math.floor(totalPages / instances));
    } else {
      instances = 5;
      pagesPerInstance = Math.min(30, Math.floor(totalPages / instances));
    }

    // Calculate pages for each instance
    let remainingPages = totalPages;
    let pages = [];

    for (let i = 0; i < instances; i++) {
      if (i === 0) {
        pages.push(Math.min(pagesPerInstance + 2, remainingPages)); // To equally allocate, take out +2 (can be checked more)
        remainingPages -= pages[0];
      } else {
        pages.push(Math.min(pagesPerInstance, remainingPages));
        remainingPages -= pages[i];
      }
    }

    // Check and assign remaining pages to the instance with the lowest page count
    if (remainingPages > 0) {
      const minPages = Math.min(...pages);
      const minIndex = pages.indexOf(minPages);
      pages[minIndex] += remainingPages;
    }

    // Check and adjust if the 1st instance has > 3 pages compared to others
    if (pages.length > 1 && pages[0] - Math.min(...pages.slice(1)) > 3) {
      let diff = pages[0] - pages[1] - 2;
      for (let i = 1; i < pages.length; i++) {
        if (diff > 0) {
          pages[i] += 1;
          diff -= 1;
          pages[0] -= 1;
        }
      }
    }

    // Check and adjust if the 2nd instance has > 1 page compared to instances 3, 4, 5
    if (pages.length > 2 && pages[1] - Math.min(...pages.slice(2)) > 1) {
      let diff = pages[1] - Math.min(...pages.slice(2)) - 1;
      const minPages = Math.min(...pages.slice(2));
      const minIndices = pages
        .slice(2)
        .map((page, index) => (page === minPages ? index + 2 : -1))
        .filter(index => index !== -1);
      for (let i of minIndices) {
        if (diff > 0) {
          pages[i] += 1;
          diff -= 1;
          pages[1] -= 1;
        }
      }
    }

    return Math.ceil((pages[0] * 20) / 60);
  };

  let progress = getProgress?.data?.progress || 0;
  let serverCrashed =
    (getProgress?.data?.serverAttempt === 1 &&
      (getProgress?.data?.serverStatus === 'CRASHED' ||
        getProgress?.data?.serverStatus === 'INITIALIZING')) ||
    getProgress?.data?.serverAttempt === 2;
  let serverCrashedAgain =
    getProgress?.data?.serverAttempt === 2 && getProgress?.data?.serverStatus === 'CRASHED';

  const totalTime = calculateServerInstances(totalPageCount);
  return (
    <div id="overlay" className="progressBar" style={{ verticalAlign: 'center', padding: '3% 10%' }}>
      <div className="cardFrameProgress">
        <div></div>

        <div>
          <div>
            {progress === 0 ? (
              <>
                {uploadPDF?.error?.payload?.message && <div className='text-danger'>{uploadPDF?.error?.payload?.message}</div>}
                {!uploadPDF?.error?.payload?.message && <h5>
                  <b>
                    <div className='d-flex align-items-center justify-content-center'>
                      <div className="loading-square">
                        <div className="spinner" />
                      </div>
                      <TranslateMessage translateKey="connecting_to_server" />
                    </div>

                  </b>
                </h5>}
                {!uploadPDF?.error?.payload?.message && <small>
                  <b>
                    {serverCrashed ? (
                      <>
                        <TranslateMessage translateKey="serverCrashed" />
                      </>
                    ) : (
                      <>
                        <TranslateMessage translateKey="total_processing_time" />
                        {' '}{totalTime + 1}{' '}
                        {totalTime + 1 < 2 ? (
                          <TranslateMessage translateKey="minute" />
                        ) : (
                          <TranslateMessage translateKey="minutes" />
                        )}
                      </>
                    )}
                  </b>
                </small>}
                <br />
                <br />
              </>
            ) : (
              <>
                {serverCrashedAgain ? (
                  <>
                    <TranslateMessage translateKey="serverCrashedAgain" />
                  </>
                ) : (
                  <>
                    <h5>
                      <b>
                        <TranslateMessage translateKey="processing" />
                      </b>
                    </h5>
                    <div style={{ padding: '0px 30%', marginTop: '20px' }}>
                      <LinearProgress variant="determinate" value={progress} />
                    </div>
                    {serverCrashed ? (
                      <div style={{ marginTop: '8px' }}>
                        <TranslateMessage translateKey="serverCrashed" />
                      </div>
                    ) : (
                      <div style={{ marginTop: '8px' }}>
                        <TranslateMessage translateKey="processing_message" />{' '}
                        <b>
                          {totalTime}{' '}
                          {totalTime < 2 ? (
                            <TranslateMessage translateKey="minute" />
                          ) : (
                            <TranslateMessage translateKey="minutes" />
                          )}
                        </b>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <div className='guide-video-wrap'>
            <video controls loop autoPlay style={{ width: '90%', height: 'auto' }}>
              <source
                src="https://kasun.s3.ap-south-1.amazonaws.com/OCR/video_1280.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: 20,
            }}
          >
            <span>
              <TranslateMessage translateKey="watchTheProductTutorial" />{' '}
              <u>
                <b>
                  {' '}
                  <a href="https://youtu.be/vJsQs3zVyaM?si=-_0VJCn8pdZWAA66">
                    {' '}
                    <TranslateMessage translateKey="here" />
                  </a>
                </b>
              </u>
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: 5,
            }}
          >
            <span>
              <TranslateMessage translateKey="please" />
              <u>
                <b>
                  <TranslateMessage translateKey="do_not_refresh" />
                </b>
              </u>{' '}
              <TranslateMessage translateKey="the_page" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UploadLoadingManager;
