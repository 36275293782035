import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Button from "@mui/material/Button";
import { AuthActions } from "./ducks";
import { Alert, CircularProgress } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { EMAIL_REGEX } from "../../util/ValidationRegex";
import TranslateMessage from "../../components/translateManager/TranslateMessage";

/**
 * 
 * Renders the Sign Up page
 * @component
 *
 * @returns {JSX.Element} The JSX element representing the SignUp component.
 */
class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: "",
      showPassword: false,
      isValidEmail: true,
      isValidPassword: false,
      emailValidateMessage: "",
      passwordValidateMessage: "",
      isChanging: false,
    };
  }

  /**
   * Email change handler
   * @param {string} email user input email
   */
  emailChangeHandler = (email) => {
    this.setState({ email, isValidEmail: true, isChanging: true });
  };

  /**
   * Password change handler
   * @param {string} password user input password
   */
  passwordChangeHandler = (password) => {
    if (this.validatePassword(password)) {
      this.setState({
        password,
        isChanging: true,
        isValidPassword: true,
        passwordValidateMessage: "",
      });
    } else {
      this.setState({
        password,
        isChanging: true,
        isValidPassword: false,
        passwordValidateMessage: "Password is required",
      });
    }
  };

  /**
   * Sign up handler
   */
  signUpHandler = () => {
    const { signUp, AuthActions } = this.props;
    const { email, password } = this.state;
    if (!signUp.pending) {
      this.setState({ loading: true });
      if (!this.validatePassword(password)) {
        this.setState({
          isValidPassword: false,
          passwordValidateMessage: "Password is required",
        });
      }
      if (!this.validateEmail(email)) {
        this.setState({
          isValidEmail: false,
          emailValidateMessage: "Invalid email.",
        });
      } else {
        this.setState({
          isValidEmail: true,
          emailValidateMessage: "",
          isChanging: false,
        });
        let signUpDto = {
          email: email,
          password: password,
        };
        AuthActions.signUp({ signUpDto });
      }
    }
  };

  /**
   * Password show/hide handler
   */
  handleClickShowPassword = () => {
    this.setState((prev) => ({
      showPassword: !prev.showPassword,
    }));
  };

  /**
   * Validate email
   * @param {string} email user input email
   * @returns {boolean} true/false
   */
  validateEmail = (email) => {
    return EMAIL_REGEX.test(email);
  };

  /**
   * Validate Password
   * @param {string} email user input email
   * @returns {boolean} true/false
   */
  validatePassword = (password) => {
    return password?.length !== 0;
  };

  render() {
    const { signUp, history } = this.props;
    const {
      showPassword,
      isValidEmail,
      emailValidateMessage,
      isChanging,
      isValidPassword,
      passwordValidateMessage,
    } = this.state;
    return (
      <div className="d-flex flex-column justify-content-center h-100" >
        <div className="row justify-content-center">
          <div className="col-sm-10 col-md-5 col-lg-5 col-xl-4 col-xxl-3">
            <div className="login-container">
              <div className="row text-center mb-5">
                <h2 className="logo-name mb-0">
                  <a href="https://www.docutize.ai">docutize.ai</a>
                  {/* <Link to="/" underline="none">
                  docutize.ai
                  </Link> */}
                </h2>
              </div>
              <div className="row first-row">
                <div className="col-md-12 col-lg-5 pe-md-0">
                  <h3 className="text-lg-start">
                    <TranslateMessage translateKey="sign_up" />
                  </h3>
                </div>
              </div>
              <div className="row second-row">
                <div className="col-12 mt-4">
                  {!isChanging && signUp && signUp?.hasError && (
                    <Alert className=" mb-3" severity="error">
                      {signUp?.error?.payload?.message}
                    </Alert>
                  )}
                  <div className="mb-1">
                    <label>
                      <TranslateMessage translateKey="email" />*
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      onChange={(e) => this.emailChangeHandler(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && isValidPassword && isValidEmail) {
                          this.signUpHandler();
                        }
                      }}
                    />
                  </div>
                  {!isValidEmail && (
                    <div className="error-msg">{emailValidateMessage}</div>
                  )}
                  <div className="mt-4 mb-3">
                    <div className="form-group">
                      <label>
                        <TranslateMessage translateKey="password" />*
                      </label>
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        onChange={(e) =>
                          this.passwordChangeHandler(e.target.value)
                        }
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && isValidPassword && isValidEmail) {
                            this.signUpHandler();
                          }
                        }}
                      />
                      <span
                        className="toggle-password"
                        onClick={this.handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </span>
                    </div>
                  </div>
                  {!isValidPassword && (
                    <div className="error-msg">{passwordValidateMessage}</div>
                  )}
                  <div className="policy">
                    <p className="mb-0">
                      <TranslateMessage translateKey="by_signing_up_you_agree_to_the" />
                      &nbsp;
                      <span>
                        <a href="https://www.docutize.ai/terms-and-condition" underline="none">
                          <TranslateMessage translateKey="terms_of_service" />
                        </a>
                        &
                        <a href="https://www.docutize.ai/privacy-policy" underline="none">
                          <TranslateMessage translateKey="privacy_policy" />
                        </a>
                      </span>
                    </p>
                  </div>
                  <div className="btn-signup">
                    <Button
                      className="blue-btn mt-4 mb-3"
                      variant="contained"
                      onClick={this.signUpHandler}
                      disabled={!isValidPassword || !isValidEmail}
                    >
                      {(signUp.pending) ? (
                        <CircularProgress className="loading-btn-circle" />
                      ) : (
                        <TranslateMessage translateKey="sign_up" />
                      )}
                    </Button>
                  </div>
                  <div className="col-md-12 col-lg-12 first-row">
                    <p className="text-lg-center">
                      <TranslateMessage translateKey="already_a_member" />
                      <span
                        onClick={() => {
                          history.push('/sign-in');
                        }}
                      >
                        &nbsp; <TranslateMessage translateKey="login" />
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    predict: state.Predict,
    detections: state.Predict.detections,
    appMode: state.Predict.appMode,
    selectedDropdownElement: state.Predict.selectedDropdownElement,
    pageImage: state.Predict.pageImage,
    viewMode: state.Predict.viewMode,
    signUp: state.Auth.signUp,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    AuthActions: bindActionCreators(AuthActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUp));
