import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";


const style: any = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

interface Props {
  open: boolean;
  handleClose: () => void;
  maxLimit: number;
  currentCount: number;
}

export const PagesPerUploadModal = ({ open, handleClose, maxLimit, currentCount }: Props) => {

  return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" id="modal-modal-description">
            Page limt per upload is exceeded
          </Typography>
          <Typography variant="body1" id="modal-modal-description" sx={{ mt: 2 }}>
            You can upload only {maxLimit} pages per upload.
          </Typography>
          <Typography variant="caption" id="modal-modal-description">
            You have uploaded {currentCount} pages.
          </Typography>
        </Box>
      </Modal>
  );
}