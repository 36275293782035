export const productTourConfig = [
  {
    selector: '[product-tour="canvas"]',
    content: `The uploaded documents are shown here. Each colored box represents an entry as Text or Table.`
  },
  {
    selector: '[product-tour="edit_btn"]',
    content: `On the left you find the edit mode switch. Click it to edit, delete or draw new boxes.
    In the middle you find all tools.
    On the right you find the page count.`
  },
  {
    selector: '[product-tour="text_section"]',
    content: `This section shows all Text entries. You can directly click in all fields to manually edit.
    In the Selection column you need to choose which Values you want to extract by clicking the checkbox or using the Dropdown to assign the correct label.
    The Labels column shows the automatic classification of the Values.
    The Values column shows the extracted content. Values with the same Labels need to be selected only once.`
  },
  {
    selector: '[product-tour="table_section"]',
    content: `This section shows all Table entries. You can move the line between Text and Table sections to maximize the section windows.`
  },
  {
    selector: '[product-tour="table_header"]',
    content: `On the left you find the '1st row is a header' icon which is used to define where the body of the table starts. Next to it is the 'Transpose' icon which flips the table. On the right you can click through all the tables on the displayed page.`
  },
  {
    selector: '[product-tour="next_btn"]',
    content: `Using the blue buttons you can click through the uploaded pages. The download button will bring you to the last step to get the Selected Values.`
  },
  {
    selector: '[product-tour="help"]',
    content: `In the help section you can start the Product Tour again. Also there you can find a Tutorial Video, FAQs & support email.`
  },
];