import { Button } from '@mui/material';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { PredictActions } from '../../validate/ducks';
import { FeedbackModal } from '../../../components/modal/FeedbackModal';
import EndPoint from '../../../util/EndPoint';
import xls from '../../../assets/images/file-excel-solid.svg';
import csv from '../../../assets/images/file-csv-solid.svg';
import txt from '../../../assets/images/file-lines-solid.svg';
import solid from '../../../assets/images/file-solid.svg';
import zip from '../../../assets/images/file-zipper-solid.svg';
import info from '../../../assets/images/circle-info-solid.svg';
import numb from '../../../assets/images/list-ol-solid.svg';
import name from '../../../assets/images/spell-check-solid.svg';
import merge from '../../../assets/images/object-group-solid.svg';
import page from '../../../assets/images/file-regular.svg';
import {
  FILE_TYPE,
  FILE_TYPE_MS_EXCEL,
  FILE_TYPE_COMMA_SEP,
  FILE_TYPE_TEXT_FILE,
  RESULT_TYPE,
  RESULT_TYPE_ALL,
  RESULT_ZIP_SINGLE,
  RESULT_ZIP_ALL,
  FORMATING,
  FORMATING_NO_EXTRA_INFO,
  FORMATING_INCLUDE_PAGE_NUM,
  FORMATING_INCLUDE_FILE_NAME,
  MERGE_TYPE,
  MERGE_EACH_PAGE,
  MERGE_INTELLIGENTLY,
  MERGE_INTELLIGENTLY_EACH_PAGE,
} from '../../../util/Constants';
import {
  DownloadModal,
} from '../../../util/Constants';
import DownloadCard from '../../../components/download/DownloadCard';
import Loader from '../../../components/shared/Loader';
import TranslateMessage from '../../../components/translateManager/TranslateMessage';
import SaveDialog from './SaveDialog';
import UpdatePromptDialog from './UpdatePromptDialog';
import {
  selectNewTemplate,
  selectNoOfFilesProcessed,
  selectNoOfPagesProcessed,
  selectOverlappingPages,
  selectTemplateChanged,
  isMetadataLoading
} from 'modules/validate/ducks/selectors';
import { withTranslation } from 'react-i18next';
import { CommonModal } from '../../../components/modal/CommonModal';

class Download extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileType: [1],
      resultType: [1],
      formating: [1],
      mergeType: [1],
      showFeedbackWindow: false,
      firstPageLoad: true,
      showDownloadModal:false,
      showOpeningVideo: false
    };
  }

  componentDidMount() {
    // const { templateChanged, isNewTemplate, admin } = this.props;
    this.openingVideoModalHandler();
    this.props.predictActions.getMetaData();
  }

  /**
   * Delete document handler
   */
  deleteHandler = () => {
    this.props.predictActions.deleteDocument();
  };

  /**
   * Download document handler
   */
  downloadHandler = () => {
    const { fileType, resultType, formating, mergeType } = this.state;
    let downloadConfig = {
      fileType: fileType,
      resultType: resultType,
      formating: formating,
      mergeType: mergeType,
    };
    this.props.predictActions.downloadResult({
      downloadConfig: downloadConfig,
      callback: this.completedFeedback,
      showDownloadModalCallback: this.downloadModalHandler
    });
    /**
     * ! TODO - Need to remove all getFileCount
     */
    // this.props.predictActions.getFileCount();
  };

  /**
   * Download config file
   */
  configFileDownloadHandler = () => {
    const newWindow = window.open(
      EndPoint.DOCUMENT_TITLE_TEMPLATE_DOWNLOAD,
      '_blank',
      'noopener,noreferrer',
    );
    if (newWindow) newWindow.opener = null;
  };

  /**
   * Completed feedback handler
   */
  completedFeedback = () => {
    this.downloadFileHandler();
    this.feedbackHandler();
  };

  /**
   * Feedback toggle
   */
  feedbackHandler = () => {
    this.setState({
      showFeedbackWindow: !this.state.showFeedbackWindow,
    });
  };

  downloadModalHandler = () => {
    this.setState({
      showDownloadModal: !this.state.showDownloadModal,
    });
  };

  openingVideoModalHandler = () => {
    this.setState({
      showOpeningVideo: !this.state.showOpeningVideo,
    });
  };

  /**
   * Result file download handler
   */
  downloadFileHandler = () => {
    const newWindow = window.open(EndPoint.DOCUMENT_DOWNLOAD, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  /**
   * Download config select handler
   * @param {string} type section
   * @param {string} value selected type id
   */
  selectHandler = (type, value) => {
    if (type === FILE_TYPE) {
      let fileTypeArr = this.state.fileType;
      let index = fileTypeArr.indexOf(value);
      if (index === -1) {
        fileTypeArr.push(value);
      } else if (fileTypeArr.length !== 1) {
        fileTypeArr.splice(index, 1);
      }
      this.setState({ fileType: fileTypeArr });
    } else if (type === RESULT_TYPE) {
      let resultTypeArr = this.state.resultType;
      let index = resultTypeArr.indexOf(value);
      if (index === -1) {
        resultTypeArr.push(value);
      } else if (resultTypeArr.length !== 1) {
        resultTypeArr.splice(index, 1);
      }
      this.setState({ resultType: resultTypeArr });
    } else if (type === FORMATING) {
      let formatingArr = this.state.formating;
      let index = formatingArr.indexOf(value);

      if (value === FORMATING_INCLUDE_PAGE_NUM || value === FORMATING_INCLUDE_FILE_NAME) {
        if (formatingArr[0] === FORMATING_NO_EXTRA_INFO) {
          formatingArr = [value];
        } else {
          if (index === -1) {
            formatingArr.push(value);
          } else if (formatingArr.length !== 1) {
            formatingArr.splice(index, 1);
          }
        }
      } else if (
        formatingArr[0] === FORMATING_INCLUDE_PAGE_NUM ||
        formatingArr[0] === FORMATING_INCLUDE_FILE_NAME
      ) {
        formatingArr = [value];
      } else {
        formatingArr = [FORMATING_INCLUDE_PAGE_NUM];
      }

      this.setState({ formating: formatingArr });
    } else if (type === MERGE_TYPE) {
      let mergeTypeArr = this.state.mergeType;
      let index = mergeTypeArr.indexOf(value);
      if (index === -1) {
        mergeTypeArr.push(value);
      } else if (mergeTypeArr.length !== 1) {
        mergeTypeArr.splice(index, 1);
      }
      this.setState({ mergeType: mergeTypeArr });
    }
  };

  handlePageLoadFirstTime = pageLoad => {
    this.setState({ firstPageLoad: pageLoad });
  };

  render() {
    const { fileCount, downloadResult, numOfPages, templateChanged, isNewTemplate, metaLoading } =
      this.props;
    const { fileType, resultType, formating, mergeType, showFeedbackWindow, firstPageLoad, showDownloadModal } =
      this.state;
    return (
      <>
        {metaLoading && <Loader />}
        {templateChanged && isNewTemplate && (
          <SaveDialog firstPageLoadHandler={this.handlePageLoadFirstTime} />
        )}
        {templateChanged && !isNewTemplate && firstPageLoad && (
          <UpdatePromptDialog admin={this.props.admin} />
        )}
        <div className="d-flex flex-column justify-content-center h-100">
          <div className="row justify-content-center">
            <div className="col-sm-11 col-md-11 col-lg-10 col-xl-9 col-xxl-8">
              <div className="upload-container">
                {this.props.overlappingPages?.length > 0 ? (
                  <Alert sx={{ maxWidth: 'fit-content' }} severity="warning">
                    {this.props.t('overlapped_pages_error_msg', {
                      overlappedPages: this.props.overlappingPages?.toString(),
                    })}
                  </Alert>
                ) : (
                  ''
                )}
                <div className="d-flex align-items-center pt-2 mb-2">
                  <h5 className="result-heading mb-0 me-2">
                    <TranslateMessage translateKey="download" />
                  </h5>
                  <small className="mt-1">
                    <TranslateMessage translateKey="download_description" />
                  </small>
                </div>
                <div className="upload-card-2">
                  <div className="d-flex justify-content-around">
                    <DownloadCard
                      section={FILE_TYPE}
                      type={FILE_TYPE_MS_EXCEL}
                      description={'ms_excel_spreadsheet'}
                      source={fileType}
                      image={xls}
                      className={'file-1 d-flex flex-column'}
                      selectHandler={this.selectHandler}
                    />
                    <DownloadCard
                      section={FILE_TYPE}
                      type={FILE_TYPE_COMMA_SEP}
                      description={'comma_separated_values'}
                      source={fileType}
                      image={csv}
                      className={'file-2 d-flex flex-column'}
                      selectHandler={this.selectHandler}
                    />
                    <DownloadCard
                      section={FILE_TYPE}
                      type={FILE_TYPE_TEXT_FILE}
                      description={'text_file'}
                      source={fileType}
                      image={txt}
                      className={'file-3 d-flex flex-column'}
                      selectHandler={this.selectHandler}
                    />
                  </div>
                </div>

                <div className="upload-card-2 mt-3">
                  <div className="d-flex justify-content-around">
                    <DownloadCard
                      section={MERGE_TYPE}
                      type={MERGE_INTELLIGENTLY}
                      description={'merge_pages_intelligently'}
                      source={mergeType}
                      image={merge}
                      className={'file-1 d-flex flex-column'}
                      selectHandler={this.selectHandler}
                    />

                    <DownloadCard
                      section={MERGE_TYPE}
                      type={MERGE_INTELLIGENTLY_EACH_PAGE}
                      description={'merge_pages_intelligently_per_each_each_file'}
                      source={mergeType}
                      image={merge}
                      className={'file-1 d-flex flex-column'}
                      selectHandler={this.selectHandler}
                      isMulti={true}
                    />

                    <DownloadCard
                      section={MERGE_TYPE}
                      type={MERGE_EACH_PAGE}
                      description={'show_result_per_each_individual_page'}
                      source={mergeType}
                      image={page}
                      className={'file-2 d-flex flex-column'}
                      selectHandler={this.selectHandler}
                      isMulti={true}
                    />
                  </div>
                </div>

                <div className="upload-card-2 mt-3">
                  <div className="d-flex justify-content-around">
                    <DownloadCard
                      section={RESULT_TYPE}
                      type={RESULT_TYPE_ALL}
                      description={'all_results_merged_as_one_file'}
                      source={resultType}
                      image={solid}
                      className={'file-1 d-flex flex-column'}
                      selectHandler={this.selectHandler}
                    />
                    <DownloadCard
                      section={RESULT_TYPE}
                      type={RESULT_ZIP_SINGLE}
                      description={'zip_folder_of_individual_results_for_each_file'}
                      source={resultType}
                      image={zip}
                      className={'file-2 d-flex flex-column'}
                      selectHandler={this.selectHandler}
                    />
                    <DownloadCard
                      section={RESULT_TYPE}
                      type={RESULT_ZIP_ALL}
                      description={'zip_folder_of_individual_pages_results'}
                      source={resultType}
                      image={zip}
                      className={'file-3 d-flex flex-column'}
                      selectHandler={this.selectHandler}
                      isMulti={true}
                    />
                  </div>
                </div>

                <div className="upload-card-2 mt-3">
                  <div className="d-flex justify-content-around">
                    <DownloadCard
                      section={FORMATING}
                      type={FORMATING_NO_EXTRA_INFO}
                      description={'include_no_extra_information'}
                      source={formating}
                      image={info}
                      className={'file-1 d-flex flex-column'}
                      selectHandler={this.selectHandler}
                    />
                    <DownloadCard
                      section={FORMATING}
                      type={FORMATING_INCLUDE_PAGE_NUM}
                      description={'include_page_number_in_extra_column'}
                      source={formating}
                      image={numb}
                      className={'file-2 d-flex flex-column'}
                      selectHandler={this.selectHandler}
                    />
                    <DownloadCard
                      section={FORMATING}
                      type={FORMATING_INCLUDE_FILE_NAME}
                      description={'include_file_name_in_extra_column'}
                      source={formating}
                      image={name}
                      className={'file-3 d-flex flex-column'}
                      selectHandler={this.selectHandler}
                    />
                  </div>
                </div>

                <div className="btn-login text-center mt-4 d-flex justify-content-evenly">
                  <div
                    className="d-flex flex-column align-items-center"
                    style={{ width: '20vw', fontSize: '13px' }}
                  >
                    <Button
                      className="blue-btn mt-2 mb-3"
                      variant="contained"
                      onClick={this.downloadHandler}
                    >
                      <TranslateMessage translateKey="download" />
                    </Button>
                    <small>
                      <TranslateMessage translateKey="delete_documents_description" />
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FeedbackModal
            showModal={showFeedbackWindow}
            closeModal={this.feedbackHandler}
            fileCount={fileCount}
            numOfPages={numOfPages}
          />
          {/*Modal when selections are not available for download*/}
          <CommonModal
            heading={DownloadModal.HEADING_KEY}
            videoId={DownloadModal.VIDEO_ID}
            description={DownloadModal.DESCRIPTION_KEY}
            showModal={showDownloadModal}
            closeModal={this.downloadModalHandler}
            fileCount={10}
            numOfPages={5}
          />
          {/*Modal at the opening of the Download page*/}
          {/* <CommonModal
            heading={DownloadOpeningModal.HEADING_KEY}
            videoId={DownloadOpeningModal.VIDEO_ID}
            description={DownloadOpeningModal.DESCRIPTION_KEY}
            showModal={showOpeningVideo}
            closeModal={this.openingVideoModalHandler}
            fileCount={10}
            numOfPages={5}
          /> */}
          {downloadResult?.pending && <Loader />}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    deleteDocument: state.Predict.deleteDocument,
    fileCount: selectNoOfFilesProcessed(state),
    downloadResult: state.Predict.downloadResult,
    numOfPages: selectNoOfPagesProcessed(state),
    isNewTemplate: selectNewTemplate(state),
    templateChanged: selectTemplateChanged(state),
    overlappingPages: selectOverlappingPages(state),
    metaLoading: isMetadataLoading(state),
    admin: state?.Predict?.metadata?.data?.admin,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    predictActions: bindActionCreators(PredictActions, dispatch),
  };
}

export default withRouter(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Download)),
);
