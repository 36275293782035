import React from 'react'
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import "./PaymentStatusModal.css"

interface PaymentStatusModalProps {
    open: boolean
    handleClose: () => void
}

export const PaymentStatusModal = ({ handleClose, open }: PaymentStatusModalProps) => {

    return (
        <>
            <Modal
                disableEscapeKeyDown={true}
                open={open}
                onClose={handleClose}
            >
                <div className='payment-status-modal'>
                    <div className='modal-body'>
                        <div className="pricing-heading">
                            docutize.ai Pricing
                        </div>

                        <div className='message-container'>
                            <CircularProgress size={28} />
                            <span className='message-text'>
                                Processing Subscription
                            </span>
                        </div>

                        <div className='modal-footer-section'>
                            <div className='footer-text'>
                                Please wait while we process your subscription.
                            </div>
                        </div>
                    </div>


                </div>
            </Modal>
        </>
    )
}

