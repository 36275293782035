import React, { Component } from "react";
import Button from "@mui/material/Button";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { AuthActions } from "../auth/ducks";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Alert, CircularProgress } from "@mui/material";
import TranslateMessage from "../../components/translateManager/TranslateMessage";
import { RoutePaths } from "routes";

/**
 * 
 * Renders the Update Password page
 * @component
 * @example
 * // Usage example:
 * <UpdatePassword />
 *
 * @returns {JSX.Element} The JSX element representing the UpdatePassword component.
 */
class UpdatePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidConfirmPassword: true,
      passwordMessage: "",
      password: "",
      newPassword: "",
      confirmPassword: "",
      showPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      isSuccess: false,
      isChanging: true,
    };
  }

  /**
   * user input field change
   * @param {string} input user input
   * @param {string} field input field name
   */
  inputChangeHandler = (input, field) => {
    this.setState({
      [field]: input,
      isValidConfirmPassword: true,
      passwordMessage: "",
      isChanging: true,
      isSuccess: false,
    });
  };

  /**
   * update password handler
   */
  updatePasswordHandler = () => {
    const { confirmPassword, newPassword, password } = this.state;
    const { changePassword } = this.props;
    if (!changePassword.pending) {
      if (newPassword === confirmPassword) {
        this.setState({
          isChanging: false,
        });
        let updatePasswordDto = {
          oldPassword: password,
          newPassword: newPassword,
        };

        this.props.AuthActions.changePassword({
          updatePasswordDto,
          callback: this.updatePasswordCallBack,
        });
      } else {
        this.setState({
          isValidConfirmPassword: false,
          passwordMessage: "Confirm password mismatch.",
        });
      }
    }
  };

  /**
   * update password call back
   */
  updatePasswordCallBack = () => {
    this.setState({
      password: null,
      newPassword: null,
      confirmPassword: null,
      isSuccess: true,
      isChanging: true,
    });
  };

  /**
   * passowrd show/hide
   * @param {string} field field name
   */
  showPasswordHandler = (field) => {
    this.setState((prev) => ({
      [field]: !prev[field],
    }));
  };

  render() {
    const {
      isValidConfirmPassword,
      passwordMessage,
      isChanging,
      newPassword,
      confirmPassword,
      password,
      isSuccess,
      showPassword,
      showNewPassword,
      showConfirmPassword,
    } = this.state;
    const { changePassword } = this.props;
    return (
      <div className="password-area">
        <div className="row mt-4">
          <div className="col-md-12 col-lg-8 col-xl-7 col-xxl-6 px-4 pb-lg-4 extra-pading">
            <div className="row">
              <div className="col-md-6 mt-4">
                {isSuccess && changePassword && changePassword?.data && (
                  <Alert className=" mb-3" severity="success">
                    {changePassword?.data?.message}
                  </Alert>
                )}
                <div className="form-group">
                  <label>
                    <TranslateMessage translateKey="current_password" />*
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    onChange={(e) =>
                      this.inputChangeHandler(e.target.value, "password")
                    }
                    value={password}
                  />
                  <span
                    className="toggle-password"
                    onClick={() => this.showPasswordHandler("showPassword")}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </span>
                </div>
                {!isChanging && changePassword && changePassword?.hasError && (
                  <div className="error-msg mt-1">
                    {changePassword?.error?.payload?.message}.
                  </div>
                )}
              </div>
              <div className="col-md-6 mt-4">
                <div className="forget-pass mt-4 pt-2">
                  <Link className="text-decoration-none" to={RoutePaths.forgotPassword}>
                    <p className="mb-0">
                      <span>
                        <TranslateMessage translateKey="i_forgot_my_password" />
                      </span>
                    </p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-4">
                <div className="form-group">
                  <label>
                    <TranslateMessage translateKey="new_password" />*
                  </label>
                  <input
                    type={showNewPassword ? "text" : "password"}
                    className="form-control"
                    onChange={(e) =>
                      this.inputChangeHandler(e.target.value, "newPassword")
                    }
                    value={newPassword}
                  />
                  <span
                    className="toggle-password"
                    onClick={() => this.showPasswordHandler("showNewPassword")}
                  >
                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </span>
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="form-group">
                  <label>
                    <TranslateMessage translateKey="confirm_new_password" />*
                  </label>
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    className="form-control"
                    onChange={(e) =>
                      this.inputChangeHandler(e.target.value, "confirmPassword")
                    }
                    value={confirmPassword}
                  />
                  <span
                    className="toggle-password"
                    onClick={() =>
                      this.showPasswordHandler("showConfirmPassword")
                    }
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </span>
                </div>
                {!isValidConfirmPassword && (
                  <div className="error-msg mt-1">{passwordMessage}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12 col-lg-8 col-xl-7 col-xxl-6 px-4 text-end">
            <Button
              className="blue-btn mt-2 mb-3"
              variant="contained"
              onClick={this.updatePasswordHandler}
            >
              {!changePassword.pending ? (
                <TranslateMessage translateKey="update_password" />
              ) : (
                <CircularProgress className="loading-btn-circle" />
              )}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    changePassword: state.Auth.changePassword,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    AuthActions: bindActionCreators(AuthActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UpdatePassword)
);
